<template>
  <a-modal
    width=480px
    centered
    style="border-radius: 10px;"
  >
    <div class="pictureTitle">{{$t('titleDialog.pictureTitle')}}</div>
    <div
      v-for="(item, index) in titleList"
      :key="index"
    >
      <div style="margin: 15px 20px;">
        <span>{{item.title}}</span>
        <span style="position: absolute;right: 50px;cursor:pointer;">
          <svg
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="currentColor"
            :style="'color:'+(item.doLike==='1'?'#ffd966': '#323232')"
            @click="addTitleLike(item.titleId)"
          >
            <path
              d="M140.883,137.666H129.416a3.252,3.252,0,0,1-2.18-.907,2.971,2.971,0,0,1-.992-2.115l-.244-7.4v-.01a2.909,2.909,0,0,1,2.925-2.887h.428c.954,0,1.185-.186,2.111-1.06a5.319,5.319,0,0,0,1.245-2.122c.075-.248.1-.365.124-.443a1.5,1.5,0,0,1,.185-.451c.019-.042.077-.176.116-.268.121-.282.142-.331.166-.375a1.878,1.878,0,0,1,1.581-.956,3.121,3.121,0,0,1,2.579,1.166l.031.041a4.716,4.716,0,0,1,.868,2.149.442.442,0,0,1,.005.11h0a7.355,7.355,0,0,1-.117,1.183l-.1,1.024h4.932a2.936,2.936,0,0,1,2.409,1.252,2.854,2.854,0,0,1,.32,2.669l-2.193,7.548-.012.029a2.909,2.909,0,0,1-2.717,1.824Zm-13.6-10.443.247,7.4v.011a1.982,1.982,0,0,0,1.888,1.766h11.465a1.63,1.63,0,0,0,1.514-1l2.192-7.544.011-.029a1.6,1.6,0,0,0-.168-1.506,1.647,1.647,0,0,0-1.357-.709h-5.032l-.029,0-.458-.008a.956.956,0,0,1-.529-.273c-.408-.419-.287-1.018-.148-1.712.057-.279.118-.582.157-.9a.52.52,0,0,1,0-.165v-.018c0-.049.011-.12.02-.222.006-.066.013-.133.017-.18a3.436,3.436,0,0,0-.617-1.508l-.025-.034a1.837,1.837,0,0,0-1.458-.658.6.6,0,0,0-.537.282c-.021.045-.08.183-.12.277-.116.27-.136.317-.159.359s-.055.1-.066.123-.007.027-.011.046c-.021.085-.057.227-.141.5a6.586,6.586,0,0,1-1.554,2.642l-.021.021a3.715,3.715,0,0,1-3.013,1.423h-.428A1.632,1.632,0,0,0,127.284,127.223Zm5.738-6.965a.009.009,0,0,0,0,0A.014.014,0,0,1,133.022,120.258Z"
              transform="translate(-126 -118.666)"
            />
          </svg>
        </span>
      </div>
    </div>
    <div class="">
      <div class="">
        <div class="comment-text-div">
          <textarea
            class="comment-text"
            :placeholder="$t('detail.title')"
            v-model="commentTitle"
          ></textarea>
          <div class="btn-box">
            <button
              :style="'background-color:'+(commentTitle?'#0870d1': '#EEEEEE')+';color:'+(commentTitle?'#EEEEEE': '#cccccc')"
              @click="addTitle()"
            >
              {{$t('detail.addTitle')}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import Api from "../Api";

export default {
  components: {},
  data () {
    return {
      commentTitle: '',
      titleList: [],
      imageId: '',
      userId: '',
      pictureId: ''
    }
  },
  created () {
  },
  methods: {
    // 添加标题点赞
    addTitleLike (titleId) {
      Api.addTitleLike(titleId, this.imageId, this.userId).then((res) => {
        if (res.error_code == 0) {
          this.getImageTitleInfos(this.imageId, this.userId, 1, 10000, this.pictureId)
          this.$nextTick(()=>{this.$emit('refreshTitle')});
        }
      }).catch(err => {
        console.log("Get Entry Page ==> ", err)
          if (err.response.status == 401 || err.response.status == 400) {
              this.$message.error(this.$t("tokenErrMessage"));
              this.$emit("loginVisible")
          }
      }).finally(() => {
      });
    },
    // 获取标题列表
    getImageTitleInfos (imageId, userId, page, pageSize, pictureId) {
      this.imageId = imageId
      this.userId = userId
      this.pictureId = pictureId
      Api.getImageTitleInfos(imageId, userId, page, pageSize).then((res) => {
        if (res.error_code == 0) {
          let data = res.result.data;
          this.titleList = data.list;
        }
      }).catch(err => {
        console.log("Get Entry Page ==> ", err)
      }).finally(() => {
      });
    },
    // 添加标题
    addTitle () {
      Api.addTitle(this.commentTitle, this.imageId, this.userId).then((res) => {
        if (res.error_code == 0) {
          this.commentTitle = ''
          this.getImageTitleInfos(this.imageId, this.userId, 1, 10000, this.pictureId)
          this.$nextTick(()=>{this.$emit('refreshTitle')});
        }
      }).catch(err => {
        console.log("Get Entry Page ==> ", err)
          if (err.response.status == 401 || err.response.status == 400) {
              this.$message.error(this.$t("tokenErrMessage"));
              this.$emit("loginVisible")
          }
      }).finally(() => {
      });
    },
  }
}
</script>
<style scoped lang="scss">
.pictureTitle {
  text-align: center;
  font-size: 16px;
}
.comment-text-div {
  padding: 18px 20px;
  height: 108px;
  background: #f9f9f9;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .comment-text {
    width: 100%;
    height: 100%;
    resize: none;
    border: none;
    font-size: 14px;
    line-height: 19px;
    background: none;
    outline: none;
  }

  .btn-box {
    position: absolute;
    bottom: 20px;
    right: 20px;

    > button {
      width: 64px;
      height: 32px;
      background: #eeeeee;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 34px;
      color: #cccccc;
      border: none;
      outline: none;
      margin-left: 15px;
      cursor: pointer;
    }
  }
}
</style>
