export default {
    expression: 'ひょうじょう',
  //tokenErrMessage: '別のコンピュータにログインしています',
  tokenErrMessage:'このアカウントは既に別のところで登録されましたが、再度ログインしてください',
  //searchTerm:'単語を検索',
  searchTerm:'キーワードを検索',
  embeddedCode:'埋め込みコード',
  copyLink:'リンクをコピーする',
  //done: "確定",
  done:'完了',
  cancel: "キャンセル",
  header: {
    search: '検索',
    personal: '個人情報',
    account: 'アカウント',
    switchAccount: '切り替える',
      exit: 'ログアウト'
  },
  footer: {
    //　　　about: '情報',
    about:'お知らせ',
    terms: '利用規約',
    privacy: 'プライバシー',
    language:'言語',
  },
  mdialog: {
    replay: '返信',
    cancel: 'キャンセル',
    saySomething: '何か言いましょう…',
  },
  editAvatar: {
    //addPicture: '画像を加える',
    addPicture:'写真を追加する',
    preview: 'プレビュー',
    //changeProfile: 'アイコンを変更',
    changeProfile:'プロフィール写真を変更',
    //typeRule: "画像タイプはgif，jpeg，jpg，png，bmpのいずれかでなければなりません。",
    typeRule:'gif，jpeg，jpg，png，bmpのように画像タイプをサポートする',
  },
  editModal: {
    // input默认提示
    usernamePlaceholder: 'ユーザーネームを入力してください',
    phonePlaceholder: '電話番号を入力してください',
    userIDPlaceholder: 'IDを入力してください(一回のみ変更できる)',
    genderPlaceholder: '性别を選んでください',
    male: '男性',
    female: '女性',
    birthdayPlaceholder: '生年月日',
    emailAddressPlaceholder: 'メールアドレスを入力してください',
    currentPasswordPlaceholder: '現在のパスワードを入力してください',
    newPasswordPlaceholder: '新しいパスワードを入力してください',
    confirmPlaceholder: '新しいパスワードを確認してください',
    // 弹窗标题
    nameTitle: "ユーザーネーム",
    phoneTitle: "電話番号",
    userIdTitle: "ユーザーID",
    sexTitle: "性别",
    dateTitle: "生年月日",
    emailTitle: "メールアドレス",
    passwordTitle: "パスワード",
    // 校验提示？？　  还不太清楚使用场景
    nameRule: "ユーザーネーム",
    phoneRule: "電話番号",
    userIdRule: "ユーザーID",
    sexRule: "性别",
    dateRule: "生年月日",
    emailRule: "メールアドレス",
    //emailTypeRule: "正しいメールフォーマットを入力します。",
    emailTypeRule:'正しいメールフォーマットを入力してください',
    //passwordRule: "メールアドレスを更新",
    passwordRule:'パスワードを入力',
    // Error提示语？？  还不太清楚使用场景
    //passwordNullError: "メールアドレスを更新",
    passwordNullError:'パスワードを更新',
    //newPasswordNullError: "新メールアドレスを更新",
    newPasswordNullError:'パスワードを再度入力',
    passwordDifferentError: "二回の入力は違います。",

  },
  setting: {
    profile: 'プロファイル',
    //username: 'ユーザー名',
    username:'ユーザーネーム',
    phone: '電話番号',
    userID: 'ユーザーID',
    gender: '性別',
    birthday: '生年月日',
    emailAddress: 'メールアドレス',
    resetPassword: 'パスワード',
    edit: '編集',
    male: '男性',
    female: '女性',
    //custom: '秘密',
    custom:'内緒',
  },
  create: {
    postTo: '投稿先',
    postToAfterSelect: '投稿先',
    chooseTargetToLetMorePeopleKnow: 'ターゲットを選択し、注目されやすい！',
    search: '検索',
    history: '歴史',
    historyAll: 'すべての歴史',
    relatedSearches: '他のキーワード',
    withInWordsBefore: '',
    withInWordsAfter: '文字以内',
    addPictures: '写真を追加',
    customShootingTime: 'カスタム撮影時間',
    selectTime: '時間を選ぶ',
    post: '投稿',
    title: 'タイトル',
    description: '何か言いましょう',
    success: 'アップロード完了',
    viewPost: '投稿を確認する',
    //postNext: '新しい作成',
    postNext:'投稿をつづく',
    //createEntry:'項目の作成',
    createEntry:'項目を新規する',
    prompt: '選択対象は空くすることできません'
  },
  detail: {
    // all: '全部',
    all:'すべて',
    //impression: '印象',
    impression:'イメージ',
    //queryResults: 'クエリの結果',
    queryResults:'検索結果',
    date: '日付',
    other: 'その他',
    clear: 'クリア',
    //selectDate: '月を選択',
    selectDate:'年月を選ぶ',
    filter: 'フィルター',
    //suggest: '提案',
    suggest:'アドバイス',
    question: '質問',
    comment: 'コメント',
    hot: '評価順',
    time: '新しい順',
    saySomething: '何か言いましょう。',
    replay: '返信する',
    totalReplyBefore: '合計',
    totalReplyAfter: '件の返信',
    replaySuccess: '返信しました',
    commentSuccess: 'コメントを追加しました',
    collectionSuccess:'コレクションに保存',
    cancelCollectionSuccess:'コレクションから保存を取り消す',
    likeSuccess: 'いいね！',
    cancelLikeSuccess: '「いいね！」をキャンセル',
    // 时间
    justNow: '数秒前',
    title: 'タイトルを書きましょう',
    addTitle: '追加',
    report: '告発する',
    notShowAgainLeft: 'このユーザー（',
    notShowAgainRight: '）を非表示にする',
    oneClickCopy: 'キーでコピー',
  },
  index: {
    search: '検索',
    overview: '概要',
    // tab
    recommend: 'おすすめ',
    hot: 'ホット',
    //follow: 'フォロー',
    follow:'フォロー',
    //collectionSuccess: 'コレクション成功',
    collectionSuccess:'コレクションに保存',
    //cancelCollectionSuccess: 'コレクションのキャンセルに成功しました',
    cancelCollectionSuccess:'コレクションから保存を取り消す',
    //likeSuccess: 'ポイント成功',
    likeSuccess:'いいね！',
    //cancelLikeSuccess: 'ポイントキャンセル成功',
    cancelLikeSuccess:'「いいね！」をキャンセル',
    //followSuccess: '成功に関心を持つ',
    followSuccess:'フォロー中',
    //cancelFollowSSuccess: 'キャンセル成功',
    cancelFollowSSuccess:'キャンセル済',
    //copySucceed: 'コピー成功',
    copySucceed:'コピー済',
    //copyFailed: 'コピーに失敗しました',
    copyFailed:'コピーできません',
    button:'Look 検索',

  },
  login: {
    loginFailed:'ユーザー名またはパスワードが間違る',
    login: 'ログイン',
    verificationCodeLogin: '確認コード',
    passwordLogin: 'パスワード',
    phone: '電話番号',
    code: '確認コード',
    getVerificationCode: 'コードを取得',
    // 服务条款和隐私政策
    agree: '',
    OurTermsOfService: '利用規約',
    and: 'と',
    privacyPolicy: 'プライバシー',
    privacyPolicyAfter: 'に同意します',
    phoneAndEmail: 'アカウント',
    password: 'パスワードで',
    // input 提示语
    phoneAndEmailRule: 'アカウント',
    codeRule: '認証コードを入力',
    passwordRule: 'パスワードを入力',
    registerUsernameRule:"IDはすでに存在します。変更してください",
    UsernameNullRule:"IDを空にすることはできません",
    okRule:"idを使用できます",
    // 登录后
    //success: '成功',
    success:'ログインしました',
    //fail: '失敗',
    fail:'ログインできません',
    // 注册
    seconPass:'パスワードを認証する',
    username:'ユーザー名',
    register:'登録',
     // 校验提示
     registerName:'6〜12桁の数字、文字、アンダースコアで構成されるIDを入力してください',
     passwordTip:'8〜20桁の数字とアンダースコアで構成されるパスワードを入力してください'
  },
  searchList: {
    about: "約 ",
    results: " 件",
    relatedSearches: "他のキーワード",
    // tab
    // all: "全部",
    all:'すべて',
    picture: "写真",
    video: "動画",
    text: "テキスト",
    news: "ニュース",
  },
  myInfo: {
    //create: "作成",
    create:'新規作成',
    //favorite: "気に入り",
    favorite:'いいね！',
    follow: "フォロー",
    history: "歴史",
  },
  time: {
    justNow: "数秒前",
    minutesAgo: "分前",
    hourAgo: "時間前",
    hoursAgo: "時間前",
    dayAgo: "日前",
    daysAgo: "日前",
    monthAgo: "ヶ月前",
    monthsAgo: "ヶ月前",
    yearAgo: "年前",
    yearsAgo: "年前",
  },
  reportDialog:{
    // reportingProblems:'告発問題',
    reportingProblems:'報告する',
    repeat:'繰り返し',
    // antipathy:'反感を買う',
    antipathy:'単に気に入らない',
    //poorContentQuality:'内容が悪い',
    poorContentQuality:'コンテンツがよくないこと',
  },
  titleDialog:{
    pictureTitle:'画像のタイトル',

  },
  comment:{
    packUp:'コメントを折りたたむ',
    more:'もっと'
  },
  deletePicture:{
    deletePicture:'写真を削除',
    deleteConfirm:'削除の確認',
    cancel:'キャンセル',
    delete:'削除する',
    deleteSuccess:'削除に成功',
    noPermission:'無許可',
  }
}
