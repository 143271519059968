<template>
    <div class="home-box-my"  @scroll="scrollEvent">
        <Header @targetSide="targetSide"
                :tabs="headerTabs"
                :selectTab="selectTab"
                @changeTab="changeTab"

                :showSearch="showSearch"
                :value="search_value"
                @keydownEnter="keydownEnter"
                @changeSearchEvent="changeSearchEvent"

        />


        <div class="container">
            <div class="container-center">

                <div class="list-center">
                    <div class="list-left">

                        <div class="tab-center">
                            <div v-for="item in tabs" @click="changeTab(item)" :key="item" class="tab-item"
                                 :class="item==selectTab?'active':''">{{item}}
                            </div>
                        </div>

                        <div class="item-list-box" v-for="(data,i) in datas " :key="i">
                            <ListItem
                                    v-for="(item,index) in data " :key="item.id"
                                    :data="item"
                                    @goInDetail="goInDetail"
                                    @bloomHandle="bloomHandle"
                                    @shareHandle="shareHandle"
                                    @favoriteHandle="favoriteHandle"
                                    @commentHandle="commentHandle"
                                    @likeHandle="likeHandle"
                            />
                            <!--<div class="item-list"
                                 v-for="(item,index) in data " :key="item.id"
                                 @goInDetail="goInDetail"
                            >
                                <div class="item-icon-tabs" @click="bloomHandle" :class="item.isBloom ? 'ismy':''">
                                    <svg width="18" height="22" viewBox="0 0 18 22" fill="currentColor">
                                        <path data-name="路径 695" class="cls-1" d="M110,56.89a1.036,1.036,0,0,1-1.5-.906V36.753A1.772,1.772,0,0,1,110.291,35h14.419a1.772,1.772,0,0,1,1.791,1.753V55.984a1.036,1.036,0,0,1-1.5.907l-7.53-3.692Zm.569-19.86V54.335L117,51.158a1.057,1.057,0,0,1,.932,0l6.5,3.184V37.029Z" transform="translate(-108.5 -35)"/>
                                    </svg>
                                </div>
                                <div class="item-list-title" @click="goInDetail(item.id)">{{item.title}}</div>
                                <div class="item-list-imgbox" @click="goInDetail(item.id)" v-if="item.lastPicture.pictureUrl">
                                    <div class="item-list-imgbox-center">
                                        <img v-lazy="item.lastPicture.pictureUrl" class="item-list-img"/>
                                    </div>
                                </div>
                                <div class="item-info">
                                    <div class="info-left">
                                        <span class="item-info-name">{{item.insertUser}}</span>
                                        <span class="item-info-time">{{getWaitDurationTime(item.updateTime)}}</span>
                                    </div>
                                    <div class="info-right">
                                    <span @click="shareHandle">
                                        <svg width="20" height="17" viewBox="0 0 20 17" fill="currentColor">
                                            <path data-name="路径 636" class="cls-1" d="M85.239,149.619a1.179,1.179,0,0,1-.172-.013,1.287,1.287,0,0,1-1.057-1.443c.439-4.221,1.882-7.359,4.291-9.331a11.025,11.025,0,0,1,6.512-2.365l.149-.009v-2.345a1.549,1.549,0,0,1,.4-1.022,1.45,1.45,0,0,1,1.059-.472,1.419,1.419,0,0,1,1.028.44l5.836,6.2a2.642,2.642,0,0,1,0,3.6l-5.838,6.2a1.428,1.428,0,0,1-.988.438h-.042a1.484,1.484,0,0,1-1.46-1.485V145.5h-.249c-2.25,0-6.339.462-8.453,3.557a1.226,1.226,0,0,1-1.021.56h0Zm9.474-5.558c.351,0,.628.01.794.016h.016l.13,0a.707.707,0,0,1,.692.721v3.379l5.957-6.323a1.163,1.163,0,0,0,0-1.587l-5.955-6.332v3.224a.707.707,0,0,1-.692.721c-3.954,0-8.921,1.623-10.13,9.356l-.084.535.355-.4C88.38,144.492,92.492,144.061,94.713,144.061Z" transform="translate(-83.999 -132.619)"/>
                                        </svg>
                                    </span>
                                        <span @click="favoriteHandle" :class="item.isFavorite ? 'ismy':''">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                                            <path data-name="路径 628" class="cls-1" d="M67.93,96.69a1.5,1.5,0,0,0,1.53.08L74,94.374l4.538,2.4a1.5,1.5,0,0,0,1.531-.08,1.353,1.353,0,0,0,.591-1.351l-.884-5.179,3.815-3.735a1.332,1.332,0,0,0,.335-1.4,1.431,1.431,0,0,0-1.151-.926l-5.2-.759-2.263-4.6a1.474,1.474,0,0,0-2.615,0l-2.262,4.6-5.2.759a1.43,1.43,0,0,0-1.151.926,1.333,1.333,0,0,0,.336,1.4l3.813,3.735-.885,5.179A1.354,1.354,0,0,0,67.93,96.69ZM65.214,85.471l5.188-.756.913-.133.4-.814L74,79.127l2.282,4.641.4.814.913.133,5.188.756L79,89.171l-.635.622.148.867.884,5.169-4.572-2.411L74,92.983l-.826.436L68.6,95.831l.884-5.17.148-.867L69,89.171Z" transform="translate(-63.998 -77.942)"/>
                                        </svg>
                                    </span>
                                        <span @click="commentHandle" :class="item.isComment ? 'ismy':''">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                                            <path class="cls-1" d="M87.026,100.161c-.258-.416-.427-.7-.511-.844a9.086,9.086,0,0,1-1.182-4.484,10.013,10.013,0,1,1,10,9.5,10.355,10.355,0,0,1-5.136-1.347l-.47-.262-2.447.634a.732.732,0,0,1-.921-.875l.667-2.322Zm1.531.084-.4,1.41,1.484-.384a.785.785,0,0,1,.575.071q.38.209.758.422a8.8,8.8,0,0,0,4.364,1.144,8.086,8.086,0,1,0-8.5-8.075,7.719,7.719,0,0,0,1,3.81c.087.154.3.508.642,1.052a.682.682,0,0,1,.078.55Z" transform="translate(-85.333 -85.333)"/>
                                        </svg>
                                    </span>
                                        <span @click="likeHandle" :class="item.isLike ? 'ismy':''">
                                        <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                                          <path class="cls-1" d="M140.883,137.666H129.416a3.252,3.252,0,0,1-2.18-.907,2.971,2.971,0,0,1-.992-2.115l-.244-7.4v-.01a2.909,2.909,0,0,1,2.925-2.887h.428c.954,0,1.185-.186,2.111-1.06a5.319,5.319,0,0,0,1.245-2.122c.075-.248.1-.365.124-.443a1.5,1.5,0,0,1,.185-.451c.019-.042.077-.176.116-.268.121-.282.142-.331.166-.375a1.878,1.878,0,0,1,1.581-.956,3.121,3.121,0,0,1,2.579,1.166l.031.041a4.716,4.716,0,0,1,.868,2.149.442.442,0,0,1,.005.11h0a7.355,7.355,0,0,1-.117,1.183l-.1,1.024h4.932a2.936,2.936,0,0,1,2.409,1.252,2.854,2.854,0,0,1,.32,2.669l-2.193,7.548-.012.029a2.909,2.909,0,0,1-2.717,1.824Zm-13.6-10.443.247,7.4v.011a1.982,1.982,0,0,0,1.888,1.766h11.465a1.63,1.63,0,0,0,1.514-1l2.192-7.544.011-.029a1.6,1.6,0,0,0-.168-1.506,1.647,1.647,0,0,0-1.357-.709h-5.032l-.029,0-.458-.008a.956.956,0,0,1-.529-.273c-.408-.419-.287-1.018-.148-1.712.057-.279.118-.582.157-.9a.52.52,0,0,1,0-.165v-.018c0-.049.011-.12.02-.222.006-.066.013-.133.017-.18a3.436,3.436,0,0,0-.617-1.508l-.025-.034a1.837,1.837,0,0,0-1.458-.658.6.6,0,0,0-.537.282c-.021.045-.08.183-.12.277-.116.27-.136.317-.159.359s-.055.1-.066.123-.007.027-.011.046c-.021.085-.057.227-.141.5a6.586,6.586,0,0,1-1.554,2.642l-.021.021a3.715,3.715,0,0,1-3.013,1.423h-.428A1.632,1.632,0,0,0,127.284,127.223Zm5.738-6.965a.009.009,0,0,0,0,0A.014.014,0,0,1,133.022,120.258Z" transform="translate(-126 -118.666)"/>
                                        </svg>
                                    </span>

                                        <div class="item-icon-tabs" @click="bloomHandle" :class="item.isBloom ? 'ismy':''">
                                            <svg width="18" height="22" viewBox="0 0 18 22" fill="currentColor">
                                                <path data-name="路径 695" class="cls-1" d="M110,56.89a1.036,1.036,0,0,1-1.5-.906V36.753A1.772,1.772,0,0,1,110.291,35h14.419a1.772,1.772,0,0,1,1.791,1.753V55.984a1.036,1.036,0,0,1-1.5.907l-7.53-3.692Zm.569-19.86V54.335L117,51.158a1.057,1.057,0,0,1,.932,0l6.5,3.184V37.029Z" transform="translate(-108.5 -35)"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-dec">{{item.lastPicture.comment}}</div>
                            </div>-->
                        </div>

                        <div class="list-loading" v-if="loading">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div class="list-right">
                        <div class="list-right-box">
                            <div class="center-box-info">
                                <div class="center-box-info-title">概要</div>
                                <div class="center-box-info-box">
                                    <ListItemSimple v-for="(item,index) in this.datalist1"
                                                    :data="item"
                                                    :key="index"
                                    />
                                </div>
                            </div>
                            <div class="center-box-info">
                                <div class="center-box-info-item">
                                    <div class="title">阿萨达所多</div>
                                    <div class="dec line-clamp2">安达市大所大所大所多安达市大所大所大所多安达市大所大所大所多安达市大所大所大所多安达市大所大所大所多安达市大所大所大所多安达市大所大所大所多安达市大所大所大所多</div>
                                    <div class="time">123123</div>
                                </div>
                                <div class="center-box-info-item">
                                    <div class="title">阿萨达所多</div>
                                    <div class="dec line-clamp2">安达市大所大所大所多安达市大所大所大所多安达市大所大所大所多安达市大所大所大所多安达市大所大所大所多安达市大所大所大所多安达市大所大所大所多安达市大所大所大所多</div>
                                    <div class="time">123123</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SideNav :showSide="showSide"/>
    <Footer/>

</template>
<script>
    import Header from '@/components/Header.vue'
    import SideNav from '@/components/SideNav.vue'
    import Footer from '@/components/Footer.vue'
    import ListItem from '@/components/ListItem.vue'
    import ListItemSimple from '@/components/ListItemSimple.vue'
    import $ from 'jquery';
    import Api from '@/Api'
    import moment from 'moment'

    export default {
        name: 'Index',
        components: {
            Header,
            SideNav,
            Footer,
            ListItem,
            ListItemSimple
        },
        data() {
            return {
                showSide: false,
                tabs: ['推荐', '热点', '关注'],
                selectTab: '推荐',

                headerTabs:[],
                showSearch:true,


                datas:[],
                loading: false,
                hasMore: true,

                search_value:'', //搜索的绑定值

                showSearchList: false, //显示搜索列表
                searchList:[], //搜索列表
                activeSearch:'', //搜索列表选中

                currentIndex: 0,
            }
        },
        mounted() {
            this.getInitList();
        },
        computed: {
            datalist1(){
                let data = this.datas[this.currentIndex] || []
                return data.filter(o=>o.lastPicture)
            },
            datalist2(){
                let data = this.datas[this.currentIndex] || []
                return data.filter(o=>o.lastPicture && o.lastPicture.note && o.lastPicture.comment && !o.lastPicture.pictureUrl)
            }
        },
        methods: {
            targetSide() {
                this.showSide = !this.showSide
            },
            changeTab(val) {
                this.selectTab = val
            },

            //Header
            keydownEnter(){
                this.toSearchPage()
            },
            changeSearchEvent(val){
                this.search_value = val;
            },



            //搜索页
            toSearchPage(){
                if(this.search_value!=''){
                    this.$router.push({name:'search',query: {search_value:this.search_value}})
                }
            },



            scrollEvent(event){
                let clientHeight = document.documentElement.clientHeight;
                let scrollTop = event.target.scrollTop

                if( scrollTop >= 80){
                    this.headerTabs = this.tabs

                }else{
                    this.headerTabs = []
                }

                if(!this.loading){
                    if(event.target.scrollHeight < ( event.target.scrollTop+event.target.clientHeight + 30 )){
                        this.loading = true
                        this.getList();
                    }
                }

                let currentIndex = this.currentIndex;
                for (let i = 0; i < document.querySelectorAll('.item-list-box').length; i++) {
                    let item = $(".item-list-box").eq(i);
                    let top = item.position().top;
                    if (top > 80) {
                        break;
                    }
                    currentIndex = i
                }
                this.currentIndex = currentIndex

            },

            getInitList(){
                this.loading = true
                this.getList();
            },
            getList(){
                Api.getRecommend().then((res)=>{
                    let data = res.result.data;
                    this.datas = this.datas.concat([data])
                    this.loading = false

                })
            },

            shareHandle(){

            },
            favoriteHandle(){

            },
            commentHandle(){

            },
            likeHandle(){

            },
            bloomHandle(){

            },

            goInDetail(entryId, entryTitle, pictureId){
                window.open(`/${entryId}`)
            },

            getWaitDurationTime(start, end = moment()) {
                if (start) {
                    let startTime = moment(start);
                    let endTime = moment(end);
                    if (startTime.isSame(end)) {
                        return ''
                    }

                    let waitTime = moment.duration(endTime - startTime, 'ms');
                    let years = waitTime.get('years');
                    let months = waitTime.get('months');
                    let days = waitTime.get('days');
                    let hours = waitTime.get('hours');
                    let minutes = waitTime.get('minutes');

                    if( !years && !months && !days && !hours ) {
                        if(minutes >= 1){
                            return minutes+'分钟前'
                        }else{
                            return '刚刚'
                        }
                    }else if( !years && !months && !days && hours ){
                        return hours+'小时前'
                    }else if( !years && !months && days && days < 3){
                        if(days == 1){
                            return '昨天'
                        }
                        if(days == 2){
                            return '前天'
                        }
                    }else{
                        return moment(startTime).format('YYYY.M.D HH:mm:ss')
                    }

                }
                return ''
            }
        }
    }

</script>
<style lang="scss">
    body{
        background: #F8F8F8 !important;
    }
    .home-box-my {
        width: 100%;
        height: calc(100vh - 50px);
        overflow-y: auto;

        .container {
            width: 1300px;
            margin: 0 auto;


            .container-center {
                margin-top: 72px;

                .list-center {
                    margin-top: 20px;
                    display: flex;

                    .list-left {
                        width: 840px;

                        .tab-center {
                            background: #fff;
                            width: 100%;
                            margin-bottom: 20px;
                            height: 60px;
                            display: flex;
                            align-items: center;
                            justify-content: center;


                            .tab-item {
                                display: inline-block;
                                margin: 0 31px;
                                margin-top: 15px;
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 24px;
                                color: #666666;
                                cursor: pointer;

                                &.active {
                                    color: #0870D1;
                                    font-weight: bold;
                                    position: relative;

                                    &::after {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        bottom: -8px;
                                        width: 100%;
                                        height: 4px;
                                        background: #0870D1;
                                    }
                                }
                            }
                        }

                        .item-list {
                            width: 100%;
                            padding: 20px;
                            background: #fff;
                            position: relative;
                            margin-bottom: 20px;

                            .item-icon-tabs {
                                position: absolute;
                                right: 20px;
                                top: 20px;
                                cursor: pointer;
                                color: #999;

                                &.ismy {
                                    color: #f69c4a;
                                }
                            }

                            .item-list-title {
                                width: 100%;
                                text-align: center;
                                font-size: 20px;
                                font-weight: bold;
                                line-height: 26px;
                            }

                            .item-list-imgbox {
                                margin-top: 16px;
                                width: 100%;
                                padding-bottom: 59%;
                                position: relative;

                                .item-list-imgbox-center {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                }

                                .item-list-img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .item-info {
                                margin-top: 20px;
                                line-height: 24px;
                                display: flex;

                                .info-left {
                                    flex: 1;

                                    .item-info-name {
                                        font-size: 18px;
                                        font-weight: bold;
                                        color: #0870D1;
                                        margin-right: 30px;
                                    }

                                    .item-info-time {
                                        font-size: 16px;
                                        font-weight: 400;
                                        color: #999999;
                                    }
                                }

                                .info-right {
                                    position: relative;
                                    color: #999;

                                    > span {
                                        margin-left: 50px;
                                        vertical-align: middle;
                                        line-height: 1;
                                        cursor: pointer;

                                        > img {
                                            width: 20px;
                                        }
                                    }

                                    &.ismy {
                                        color: #f69c4a;
                                    }

                                    .item-icon-tabs {
                                        display: none;
                                    }
                                }
                            }

                            .item-dec {
                                margin-top: 20px;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 19px;
                                color: #999999;
                                opacity: 1;
                            }

                        }

                        .list-loading{
                            display: flex;
                            align-items: center;
                            margin-top: 20px;
                            height: 40px;
                            justify-content: center;
                            >span{
                                display: inline-block;
                                width: 16px;
                                height: 16px;
                                margin-right: 6px;
                                border-radius: 50%;
                                background: #EBEBEB;
                                -webkit-animation: load 1.04s ease infinite;
                                &:last-child{
                                    margin-right: 0px;
                                }
                            }
                            @-webkit-keyframes load{
                                0%{
                                    opacity: 1;
                                }
                                100%{
                                    opacity: 0;
                                }
                            }
                            span:nth-child(1){
                                -webkit-animation-delay:0.13s;
                            }
                            span:nth-child(2){
                                -webkit-animation-delay:0.26s;
                            }
                            span:nth-child(3){
                                -webkit-animation-delay:0.39s;
                            }
                        }
                    }

                    .list-right {
                        margin-left: 20px;
                        width: 440px;
                        flex: 1;
                        .list-right-box{
                            position: sticky;
                            top: 72px;
                            max-height: calc( 100vh - 200px);
                            overflow-y: auto;
                            .center-box-info{
                                &:first-child{
                                    margin-bottom: 20px;
                                    background: #fff;
                                    padding: 16px 20px;
                                }
                                .center-box-info-title{
                                    font-size: 20px;
                                    font-weight: bold;
                                    line-height: 26px;
                                    color: #333333;
                                    text-align: center;
                                }
                                .center-box-info-box{
                                    margin-top: 16px;
                                    overflow: hidden;
                                }

                                .center-box-info-item{
                                    margin-bottom: 20px;
                                    background: #fff;
                                    padding: 10px;

                                    .title{
                                        font-size: 18px;
                                        font-weight: 400;
                                        line-height: 24px;
                                        color: #333333;
                                        text-align: center;
                                    }
                                    .dec{
                                        font-size: 14px;
                                        font-weight: 400;
                                        line-height: 20px;
                                        color: #999999;
                                        margin-top: 11px;
                                    }
                                    .time{
                                        text-align: right;
                                        font-size: 12px;
                                        font-weight: 400;
                                        line-height: 16px;
                                        color: #999999;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    @media (max-width: 840px){
        .home-box-my .container{
            width: 100%;
            padding: 0 20px;
        }
        .home-box-my .container .container-center .list-center .list-left{
            width: 100%;
        }
        .home-box-my .container .container-center .list-center .list-right {
            display: none;
        }
        .home-box-my .container .container-center .list-center .list-left .item-list .item-icon-tabs {
            display: none;
        }

        .home-box-my .container .container-center .list-center .list-left .item-list .item-info {
            display: block;
            .info-right{
                >span{
                    margin-left: 0;
                    margin-right: 25px;
                }
                .item-icon-tabs{
                    display: block;
                    position: absolute;
                    right: 0;
                    top: 2px;
                }
            }
        }

    }

</style>