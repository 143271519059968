<template>
    <Embeddialog :showDialog="dialogVisible" ref="Embeddialog" :content="iframe" :footer="null" centered cancelText @submit="submit" @close="close"/>
    <div class="home-box" @scroll="scrollEvent">
        <Header :showSearch="false"
                @targetSide="targetSide"
                :style="{
                    position: 'relative',
                }"
        />

        <Header @targetSide="targetSide"

                :tabs="headTags"
                :selectTab="selectTab"
                @changeTab="changeTab"

                :showSearch="true"
                :value="keywords"
                @keydownEnter="keydownEnter"
                @changeSearchEvent="changeSearchEvent"

                :style="headerStyle"
        />


        <div class="container">
            <div class="container-first">
                <div class="logo-box">
                    <img class="img-logo" src="../assets/newLogo.png" alt="">
                </div>
                <div class="search-box" @keydown="keyDown" id="search"
                     :style="{
                        borderBottomLeftRadius: showSearchList?0:'25px',
                        borderBottomRightRadius: showSearchList?0:'25px',
                   }"
                >
                <!-- boxShadow: showSearchList? '0px 3px 6px rgba(0, 0, 0, 0.16)':'none',  -->
                    <img class="search-icon" :src="require('@/assets/icon-search.svg')" alt="">
                    <input class="search-input" :placeholder="$t('index.search')" @input="onChangeSearchValue($event)"
                           @focus="inputFocusHandle"
                           @blur="inputBlurHandle"
                           v-model="keywords"
                    />

                    <a-Button v-if="keywords" type="text" shape="circle" @click="keywords=''" >
                        <div style="font-size:x-large;margin-top: -10px;color: #bbbbbb" >×</div>
                    </a-Button>
                    <div class="search-list" v-if="showSearchList" >
                        <div class="search-list-item" v-for="item in searchList" :key="item"
                             @click="changeSearchList(item)"
                        >
                            <HightLight :role_text="keywords" :text="item.title"/>
                        </div>
                    </div>
                </div>
                <div class="search-buttom" @click="keydownEnter()">
                  <a-button class="search">{{this.$t('index.button')}}</a-button>
                </div>
                <div class="tab-center">
                    <div v-for="item in headTagsTab" @click="changeTab(item)" :key="item" class="tab-item"
                         :class="item.tagId == selectTab ? 'active' : ''">{{ item.tagName }}
                    </div>
                </div>
            </div>
            <div class="container-center" style="margin-top: 100px">
                <div class="list-center" id="content" style="height: 50px;">
                </div>
                <div class="list-center" :style="{minHeight: clientHeight}">
                    <div class="list-left">
                        <div class="item-list-box" v-for="(data,i) in datas " :key="i">
                            <ListItem
                                v-for="(item,index) in data " :key="item.id"
                                :data="item"
                                :index="item.id"
                                @goInDetail="goInDetail"
                                @bloomHandle="bloomHandle(i,index,item)"
                                @shareHandle="shareHandle(i,index,item)"
                                @favoriteHandle="favoriteHandle(i,index,item)"
                                @commentHandle="commentHandle(i,index,item)"
                                @likeHandle="likeHandle(i,index,item)"
                                @onload= "onload(item.id)"
                                @onCopy="onCopy(shareUrl+'/'+(item.id?item.id:item.entryId)+'?'+'pictureId='+(item.lastPicture?item.lastPicture.pictureId:item.pictureId))"
                                @onEmbed="onEmbed(item.lastPicture?item.lastPicture.pictureUrl:item.pictureUrl, item.lastPicture?item.lastPicture.pictureWidth:item.pictureWidth, item.lastPicture?item.lastPicture.pictureHeight:item.pictureHeight)"
                            />
                        </div>
                        <div class="list-loading" v-if="loading">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div class="list-right">
                        <div class="list-right-box" id="center-box-info">
                            <div class="center-box-info">
                                <div class="center-box-info-title">
                                    <img class="item-list-img-last" @click="toUp()"  v-show="this.upshows" src="../assets/up.png"/>
                                    {{$t('index.overview')}}
                                </div>
                                <div class="center-box-info-box">
                                    <ListItemSimple v-for="(item,index) in this.datalist1"
                                                    :data="item"
                                                    :key="index"
                                                    :index="index"
                                                    @goInDetail="goInDetail"
                                                    @onloadvice="onloadvice(index)"
                                    />
                                </div>
                                 <div class="center-box-info-bottom">
                                 <img class="item-list-img-next" @click="toDown()" v-show="this.shows" src="../assets/down.png"/>
                                </div>
                            </div>
                            <div class="center-box-info">
                                <div class="center-box-info-item" v-for="(item,index) in this.datalist2" :key="index">
                                    <div class="title">{{ item.lastPicture.note }}</div>
                                    <div class="dec line-clamp2">{{ item.lastPicture.comment }}</div>
                                    <div class="time">{{ getWaitDurationTime(item.lastPicture.comment) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <comment-dialog
            :content="replyInfo.replyContext"
            @addComment="addComment"
            :visibleSecond="visibleSecond"
            @closeDialog="visibleSecond=false"
    >
    </comment-dialog>
    <SideNav :showSide="showSide"/>
    <Footer/>
    <Login :visible="visible" :closeModal="closeModal"/>



</template>
<script>
import Header from '@/components/Header.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'
import ListItem from '@/components/ListItem.vue'
import ListItemSimple from '@/components/ListItemSimple.vue'
import HightLight from '@/components/HightLight.vue'
import CommentDialog from "@/components/Commentdialog.vue"
import Embeddialog from "@/components/Embeddialog.vue";
import {shareURL} from '@/utils/request';

import Api from '@/Api'
import storage from 'storejs';
import $ from 'jquery';
import moment from 'moment'
import Login from '@/views/Login.vue'

let searchTimer = null
export default {
    name: 'Index',
    components: {
        Embeddialog,
        Header,
        Login,
        SideNav,
        Footer,
        ListItem,
        ListItemSimple,
        HightLight,
        CommentDialog
    },
    data() {
        return {
            shows:true,
            upshows:true,
            upWindowHeight:0,
            upScrollHeight:0,
            shareUrl: '',
            iframe: '',
            dialogVisible: false,
            visibleSecond: false,
            replyInfo: {
                commentIndex: undefined,
                replyContent: undefined,
                commentId: undefined,
                replyUserId: undefined,
            },
            treeId: null,
            imageId: null,
            clientHeight: "1000px",
            showSide: false,
            isTop: true,
            visible: false,
            selectTab: 'recommend',

            headerStyle: {
                transform: `translateY(-52px)`
            },

            datas: [],
            page: 1,
            totalPage: 1,
            pageSize: 20,
            headTags: [
                {tagId: 'recommend', tagName: this.$t('index.recommend')},
                {tagId: 'hot_spots', tagName: this.$t('index.hot')},
                {tagId: 'follow', tagName: this.$t('index.follow')},
            ],
            filterTags: [],
            loading: false,
            hasMore: true,

            keywords: '', //搜索的绑定值
            showSearchList: false, //显示搜索列表
            searchList: [], //搜索列表
            activeSearch: '', //搜索列表选中

            currentIndex: 0,
            tagId: this.$route.params.id,

            ListIndex: -1, //当前查看到第几张图片
        }
    },
    mounted() {
        this.shareUrl = shareURL
        if (!localStorage.getItem('language')) {
            localStorage.setItem('language','ja')
        }
        this.$i18n.locale=localStorage.getItem('language');
        this.clientHeight = (document.documentElement.clientHeight + 100) + "px";
        this.getInitList();
        this.getTagByRecommend("header_recommend");

        console.log(String.fromCodePoint(128513));

        this.checkAuthority();
        this.checkToken();
        window.addEventListener("scroll", this.handleScroll, true);
    },
    computed: {
        initTabs() {
            return [
                {tagId: 'recommend', tagName: this.$t('index.recommend')},
                {tagId: 'hot_spots', tagName: this.$t('index.hot')},
                {tagId: 'follow', tagName: this.$t('index.follow')}
            ]
        },
        headTagsTab() {
            this.headTags[0].tagName = this.$t('index.recommend')
            this.headTags[1].tagName = this.$t('index.hot')
            this.headTags[2].tagName = this.$t('index.follow')
            return this.headTags
        },
        datalist1() {
            let data = this.datas[this.currentIndex] || []
            return data.filter(o => o.lastPicture)
        },
        datalist2() {
            let data = this.datas[this.currentIndex] || []
            return data.filter(o => o.lastPicture && o.lastPicture.note && o.lastPicture.comment && !o.lastPicture.pictureUrl)
        }
    },
    methods: {
        submit() {
          this.$copyText(this.iframe).then(() => {
            this.$message.success(this.$t('index.copySucceed'))
            }).catch(() => {
               this.$message.error(this.$t('index.copyFailed'))
            })
        },
        onEmbed(pictureUrl, width, height) {
           this.iframe = `<iframe src=${pictureUrl} width=${width} height=${height} frameborder="0" scrolling="auto" referrerpolicy="unsafe-url" allowfullscreen></iframe>`
           this.dialogVisible = true
        },
        checkLogin() {
            this.visible = !localStorage.getItem('token');
            return this.visible;
        },
        addComment(type, content = undefined) {
            if (this.checkLogin()) {
                return;
            }
            if (!content) {
                return;
            }
            let userInfo = storage.get('userInfo') || '';
            if (userInfo) {
            content = content.replace(/\n/g, '<br>');
                let params = {
                    userId: userInfo.userid,
                    treeId: this.treeId,
                    imageId: this.imageId,
                    content: content,
                    type: type
                }
                this.replyInfo = {
                    commentIndex: undefined,
                    replyContent: undefined,
                    commentId: undefined,
                    replyUserId: undefined,
                };
                console.log("add comment params ==>", params);
                Api.addComment(params).then((res) => {

                    if (res.error_code == 0) {
                        this.$message.success(this.$t('detail.commentSuccess'));
                        this.visibleSecond = false
                    }
                }).catch(err => {
                    if (err.response.status == 401 || err.response.status == 400) {
                        this.$message.error(this.$t("tokenErrMessage"));
                        this.visible = true
                    }
                })
            }
        },
        scrollBotton() {
            document.querySelector("#content").scrollIntoView({
                behavior: "smooth"
            });
        },
        closeModal() {
            this.visible = false;
        },
        // 隐藏嵌入代码
        close() {
          this.dialogVisible = false
        },
        targetSide() {
            this.showSide = !this.showSide
        },
        changeTab(val) {
            this.scrollBotton();
            if (val.tagId == this.initTabs[2].tagId && this.checkLogin()) {
                // this.visible = true;
                return;
            }

            this.selectTab = val.tagId;
            this.datas = [];
            this.page = 1;
            this.totalPage = 1;
            this.getList();
        },

        //Header
        keydownEnter() {
            this.toSearchPage()
        },
        changeSearchEvent(val) {
            this.keywords = val;
            this.setSearchList();
        },


        //搜索框更改
        onChangeSearchValue(e) {
            this.keywords = e.target.value;
            this.showSearchList = e.target.value ? true : false
            this.setSearchList();
        },
        inputFocusHandle() {
           document.getElementById("search").style.boxShadow = "0.5px 0.5px 1px 1px #66666635";
            this.showSearchList = this.keywords ? true : false
            this.setSearchList();
        },
        inputBlurHandle() {
            document.getElementById("search").style.boxShadow = "0px 0px 0px 0px ";
            setTimeout(() => {
                this.showSearchList = false
            }, 200)
        },
        //搜索框选中
        changeSearchList(val) {
            Api.modifyUserSearchHistory(val.id, val.title)
            if (!localStorage.getItem('token')) {
                //  如果已经登录,调用接口保存搜索历史数据
            //     Api.modifyUserSearchHistory(val.id, val.title)
            // } else {
                // 保存到本地
                let oldHistory = JSON.parse(localStorage.getItem('searchHistory')) || [];
                if (val) {
                    if (oldHistory.indexOf(val.title)>-1) {
                        oldHistory.splice(oldHistory.indexOf(val.title),1)
                    }
                    oldHistory.unshift(val.title);
                    if (oldHistory.length > 10) {
                        oldHistory.splice(10)
                    }
                    localStorage.setItem("searchHistory", JSON.stringify(oldHistory));
                }
            }
            this.keywords = val.title;
            this.keywords = val.title;
            this.setSearchList();
            this.toSearchPage();
        },
        //绑定按键
        keyDown(e) {
            let e1 = e || event || window.event
            if (e1 && e1.keyCode == 13) {
                this.toSearchPage();
            }
            //键盘按键判断:左箭头-37;上箭头-38；右箭头-39;下箭头-40
            if (this.showSearchList) {
                if (!this.activeSearch) {
                    this.activeSearch = this.searchList.map((item) => item.title)[0];
                }
                let index = this.searchList.map((item) => item.title).indexOf(this.activeSearch);
                if (e1 && e1.keyCode == 38) {
                    index--
                    if (index < 0) {
                        index = 0
                    }

                    this.activeSearch = this.searchList.map((item) => item.title)[index];
                    this.keywords = this.activeSearch;
                } else if (e1 && e1.keyCode == 40) {
                    index++
                    if (index > this.searchList.length - 1) {
                        index = this.searchList.length - 1
                    }

                    this.activeSearch = this.searchList.map((item) => item.title)[index];
                    this.keywords = this.activeSearch;
                }

            }
        },

        //搜索页
        toSearchPage() {
            if (this.keywords != '') {
                this.$router.push({name: 'search', query: {keywords: this.keywords}})
            }
        },


        scrollEvent(event) {
            let clientHeight = document.documentElement.clientHeight;
            let scrollTop = event.target.scrollTop
            let num = clientHeight - 125;

            let headerStyle = {};
            if (scrollTop > num) {
                let tNum = (scrollTop - num) - 52;
                if (tNum >= 0) {
                    tNum = 0
                }
                headerStyle = {
                    transform: `translateY(${tNum}px)`
                }

            } else {
                headerStyle = {
                    transform: `translateY(-52px)`
                }
            }
            this.headerStyle = headerStyle

            if (!this.loading) {
                if (event.target.scrollHeight < (event.target.scrollTop + event.target.clientHeight + 30)) {
                    this.loading = true;
                    this.isTop = false;
                    this.getList();
                }
            }


            let currentIndex = this.currentIndex;
            let listIndexOld = 0
            for (let i = 0; i < document.querySelectorAll('.item-list-box').length; i++) {
                let item = $(".item-list-box").eq(i);
                let top = item.position().top;

                // 处理3条数据的数组,供上传页面使用
                // 当前查看到第几章图片
                listIndexOld = Math.abs(parseInt(($(".item-list-box").eq(0).position().top - 52) / ($(".item-list-box").eq(0).height() / 20)))
                if (listIndexOld !== this.ListIndex ) {
                    // 重计算条件赋值
                    this.ListIndex = listIndexOld
                    // 接合datas数据结构,计算分页及索引
                    let page = parseInt(listIndexOld / 20)
                    let index = parseInt(listIndexOld % 20)
                    let newDataEntryList = this.datas[page].slice(index)
                    if (newDataEntryList.length < 3 && this.datas[page]) {
                        newDataEntryList = newDataEntryList.concat([this.datas[page+1][0],this.datas[page+1][1]])
                    }
                    if (newDataEntryList.length < 3) {
                        newDataEntryList = this.datas[page].slice(17)
                    }
                    localStorage.setItem("selectItemList",JSON.stringify(newDataEntryList))


                }
                if (top > 52) {
                    break;
                }
                currentIndex = i
            }
            this.currentIndex = currentIndex


        },
        getInitList() {
            this.loading = true
            this.datas = [];
            this.page = 1;
            this.totalPage = 1;
            this.getList();
        },
        getList() {
            if (this.selectTab == this.initTabs[0].tagId) {
                // 获取推荐条目列表
                this.getRecommendList();
            } else if (this.selectTab == this.initTabs[1].tagId) {
                // 获取热门条目列表
                // this.getList();
                this.getRecommendList(this.tagId);
            } else if (this.selectTab == this.initTabs[2].tagId) {
                // 获取关注条目列表
                this.getEntryPage();
            } else {
                this.tagId = this.selectTab;
                this.getRecommendList(this.tagId);
            }
        },
        getEntryPage() {
            if (this.totalPage < this.page) {
                return;
            }
            Api.getEntryPage('my_follow', this.tagId, this.page, this.pageSize).then((res) => {
                if (res.error_code == 0) {
                    let data = res.result.data;
                    this.datas = this.datas.concat([data.list]);
                    this.totalPage = data.totalPageCount;
                    this.page++;
                }

            }).catch(err => {
                console.log("Get Entry Page ==> ", err)
            }).finally(() => {
                this.loading = false
            });
        },
        getRecommendList(tagId) {
            Api.getRecommend().then((res) => {
                let data = res.result.data;
                this.datas = this.datas.concat([data])
                this.loading = false
            })
        },
        getTagByRecommend(recommendType) {
            Api.getTagByRecommend(recommendType).then((res) => {
                let data = res.result.data;
                switch (recommendType) {
                    case "left_recommend":
                        this.leftTags = data;
                        break;
                    case "header_recommend":
                        this.headTags = this.initTabs.concat(data);
                        break;
                    case "filter_recommend":
                        this.filterTags = data;
                        break;
                }

            })
        },

        setSearchList() {
            if (this.keywords && this.keywords.trim() != '') {
                clearTimeout(searchTimer)
                searchTimer = setTimeout(() => {
                    if (this.keywords) {
                        Api.getSearch({
                            keywords: this.keywords
                        }).then((res) => {
                            let data = res.result.data
                            if (data.length > 0) {
                                this.searchList = data
                            }
                        });
                    }
                }, 300)
            } else {
                this.searchList = []
            }

        },

        goInDetail(entryId, entryTitle, pictureId) {
            Api.saveHistory(entryId, entryTitle, pictureId);
            window.open(`/${entryId}`)
        },


        shareHandle(index1, index2, item) {

        },
        favoriteHandle(index1, index2, item) {
            if (this.checkLogin()) {
                return;
            }
            let userInfo = storage.get('userInfo') || '';
            if (userInfo) {
                let params = {
                    userId: userInfo.userid,
                    imageId: item.lastPicture.pictureId
                }
                if (item.lastPicture.isFavorite) {
                    Api.postCancelFavorite(params).then((res) => {
                        item.lastPicture.isFavorite = false;
                        this.datas[index1][index2].lastPicture.isFavorite = false
                        this.$message.success(this.$t('index.cancelCollectionSuccess'));
                    }).catch(err => {
                        if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        })
                } else {
                    Api.postFavorite(params).then((res) => {
                        item.lastPicture.isFavorite = true;
                        this.datas[index1][index2].lastPicture.isFavorite = true
                        this.$message.success(this.$t('index.collectionSuccess'));
                    }).catch(err => {
                        if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        })
                }
            }

        },
        commentHandle(index1, index2, item) {
            if (this.checkLogin()) {
                return;
            }
            this.treeId = item.id
            this.imageId = item.lastPicture.pictureId
            this.visibleSecond = true
            this.$nextTick(()=>{
                $('#textarea')[0].select()
            })
        },
        likeHandle(index1, index2, item) {
            if (this.checkLogin()) {
                return;
            }
            let userInfo = storage.get('userInfo') || '';
            if (userInfo) {
                let params = {
                    pictureId: item.lastPicture.pictureId
                }
                if (item.lastPicture.isLike) {
                    Api.postpictureLike(params).then((res) => {
                        this.datas[index1][index2].lastPicture.isLike = false
                        this.$message.success(this.$t('index.cancelLikeSuccess'));
                    }).catch(err => {
                        if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        })
                } else {
                    Api.postpictureLike(params).then((res) => {
                        this.datas[index1][index2].lastPicture.isLike = true
                        this.$message.success(this.$t('index.likeSuccess'));
                    }).catch(err => {
                        if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        })
                }
            }
        },
        onload(index) {
            $('.item-list-img'+index).hide();
            $('.item-list-img'+index).fadeIn(500);
        },
        onloadvice(index){
            $('.item-list-img-simple'+index).hide();
            $('.item-list-img-simple'+index).fadeIn(500);
        },
        onCopy(url) {
            this.$copyText(url).then(() => {
            this.$message.success(this.$t('index.copySucceed'))
            }).catch(() => {
               this.$message.error(this.$t('index.copyFailed'))
            })
        },
        bloomHandle(index1, index2, item) {
            if (this.checkLogin()) {
                return;
            }
            let userInfo = storage.get('userInfo') || '';
            if (userInfo) {
                let params = {
                    userId: userInfo.userid,
                    treeId: item.id
                }
                if (item.isFollow) {
                    Api.postCancelNote(params).then((res) => {
                        item.lastPicture.isFavorite = false;
                        this.datas[index1][index2].isFollow = false
                        this.$message.success(this.$t('index.cancelFollowSSuccess'));
                    }).catch(err => {
                        if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        })
                } else {
                    Api.postNote(params).then((res) => {
                        item.lastPicture.isFollow = true;
                        this.datas[index1][index2].isFollow = true
                        this.$message.success(this.$t('index.followSuccess'));
                    }).catch(err => {
                        if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        })
                }
            }
        },

        getWaitDurationTime(start, end = moment()) {
          let timeZone = new Date().getTimezoneOffset()/60
          if (typeof timeZone !== "number") {
            return new Date();
          }
          if (start, timeZone) {
            let startTime = moment(start);
            let newStart = new Date(startTime - 3600000 * timeZone);
            let newTime = moment(newStart);
            let endTime = moment(end);
            if (newTime.isSame(end)) {
              return ''
            }

                let waitTime = moment.duration(endTime - newTime, 'ms');
                let years = waitTime.get('years');
                let months = waitTime.get('months');
                let days = waitTime.get('days');
                let hours = waitTime.get('hours');
                let minutes = waitTime.get('minutes');

                if (!years && !months && !days && !hours) {
                    if (minutes >= 1) {
                        return minutes + this.$t('time.minutesAgo')
                    } else {
                        return this.$t('time.justNow')
                    }
                } else if (!years && !months && !days && hours) {
                    if (hours > 1) {
                        return hours + this.$t('time.hoursAgo')
                    } else {
                        return hours + this.$t('time.hourAgo')
                    }
                } else if (!years && !months && days ) {
                    if (days > 1) {
                        return days + this.$t('time.daysAgo')
                    } else {
                        return days + this.$t('time.dayAgo')
                    }
                } else if (!years && months ) {
                    if (months > 1) {
                        return months + this.$t('time.monthsAgo')
                    } else {
                        return months + this.$t('time.monthAgo')
                    }
                } else if (years ) {
                    if (years > 1) {
                        return years + this.$t('time.yearsAgo')
                    } else {
                        return years + this.$t('time.yearAgo')
                    }
                } else {
                    return moment(newTime).format('YYYY.M.D HH:mm:ss')
                }
            }
            return ''
        },

        checkToken() {
            let token = this.$route.query.token;
            if (token) {
                storage.set('token', token);
                Api.getMe().then(res => {
                    localStorage.setItem("userInfo",JSON.stringify(res.result && res.result.data || null));
                    this.$message.success(res.reason || this.$t('login.success'));
                    window.location.href="/";
                }).catch(error => {
                    let msg = error.response && error.response.data && error.response.data.reason || this.$t('login.fail');
                    this.$message.error(msg);
                })
            }
        },
        checkAuthority() {
            let code = this.$route.query.code;
            let state = this.$route.query.state;

            let params = {
                code: code,
                state: state
            };
            if (state == "wechat") {
                Api.wechatAuthority(params).then(res => {
                    localStorage.setItem("userInfo",JSON.stringify(res.result && res.result.data || null));
                    this.$message.success(res.reason || this.$t('login.success'));
                    window.location.href="/";
                }).catch(error => {
                    let msg = error.response && error.response.data && error.response.data.reason || this.$t('login.fail');
                    this.$message.error(msg);
                })
            }
        },
          // 移动函数
       toUp() {
        let clientHeight = document.documentElement.clientHeight;
            // let upTop = event.target.scrollTop
       let upTop = document.getElementById("center-box-info").scrollTop;
        if(upTop >= 0){
         document.getElementById("center-box-info").scrollTop -= 130;

         }
       },
     toDown() {
      let top = document.getElementById("center-box-info").scrollTop;
        if(top >= 0){
          document.getElementById("center-box-info").scrollTop+=130;
        }
    },
    handleScroll(){
          // tab2的scrollTop
        this.upScrollTop = document.getElementById("center-box-info").scrollTop;
        // 窗口可视区域高度
         this.upWindowHeight = document.getElementById("center-box-info").clientHeight
       //变量scrollHeight是滚动条的总高度
         this.upScrollHeight = document.getElementById("center-box-info").scrollHeight -1
          if(this.upScrollTop+this.upWindowHeight>=this.upScrollHeight){
       // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
           this.shows = false
        }else{
          this.shows = true
        }

        if(this.upScrollTop > 0){
         this.upshows = true;
        }else{
            this.upshows = false
        }
    }
 }
}

</script>
<style lang="scss">
body {
    background: #F8F8F8 !important;
}

.home-box {
    width: 100%;
    height: calc(100vh - 50px);
    overflow-y: auto;
    // background-color: #DEDEDE;

    .container {
        width: 1300px;
        margin: 0 auto;

        .container-first {
            display: flex;
            width: 100%;
            height: calc(100vh - 52px - 15px - 50px);
            align-items: center;
            justify-content: center;
            position: relative;
            flex-flow:column ;
            .logo-box{
                padding: 0 21px;
                display: flex;
                text-align: center;
                z-index: 1;
                position: relative;
                bottom: 5%;
                width: 350px;
                .img-logo{
                    margin:auto;
                    width: 100%;
                    height: 100%;
                    // object-fit: cover;
                }
            }

            .search-box {
                /*width: 440px;*/
                width: 720px;
                height: 46px;
                background: #fff;
                border-radius: 25px;
                padding: 0 21px;
                display: flex;
                align-items: center;
                z-index: 1;
                position: relative;
                border: 1px solid #66666645;

                .search-icon {
                    width: 14px;
                    height: 14px;
                }

                .search-input {
                    flex: 1;
                    height: 14px;
                    margin-left: 21px;
                    font-size: 14px;
                    font-weight: 400;
                    border: none;
                    outline: none;
                    background: none;
                }
                .search-list {
                    width: 100%;
                    height: 380px;
                    background: #fff;
                    position: absolute;
                    top: 46px;
                    left: 0;
                    padding-bottom: 16px;
                    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
                    border-radius: 0 0 23px 23px;
                    .search-list-item {
                        font-size: 14px;
                        font-weight: bold;
                        color: #666666;
                        line-height: 1;
                        padding: 0 20px;
                        margin-top: 16px;
                        cursor: pointer;

                        &.active {
                            color: #0870D1;
                        }
                    }
                }
            }
            .search-buttom{
                margin-top:2%;
            .search{
                border:none;
                border-radius: 8%;
                align-items: center;
                height: 30px;
                cursor: pointer;
                background-color: rgb(187 180 180 / 13%);
                color: #747171;
                font-size: 15px;
                font-weight: 500;
              }
            }
            .tab-center {
                position: absolute;
                width: 100%;
                height: 60px;
                white-space: nowrap;
                /*display: flex;*/
                /*align-items: center;*/
                /*justify-content: center;*/
                bottom: 0;
                line-height: 60px;
                overflow: auto;
                text-align: center;

                .tab-item {
                    vertical-align: middle;
                    white-space: nowrap;
                    display: inline-block;
                    margin: 0 31px;
                    margin-top: 15px;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 26px;
                    color: #666666;
                    cursor: pointer;

                    &.active {
                        color: #0870D1;
                        font-weight: bold;
                        position: relative;

                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            width: 100%;
                            height: 4px;
                            background: #0870D1;
                        }
                    }
                }
            }
        }

        .container-center {
            margin-top: 20px;

            .list-center {
                margin-top: 20px;
                display: flex;
                .list-left {
                    width: 840px;

                    .list-loading {
                        display: flex;
                        align-items: center;
                        margin-top: 20px;
                        height: 40px;
                        justify-content: center;

                        > span {
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            margin-right: 6px;
                            border-radius: 50%;
                            background: #EBEBEB;
                            -webkit-animation: load 1.04s ease infinite;
                            &:last-child {
                                margin-right: 0px;
                            }
                        }

                        @-webkit-keyframes load {
                            0% {
                                opacity: 1;
                            }
                            100% {
                                opacity: 0;
                            }
                        }

                        span:nth-child(1) {
                            -webkit-animation-delay: 0.13s;
                        }

                        span:nth-child(2) {
                            -webkit-animation-delay: 0.26s;
                        }

                        span:nth-child(3) {
                            -webkit-animation-delay: 0.39s;
                        }
                    }
                }

                .list-right {
                    margin-left: 20px;
                    width: 440px;
                    flex: 1;
                    .list-right-box {
                        position: sticky;
                        top: 72px;
                        max-height: calc(100vh - 200px);
                        overflow-y: auto;
                        .center-box-info {
                            &:first-child {
                                // margin-bottom: 20px;
                                background: #fff;
                                padding-left:5%;
                            }
                            .center-box-info-title {
                                font-size: 20px;
                                font-weight: bold;
                                line-height: 26px;
                                color: #333333;
                                text-align: center;
                                position: -webkit-sticky;
                                position: sticky;
                                top: 0px;
                                z-index:98;
                                background: #fff;
                                margin-bottom: 20px;
                                height: 60px;
                                padding: 16px 20px;
                                width:98%;
                            .item-list-img-last {
                                position: absolute;
                                z-index: 100;
                                border-radius: 50px;
                                width: 30px;
                                height: 30px;
                                right: 1%;
                                cursor: pointer;
                           }
                            }
                            .center-box-info-bottom{
                                position: sticky;
                                z-index:98;
                                background: #fff;
                                margin-bottom: 20px;
                                height: 60px;
                                padding: 16px 20px;
                                width:98%;
                                bottom: -1px;
                                .item-list-img-last {
                                position: absolute;
                                z-index: 100;
                                border-radius: 50px;
                                width: 30px;
                                height: 30px;
                                right: 1%;
                                cursor: pointer;
                           }
                            }
                            .center-box-info-box {
                                margin-top: 16px;
                                overflow: hidden;
                            }
                             .item-list-img-next {
                                position: absolute;
                                z-index: 100;
                                border-radius: 50px;
                                width: 30px;
                                height: 30px;
                                right: 1%;
                                cursor: pointer;
                                // margin-left: 35%;
                           }

                            .center-box-info-item {
                                margin-bottom: 20px;
                                background: #fff;
                                padding: 10px;

                                .title {
                                    font-size: 18px;
                                    font-weight: 400;
                                    line-height: 24px;
                                    color: #333333;
                                    text-align: center;
                                }

                                .dec {
                                    font-size: 14px;
                                    font-weight: 400;
                                    line-height: 20px;
                                    color: #999999;
                                    margin-top: 11px;
                                }

                                .time {
                                    text-align: right;
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 16px;
                                    color: #999999;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


}

@media (max-width: 840px) {
    .home-box .container {
        width: 100%;
        padding: 0 20px;
    }
    .home-box .container .container-center .list-center .list-left {
        width: 100%;
    }
    .home-box .container .container-center .list-center .list-right {
        display: none;
    }
    .home-box .container .container-center .list-center .list-left .item-list .item-icon-tabs {
        display: none;
    }

    .home-box .container .container-center .list-center .list-left .item-list .item-info {
        display: block;

        .info-right {
            > span {
                margin-left: 0;
                margin-right: 25px;
            }

            .item-icon-tabs {
                display: block;
                position: absolute;
                right: 0;
                top: 2px;
            }
        }
    }
    // .img-logo{
    //     width:80%
    // }
    .home-box .container .container-first .search-box{width:360px}
    .home-box .container .container-first .search-buttom{margin-top:6%}

}
// 隐藏滚动条
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}

</style>
