import axios from 'axios';
import storage from 'storejs';
import {trimParams,  urlParamsEncode} from '@/utils/common';
import {Constants} from "../constants";

export const CONTENT_TYPE = {
    jsonHeader: {
        'Content-Type': 'application/json;charset=utf-8'
    },
    urlencodedHeader: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    formHeader: {
        'Content-Type': 'multipart/form-data'
    },
};

// 分享图片baseUrl地址 ,测试环境
// export const shareURL= 'http://a.imamemo.com/';
// 分享图片baseUrl地址 ,生产环境
export const shareURL= Constants.env[Constants.environment].root_uri;

const service = axios.create({
    // 开发环境地址
    baseURL: Constants.env[Constants.environment].baseURL,
    // 测试环境地址
    // baseURL: 'http://a.imamemo.com/serve',
    // 生产环境地址
    // baseURL: 'https://ca237.imamemo.com/serve/',

    timeout: 300000, // 请求超时时间
    headers: CONTENT_TYPE.urlencodedHeader
});


let pending = []; //存储每个请求的取消函数和请求标识
let cancelToken = axios.CancelToken;
export let removePending = (urlKey,noParams = false) => {
    pending = pending.filter((item)=>{
        if(
          item.urlKey == urlKey ||
          ( noParams && item.urlKey.indexOf(urlKey) > -1 )
        ){
            item.f();
            return false;
        }else{
            return true;
        }
    })
}


//请求拦截器
service.interceptors.request.use(function (config) {
    //对请求添加Token
    if(storage.get('token')){
        config.headers.Authorization = storage.get('token') ? storage.get('token') : '';
    }

    let params =  '@PARAMS' + (config.params?urlParamsEncode(config.params):'');
    let urlKey = config.url + '&' + config.method + params;


    config.cancelToken = new cancelToken( (c)=>{
        if(config.isAbort){
            pending.push({ urlKey: urlKey, f: c });
        }
    });

    return config;
}, function (error) {
    return Promise.reject(error);
});

//响应拦截器
service.interceptors.response.use((response) => {
    // console.log(response.headers);

    let data = response.data;
    if ((response.config.url == "/login" || response.config.url == "/registerUserByOther" || response.config.url == "/auth/wechat") && response.headers.authorization) {
        storage.set('token', response.headers.authorization);
    }
    let pagination_properties = response.headers['x-pagination']
    if (pagination_properties) {
        data.pagination_properties = JSON.parse(pagination_properties);
    }


    let content_disposition = response.headers['content-disposition']
    if(response.headers['content-disposition']){
        data.content_disposition = content_disposition
        data.data = data;
    }

    let Impersonator = response.headers['x-impersonator']
    if (Impersonator) {
        if(typeof window.windowState == "object"){
            window.windowState.isInseeket = decodeURIComponent(Impersonator)
        }

    }else{
        if(typeof window.windowState == "object"){
            window.windowState.isInseeket = null
        }
    }

    if(typeof window.windowState == "object"){
        window.windowState.notServe = false
    }


    return data;
}, error => {
    console.log('响应错误-response', error.config, error.response);

    //断网
    if(!window.navigator.onLine){
        return Promise.reject(error)
    }

    //401
    let response = error.response ? error.response : {};
    if (response.status === 401) {
        storage.remove('token');
        storage.remove('userInfo');
    }
    if (response.status === 500) {
        // message.error('服务器内部错误');
    }

    //服务异常
    if(error.message === 'Network Error') {
        var config = error.config;
        var isRetry = config.retryObj ? config.retryObj.isRetry : false;
        if (!config || !config.retryObj || !config.retryObj.retry || isRetry) return Promise.reject(error);
        config.retryObj.retryCount = config.retryObj.retryCount || 0;
        if (config.retryObj.retryCount >= config.retryObj.retry) {

            //几次全部都是错误时 -- 服务异常
            if(typeof window.windowState == "object"){
                window.windowState.notServe = true
            }


            return Promise.reject(error);
        }

        config.retryObj.retryCount += 1;

        var backOff = new Promise(function (resolve) {
            setTimeout(function () {
                resolve();
            }, config.retryObj.retryDelay || 1);
        });

        return backOff.then(function () {
            return service(config);
        });
    }


    return Promise.reject(error)
});


// isRetry接口是否需要重试  默认为false
export function get(url, params = {},isAbort = false, retryObj = {isRetry: false, retry: 1, retryDelay: 1000, retryCount: 1}) {
    return service.get(url, {params: trimParams(params), retryObj: retryObj,isAbort:isAbort})
}

export function post(url, data = {}, headers = CONTENT_TYPE.urlencodedHeader, retryObj = {isRetry: false, retry: 1, retryDelay: 1000, retryCount: 1}) {
    let config = {
        retryObj: retryObj,
    };
    if (headers) {
        config.headers = headers;
        if (headers == CONTENT_TYPE.urlencodedHeader) {
            config.params = trimParams(data);
        }
    }
    return service.post(url, data, config)
}

export function patch(url, data = {}, retryObj = {isRetry: false, retry: 1, retryDelay: 1000, retryCount: 1}) {
    return service.patch(url, data, {retryObj: retryObj})
}

export function put(url, data = {}, retryObj = {isRetry: false, retry: 1, retryDelay: 1000, retryCount: 1}) {
    return service.put(url, data, {retryObj: retryObj})
}

export function del(url, data = {}, retryObj = {isRetry: false, retry: 1, retryDelay: 1000, retryCount: 1}) {
    // return service.delete(url, data, {retryObj: retryObj})
    return service.delete(url, {params: trimParams(data),retryObj: retryObj})
}
export function down(url, params = {}, retryObj = {isRetry: false, retry: 1, retryDelay: 1000, retryCount: 1,isDown:true}) {
    return service.get(url, {responseType:'blob',params: trimParams(params), retryObj: retryObj})
}

export default service;
