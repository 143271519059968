<template>
    <div class="list-item-simple" v-if="data.lastPicture.pictureUrl != null" @click="goInDetail(data.id, data.title, data.lastPicture?.pictureId)">
        <!--:style="{backgroundImage: `url(${data.lastPicture.pictureUrl})`}"-->
        <img @load="onloadvice" :src="data.lastPicture.pictureUrl" :class="'item-list-img-simple'+index"
             class="item-list-img" />
        <div class="list-item-simple-title" v-if="data.lastPicture.note">{{data.lastPicture.note}}</div>
        <div class="list-item-simple-dec line-clamp4" v-else>{{data.lastPicture.comment}}</div>
    </div>
    <!-- 无图片时显示 -->
    <div class="list-item" v-if="data.lastPicture.pictureUrl == null">
        <div class="list-item-simple-title" v-if="data.lastPicture.note">{{data.lastPicture.note}}</div>
        <div class="list-item-simple-dec line-clamp4" v-else>{{data.lastPicture.comment}}</div>
    </div>
</template>
<script>
    export default {
        name: 'ListItemSimple',
        props: ['data', 'index'],
        data() {
            return {}
        },
        computed: {
            searchWidth() {
                return this.tabs && this.tabs.length > 0 ? '440px' : '840px'
            }
        },
        methods: {
            onloadvice() {
                this.$emit('onloadvice');
            },
            goInDetail(entryId, entryTitle, pictureId){
              this.$emit('goInDetail', entryId, entryTitle, pictureId);
            },
            targetSide() {
                this.$emit('targetSide');
            },
            changeTab(val) {
                this.$emit('changeTab', val);
            }
        }
    }

</script>
<style scoped lang="scss">
    .list-item-simple{
        width: 190px;
        height: 110px;
        background: #F8F8F8;
        border-radius: 4px;
        margin-bottom: 21px;
        float: left;
        position: relative;
        display: flex;
        align-items: center;

        background-size: 100%;
        background-position: center;
        &:nth-child(2n){
            margin-left: 20px;
        }
        .item-list-img {
            cursor:pointer;
            width: 190px;
            height: 110px;
            background-size: cover;
            object-fit: cover;
        }
        .list-item-simple-title{
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #FFFFFF;
            position: absolute;
            bottom: 0;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .list-item-simple-dec{
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #333333;
            position: absolute;
            bottom: 0;
        }
    }
    .list-item{
        width: 190px;
        height: 110px;
        background: #F8F8F8;
        border-radius: 4px;
        margin-bottom: 21px;
        float: left;
        position: relative;
        display: flex;
        align-items: center;

        background-size: 100%;
        background-position: center;
         &:nth-child(2n){
            margin-left: 20px;
        }
        .list-item-simple-title{
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
            color: #272424;
            // position: absolute;
            bottom: 0;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
        }
        .list-item-simple-dec{
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #0f0f0f;
            position: absolute;
        }
    }
</style>
