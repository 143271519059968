<template>
  <!--<div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>-->
  <a-config-provider :locale="locale">
    <router-view/>
  </a-config-provider>

</template>
<script>
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
  import jaJP from 'ant-design-vue/lib/locale-provider/ja_JP';
  import enGB from 'ant-design-vue/lib/locale-provider/en_GB';
  import moment from 'moment';
  import 'moment/locale/zh-cn';
  moment.locale('zh-cn');

  export default {
    name: 'App',
    components: {

    },
    computed: {
      locale() {
        if (localStorage.getItem("language") === 'zh') return zhCN
        if (localStorage.getItem("language") === 'ja') return jaJP
        if (localStorage.getItem("language") === 'en') return enGB
      }
    },
    data() {
      return {
        // locale: enGB,
      }
    },
    methods: {

    }
  }

</script>

<style lang="scss">
  body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td { margin:0; padding:0; }
  body, button, input, select, textarea { font:12px/1.5tahoma, arial, \5b8b\4f53; }
  h1, h2, h3, h4, h5, h6{ font-size:100%; }
  address, cite, dfn, em, var { font-style:normal; }
  code, kbd, pre, samp { font-family:couriernew, courier, monospace; }
  small{ font-size:12px; }
  ul, ol { list-style:none; }
  a { text-decoration:none; }
  a:hover { text-decoration:underline; }
  sup { vertical-align:text-top; }
  sub{ vertical-align:text-bottom; }
  legend { color:#000; }
  fieldset, img { border:0; }
  button, input, select, textarea { font-size:100%; }
  table { border-collapse:collapse; border-spacing:0; }
  *{
    box-sizing: border-box;
  }

  body,*{
    font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }


  .container{
      width: 1300px;
      margin: 0 auto;
  }


  .container-bg-white{
      background: #ffffff;
  }

  .line-clamp1{
      overflow: hidden;/*超出部分隐藏*/
      text-overflow:ellipsis;/* 超出部分显示省略号 */
      white-space: nowrap;/*规定段落中的文本不进行换行 */
  }
  .line-clamp2 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  .line-clamp2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .line-clamp3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .line-clamp4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  body {
      .ant-btn-primary[disabled],
      .ant-btn-primary[disabled]:hover,
      .ant-btn-primary[disabled]:focus,
      .ant-btn-primary[disabled]:active {
          background: #EEEEEE;
          border-color: #EEEEEE;
          color: #999999;
      }
  }
</style>
