<template>
<Embeddialog :showDialog="dialogVisible" ref="Embeddialog" :content="iframe" :footer="null" centered cancelText @submit="submit" @close="close"/>
  <Header
    :tabs="tabs"
    :selectTab="selectTab"
    :showSearch="showSearch"
    @targetSide="targetSide"
    @changeTab="changeTab"
    :value="keywords"
    @keydownEnter="keydownEnter"
    @changeSearchEvent="changeSearchEvent"
  />
  <div class="bg">
    <div class="container">
      <div class="mine-center-div">
        <div class="mine-center-left">
          <img
            class="img-user"
            :src="info.userLogo ? info.userLogo : require('@/assets/avatar.svg')"
            alt=""
          >
          <div class="user-name-id">
            <a-tooltip placement="topLeft" :title="info.userName">
              <div class="user-name">
              {{info.userName || "默认账户昵称"}}
              </div>
            </a-tooltip>
            <a-tooltip placement="bottomLeft" :title="info.userId">
              <div class="user-id">{{info.userId || "默认用户ID"}}</div>
            </a-tooltip>
          </div>
        </div>

        <div class="mine-center-right">
          <ul>
            <li>
              <div>
                <button
                :style="'color:'+ (clickText === 'my_create' ? '#1673CE' : '#333333')"
                class="nums"
                @click="create('my_create')"
              >{{info.create}}</button>
              </div>
              <div>
                <button
                class="item-text"
                :style="'color:'+ (clickText === 'my_create' ? '#1673CE' : '#666666')"
                @click="create('my_create')"
              >{{$t('myInfo.create')}}</button>
              </div>
            </li>
            <li>
              <div>
              <button
                :style="'color:'+ (clickText === 'my_favorite' ? '#1673CE' : '#333333')"
                class="nums"
                @click="create('my_favorite')"
              >{{ info.favorite }}</button>
              </div>
              <div>
              <button
                @click="create('my_favorite')"
                class="item-text"
                :style="'color:'+ (clickText === 'my_favorite' ? '#1673CE' : '#666666')"
              >{{$t('myInfo.favorite')}}</button>
              </div>
            </li>
            <li>
              <div>
              <button
                :style="'color:'+ (clickText === 'my_follow' ? '#1673CE' : '#333333')"
                class="nums"
                @click="create('my_follow')"
              >{{ info.follow }}</button>
              </div>
              <div>
              <button
                @click="create('my_follow')"
                class="item-text"
                :style="'color:'+ (clickText === 'my_follow' ? '#1673CE' : '#666666')"
              >{{$t('myInfo.follow')}}</button>
              </div>
            </li>
            <li>
              <div>
              <button
                :style="'color:'+ (clickText === 'my_history' ? '#1673CE' : '#333333')"
                class="nums"
                @click="create('my_history')"
              >{{ info.history }}</button>
              </div>
              <div>
              <button
                @click="create('my_history')"
                class="item-text"
                :style="'color:'+ (clickText === 'my_history' ? '#1673CE' : '#666666')"
              >{{$t('myInfo.history')}}</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="home-box"
      @scroll="scrollEvent"
    >
      <div
        class="container-center"
        style="margin-top: 10px"
      >
        <div
          class="list-center"
          :style="{minHeight: clientHeight}"
        >
          <div class="list-left">
            <div
              class="item-list-box"
              v-for="(data,i) in datas"
              :key="i"
            >
              <MyListItem
                v-for="(item,index) in data"
                :key="item.id"
                :data="item"
                :index="item.id?item.id:item.pictureId"
                @goInDetail="goInDetail"
                @bloomHandle="bloomHandle(i,index,item)"
                @shareHandle="shareHandle(i,index,item)"
                @favoriteHandle="favoriteHandle(i,index,item)"
                @commentHandle="commentHandle(i,index,item)"
                @likeHandle="likeHandle(i,index,item)"
                @onload="onload(item.id?item.id:item.pictureId)"
                @onCopy="onCopy(shareUrl+(item.id?item.id:item.entryId)+'?'+'pictureId='+(item.lastPicture?item.lastPicture.pictureId:item.pictureId))"
                @onEmbed="onEmbed(item.lastPicture?item.lastPicture.pictureUrl:item.pictureUrl, item.lastPicture?item.lastPicture.pictureWidth:item.pictureWidth, item.lastPicture?item.lastPicture.pictureHeight:item.pictureHeight)"
              />
            </div>
            <div
              class="list-loading"
              v-if="loading"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
  <comment-dialog
          :content="replyInfo.replyContext"
          @addComment="addComment"
          :visibleSecond="visibleSecond"
          @closeDialog="visibleSecond=false"
  >
  </comment-dialog>
  <Login
    :visible="visible"
    :closeModal="closeModal"
  />
</template>

<script>
import MyListItem from '@/components/MyListItem.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue';
import Api from "../Api";
import storage from "storejs";
import $ from 'jquery';
import Login from '@/views/Login.vue'
import CommentDialog from "@/components/Commentdialog.vue"
import {shareURL} from '@/utils/request';
import Embeddialog from "@/components/Embeddialog.vue";
let searchTimer = undefined;
export default {
  name: "MyInfo",
  components: {
    Header,
    Footer,
    MyListItem,
    Login,
    CommentDialog,
    Embeddialog
  },
  data () {
    return {
        iframe: '',
        dialogVisible: false,
        shareUrl: '',
        visibleSecond: false,
        replyInfo: {
            commentIndex: undefined,
            replyContent: undefined,
            commentId: undefined,
            replyUserId: undefined,
        },
        treeId: null,
        imageId: null,
      clientHeight: "1000px",
      visible: false,
      page: 1,
      totalPage: 1,
      pageSize: 20,
      clickText: '',
      loading: false,
      datas: [],
      showSearch: true,
      tabs: [],
      selectTab: undefined,
      keywords: undefined,
      info: {
        userId: undefined,
        userLogo: undefined,
        userName: undefined,
        create: 0,
        favorite: 0,
        history: 0,
        follow: 0
      }
    };
  },
  mounted () {
    this.shareUrl = shareURL
    this.clientHeight = (document.documentElement.clientHeight + 100) + "px";
    this.page = 1;
    this.totalPage = 1;
    this.clickText = 'my_create'
    this.loading = true
    this.getLocationUser();
    this.getPicturecontent(this.clickText)
  },
  methods: {
      // 隐藏嵌入代码
      close() {
        this.dialogVisible = false
       },
      submit() {
          this.$copyText(this.iframe).then(() => {
            this.$message.success(this.$t('index.copySucceed'))
            }).catch(() => {
               this.$message.error(this.$t('index.copyFailed'))
            })
      },
      onCopy(url) {
          this.$copyText(url).then(() => {
          this.$message.success(this.$t('index.copySucceed'))
           }).catch(() => {
               this.$message.error(this.$t('index.copyFailed'))
           })
      },
      onEmbed(pictureUrl, width, height) {
           this.iframe = `<iframe src=${pictureUrl} width=${width} height=${height} frameborder="0" scrolling="auto" referrerpolicy="unsafe-url" allowfullscreen></iframe>`
           this.dialogVisible = true
      },
      addComment(type, content = undefined) {
          if (this.checkLogin()) {
              return;
          }
          if (!content) {
              return;
          }
          let userInfo = storage.get('userInfo') || '';
          if (userInfo) {
              let params = {
                  userId: userInfo.userid,
                  treeId: this.treeId,
                  imageId: this.imageId,
                  content: content,
                  type: type
              }
              this.replyInfo = {
                  commentIndex: undefined,
                  replyContent: undefined,
                  commentId: undefined,
                  replyUserId: undefined,
              };
              console.log("add comment params ==>", params);
              Api.addComment(params).then((res) => {

                  if (res.error_code == 0) {
                      this.$message.success(this.$t('detail.commentSuccess'));
                      this.visibleSecond = false
                  }
              })
          }
      },
    create (str) {
      this.datas = []
      this.page = 1
      this.totalPage = 1
      this.loading = true
      this.clickText = str
      this.getList(str);
    },
    getList (str) {
      if (str === 'my_follow') {
        this.getfollow()
      } else {
        this.getPicturecontent(str)
      }
    },
    getPicturecontent (str) {
      if (this.totalPage < this.page) {
        return;
      }
      Api.getPicturecontent(str, this.page, this.pageSize).then((res) => {
        if (res.error_code == 0) {
          let data = res.result.data;
          this.datas = this.datas.concat([data.list]);
          this.totalPage = data.totalPageCount;
          this.page++;
          console.log(data + str);
          if(str == 'my_create'){
             this.info.create = data.totalSize || 0;
          }
        }
      }).catch(err => {
        console.log("Get Entry Page ==> ", err)
      }).finally(() => {
        this.loading = false
      });
    },
    getfollow () {
      if (this.totalPage < this.page) {
        return;
      }
      Api.getfollow('my_follow', this.page, this.pageSize).then((res) => {
        if (res.error_code == 0) {
          let data = res.result.data;
          this.datas = this.datas.concat([data.list]);
          this.totalPage = data.totalPageCount;
          this.page++;
        }
      }).catch(err => {
        console.log("Get Entry Page ==> ", err)
      }).finally(() => {
        this.loading = false
      });
    },
    goInDetail (entryId, entryTitle, pictureId) {
      if (entryId) {
        Api.saveHistory(entryId, entryTitle, pictureId);
        this.$router.push({path: '/' + entryId, query: {pictureId: pictureId}})
      }
    },
      checkLogin() {
          this.visible = !localStorage.getItem('token');
          return this.visible;
      },
    bloomHandle (index1, index2, item) {
      if (this.checkLogin()) {
        return;
      }
      let userInfo = storage.get('userInfo') || '';
      if (userInfo) {
        let params = {
          userId: userInfo.userid,
          treeId: item.id?item.id:item.entryId
        }
        if (item.isFollow) {
          Api.postCancelNote(params).then((res) => {
            item.lastPicture ? item.lastPicture.isFavorite : item.isFavorite = false;
            this.datas[index1][index2].isFollow = false
            this.$message.success(this.$t('index.cancelFollowSSuccess'));
            this.getLocationUser();
            if (this.clickText === 'my_follow') {
              this.datas = []
              this.page = 1
              this.totalPage = 1
              this.loading = true
              this.getList(this.clickText);
            }
          })
        } else {
          Api.postNote(params).then((res) => {
            item.lastPicture ? item.lastPicture.isFollow : item.isFollow = true;
            this.datas[index1][index2].isFollow = true
            this.$message.success(this.$t('index.followSuccess'));
            this.getLocationUser();
            if (this.clickText === 'my_follow') {
              this.datas = []
              this.page = 1
              this.totalPage = 1
              this.loading = true
              this.getList(this.clickText);
            }
          })
        }
      }
    },
    favoriteHandle (index1, index2, item) {
      if (this.checkLogin()) {
        return;
      }
      let userInfo = storage.get('userInfo') || '';
      if (userInfo) {
        let params = {
          userId: userInfo.userid,
          imageId: item.lastPicture ? item.lastPicture.pictureId : item.pictureId
        }
        if (item.lastPicture ? item.lastPicture.isFavorite : item.isFavorite) {
          Api.postCancelFavorite(params).then((res) => {
            item.lastPicture ? item.lastPicture.isFavorite : item.isFavorite = false;
            this.datas[index1][index2].lastPicture ? this.datas[index1][index2].lastPicture.isFavorite : this.datas[index1][index2].isFavorite = false
            this.$message.success(this.$t('index.cancelCollectionSuccess'));
            this.getLocationUser();
            if (this.clickText === 'my_favorite') {
              this.datas = []
              this.page = 1
              this.totalPage = 1
              this.loading = true
              this.getList(this.clickText);
            }
          })
        } else {
          Api.postFavorite(params).then((res) => {
            item.lastPicture ? item.lastPicture.isFavorite : item.isFavorite = true;
            this.datas[index1][index2].lastPicture ? this.datas[index1][index2].lastPicture.isFavorite : this.datas[index1][index2].isFavorite = true
            this.$message.success(this.$t('index.collectionSuccess'));
            this.getLocationUser();
            if (this.clickText === 'my_favorite') {
              this.datas = []
              this.page = 1
              this.totalPage = 1
              this.loading = true
              this.getList(this.clickText);
            }
          })
        }
      }
    },
      commentHandle(index1, index2, item) {
          if (this.checkLogin()) {
              return;
          }
          this.treeId = item.id
          this.imageId = item.pictureId
          this.visibleSecond = true
          this.$nextTick(()=>{
              $('#textarea')[0].select()
          })
      },
    likeHandle (index1, index2, item) {
      if (this.checkLogin()) {
        return;
      }
      let userInfo = storage.get('userInfo') || '';
      if (userInfo) {
        let params = {
          pictureId: item.lastPicture ? item.lastPicture.pictureId : item.pictureId
        }
        if (item.lastPicture ? item.lastPicture.isLike : item.isLike) {
          Api.postpictureLike(params).then((res) => {
            this.datas[index1][index2].lastPicture ? this.datas[index1][index2].lastPicture.isLike : this.datas[index1][index2].isLike = false
            this.$message.success(this.$t('index.cancelLikeSuccess'));
          })
        } else {
          Api.postpictureLike(params).then((res) => {
            this.datas[index1][index2].lastPicture ? this.datas[index1][index2].lastPicture.isLike : this.datas[index1][index2].isLike = true
            this.$message.success(this.$t('index.likeSuccess'));
          })
        }
      }
    },
    onload (index) {
      $('.item-list-img' + index).hide();
      $('.item-list-img' + index).fadeIn(500);
    },
    closeModal () {
      this.visible = false;
    },
    targetSide () {
      this.showSide = !this.showSide
    },
    changeTab (val) {
      this.selectTab = val
    },

    //Header
    keydownEnter () {
      this.toSearchPage()
    },
    changeSearchEvent (val) {
      this.keywords = val;
      this.setSearchList();
    },
    //搜索页
    toSearchPage () {
      if (this.keywords != '') {
        this.$router.push({ name: 'search', query: { keywords: this.keywords } })
      }
    },
    setSearchList () {
      if (this.keywords && this.keywords.trim() != '') {
        clearTimeout(searchTimer)
        searchTimer = setTimeout(() => {
          Api.getSearch({
            keywords: this.keywords
          }).then((res) => {
            let data = res.result.data.map((item) => item.title);
            if (data.length > 0) {
              this.searchList = data
            }
          })
        }, 300)
      } else {
        this.searchList = []
      }

    },

    getLocationUser () {
      let userInfo = storage.get('userInfo') || '';
      if (userInfo) {
        this.info = {
          ...this.info,
          userId: userInfo.userid,
          userLogo: userInfo.avatar,
          userName: userInfo.username,
        }
        Api.getLocationUser().then((res) => {

          if (res.error_code == 0) {
            let data = res.result.data;
            this.info = {
              ...this.info,
              // create: data.create || 0,
              favorite: data.favorite || 0,
              history: data.history || 0,
              follow: data.follow || 0,
            }
          }
        });
      }
    },
    scrollEvent (event) {
      let clientHeight = document.documentElement.clientHeight;
      let scrollTop = event.target.scrollTop
      let num = clientHeight - 125;

      let headerStyle = {};
      if (scrollTop > num) {
        let tNum = (scrollTop - num) - 52;
        if (tNum >= 0) {
          tNum = 0
        }
        headerStyle = {
          transform: `translateY(${tNum}px)`
        }

      } else {
        headerStyle = {
          transform: `translateY(-52px)`
        }
      }
      this.headerStyle = headerStyle

      if (!this.loading) {
        if (event.target.scrollHeight < (event.target.scrollTop + event.target.clientHeight + 30)) {
          this.loading = true;
          this.isTop = false;
          this.getList(this.clickText);
        }
      }

      let currentIndex = this.currentIndex;
      for (let i = 0; i < document.querySelectorAll('.item-list-box').length; i++) {
        let item = $(".item-list-box").eq(i);
        let top = item.position().top;
        if (top > 52) {
          break;
        }
        currentIndex = i
      }
      this.currentIndex = currentIndex
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 940px;
  padding-top: 72px;
}
.bg {
  background: #f2f4f7;
  min-height: calc(100vh);
}
.mine-center-div {
  background: #ffffff;
  display: flex;
  padding: 15px;

  .mine-center-left {
    display: flex;
    align-items: center;
    margin-right: 20px;
    width: 350px;
    margin-left: 20px;
    .img-user {
      margin-right: 26px;
      width: 75px;
      height: 75px;
    }
    .user-name-id {
      display: flex;
      flex-direction: column;
      /*align-items: center;*/
      /*max-width: 215px;*/
      //   margin-left: 26px;
      .user-name {
        width:fit-content;
        max-width: 215px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        font-size: 20px;
        font-weight: bold;
        line-height: 26px;
        color: #333333;
      }
      .user-id {
        width:fit-content;
        max-width: 215px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        margin-top: 13px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #666666;
      }
    }
  }
  .mine-center-right {
    margin-left: -32px;
    flex: 1;
    ul {
      height: 100%;
      display: flex;
      align-items: center;
      li {
        width: 152px;
        text-align: center;
        position: relative;

        &::after {
          display: inline-block;
          content: "";
          width: 2px;
          height: 26px;
          background: #eeeeee;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }

      .nums {
        cursor: pointer;
        background-color: unset;
        border: none;
        font-size: 22px;
        font-weight: bold;
        line-height: 29px;
        color: #333333;
      }

      .item-text {
        cursor: pointer;
        background-color: unset;
        border: none;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #666666;
      }
    }
  }
}
.footer-box {
}

@media (max-width: 840px) {
  .container {
    width: 100%;
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  .mine-center-div {
    display: block;
    .mine-center-left {
      width: 100%;
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-left: 0px;
      .user-name-id{
          align-items: center !important;
        max-width: 100% !important;
        .user-name{
          max-width: 100% !important;
        }
        .user-id{
          max-width: 100% !important;
        }
      }
      .img-user {
        margin-right: 0;
      }
    }
    .mine-center-right {
      margin-left: 0;
      margin-top: 30px;
      ul {
        display: block;

        li {
          width: 100%;
          display: block;
          height: 48px;
          padding-top: 12px;

          border-bottom: 1px solid #eeeeee;
          &::after {
            display: none;
          }

          .nums {
            float: right;
            color: #cccccc;
          }

          .item-text {
            float: left;
            margin-top: 0;
            line-height: 29px;
          }
        }
      }
    }
  }
}
.list-loading {
  display: flex;
  align-items: center;
  margin-top: 20px;
  height: 40px;
  justify-content: center;

  > span {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    border-radius: 50%;
    background: #ebebeb;
    -webkit-animation: load 1.04s ease infinite;

    &:last-child {
      margin-right: 0px;
    }
  }

  @-webkit-keyframes load {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  span:nth-child(1) {
    -webkit-animation-delay: 0.13s;
  }

  span:nth-child(2) {
    -webkit-animation-delay: 0.26s;
  }

  span:nth-child(3) {
    -webkit-animation-delay: 0.39s;
  }
}
.list-left {
  width: 840px;
  margin: 0 auto;
  .list-loading {
    display: flex;
    align-items: center;
    margin-top: 20px;
    height: 40px;
    justify-content: center;

    > span {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 6px;
      border-radius: 50%;
      background: #ebebeb;
      -webkit-animation: load 1.04s ease infinite;

      &:last-child {
        margin-right: 0px;
      }
    }

    @-webkit-keyframes load {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    span:nth-child(1) {
      -webkit-animation-delay: 0.13s;
    }

    span:nth-child(2) {
      -webkit-animation-delay: 0.26s;
    }

    span:nth-child(3) {
      -webkit-animation-delay: 0.39s;
    }
  }
}
.list-center {
  margin-top: 20px;
  display: flex;

  .list-left {
    width: 840px;
    .list-loading {
      display: flex;
      align-items: center;
      margin-top: 20px;
      height: 40px;
      justify-content: center;

      > span {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 6px;
        border-radius: 50%;
        background: #ebebeb;
        -webkit-animation: load 1.04s ease infinite;

        &:last-child {
          margin-right: 0px;
        }
      }

      @-webkit-keyframes load {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }

      span:nth-child(1) {
        -webkit-animation-delay: 0.13s;
      }

      span:nth-child(2) {
        -webkit-animation-delay: 0.26s;
      }

      span:nth-child(3) {
        -webkit-animation-delay: 0.39s;
      }
    }
  }
}
.container-center {
  margin-top: 20px;

  .list-center {
    margin-top: 20px;
    display: flex;

    .list-left {
      width: 840px;
      .list-loading {
        display: flex;
        align-items: center;
        margin-top: 20px;
        height: 40px;
        justify-content: center;

        > span {
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 6px;
          border-radius: 50%;
          background: #ebebeb;
          -webkit-animation: load 1.04s ease infinite;

          &:last-child {
            margin-right: 0px;
          }
        }

        @-webkit-keyframes load {
          0% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }

        span:nth-child(1) {
          -webkit-animation-delay: 0.13s;
        }

        span:nth-child(2) {
          -webkit-animation-delay: 0.26s;
        }

        span:nth-child(3) {
          -webkit-animation-delay: 0.39s;
        }
      }
    }
  }
}
@media (max-width: 840px) {
  .home-box .container {
    width: 100%;
    padding: 0 20px;
  }
  .home-box .container .container-center .list-center .list-left {
    width: 100%;
  }
  .home-box .container .container-center .list-center {
    display: none;
  }
  .home-box
    .container
    .container-center
    .list-center
    .list-left
    .item-list
    .item-icon-tabs {
    display: none;
  }

  .home-box
    .container
    .container-center
    .list-center
    .list-left
    .item-list
    .item-info {
    display: block;

    .info-right {
      > span {
        margin-left: 0;
        margin-right: 25px;
      }

      .item-icon-tabs {
        display: block;
        position: absolute;
        right: 0;
        top: 2px;
      }
    }
  }
}
</style>
