<template>
  <div class="box">
    <div class="footer-box">
      <span>{{$t('footer.about')}}</span>
    <span class="terms" @click="onTerms">{{$t('footer.terms')}}</span>
    <span class="privacy" @click="onPrivacy">{{$t('footer.privacy')}}</span>
      <a-dropdown
        placement="bottomCenter"
        v-if="!(tabs && tabs.length>0)"
        :overlay-style="{width:'112px'}"
      >
        <!-- <span style="margin-right: 15px;cursor: pointer;">{{$i18n.locale==='zh'?'中文':$i18n.locale==='ja'?'日本語':'English'}}言語</span> -->
        <span style="margin-right: 15px;cursor: pointer;">{{$t('footer.language')}}</span>
        <template #overlay>
          <a-menu>
            <a-menu-item
              key="zh"
              @click="change('zh')"
            >中文</a-menu-item>
            <a-menu-item
              key="ja"
              @click="change('en')"
            >English</a-menu-item>
            <a-menu-item
              key="en"
              @click="change('ja')"
            >日本語</a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'SideNav',
    props: ['showSide'],
    data(){
      return {
      }
    },
    methods: {
      onTerms() {
        let routeData = this.$router.resolve({
         path: '/TermsOfService'
        });
        window.open(routeData.href, '_blank');
      },
      onPrivacy() {
        let routeData = this.$router.resolve({
         path: '/privacy'
        });
        window.open(routeData.href, '_blank');
      },
       change (type) {
      this.$i18n.locale = type
      localStorage.setItem("language", type)
    },
    }
  }

</script>
<style lang="scss">
.box{
    width: 100vw;
    height: 40px;
    background: #F9F9F9;
    line-height: 14px;
    position: fixed;
    bottom: 0;
    z-index: 1;
    color: #727272;
  .footer-box{
    margin: 14px 0 10px 0;
    padding-left: 79%;
    font-size: 16px;
    position: absolute;
    >span{
      margin-right: 25px;
      font-family: Microsoft YaHei;
    }
  }

}
.terms {
 cursor: pointer;
}
.privacy {
 cursor: pointer;
}
@media (max-width: 1536px) {
  .box{
    .footer-box{
      padding-left: 78%;
      font-size: small;
    }
  }
}
@media (max-width: 1280px) {
  .box{
    .footer-box{
      padding-left: 76%;
      font-size: small;
    }
  }
}
@media (max-width: 840px) {
  .box{
    .footer-box{
      padding-left: 20%;
      font-size: small;
    }
  }
}
</style>
