<template>
  <nav class="side-nav" v-show="showSide">
    <div class="side-nav-list"
         v-for="(data, index) in leftTags"
         @click="changeTag(data)"
         :key="index">
      <div
           :class="selectTag == data.tagId ? 'side-icon active' : 'side-icon'"></div>
      <div
           :class="selectTag == data.tagId ? 'side-text active' : 'side-text'">{{data.tagName}}</div>
    </div>
  </nav>
</template>
<script>
  import Api from "../Api";

  export default {
    name: 'SideNav',
    props: ['showSide'],
    data(){
      return {
        leftTags:[],
        selectTag: ''
      }
    },
    mounted() {
      this.getTagByRecommend();
    },
    methods: {
      goIndex(tagId) {
        this.$router.push({
          path: "/",
          params: {tagId: tagId}
        })
      },
      getTagByRecommend(){
        Api.getTagByRecommend("left_recommend").then((res)=>{
          let data = res.result.data;
          this.leftTags = data;
        })
      },
      changeTag(val) {
        if (this.selectTag == val.tagId) {
          this.selectTag = '';
        } else {
          this.selectTag = val.tagId;
          this.goIndex(this.selectTag);
        }
      },

    }
  }

</script>
<style lang="scss">
  .side-nav{
    width: 240px;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    padding-top: 111px;
    background: #fff;
    overflow: auto;
    .side-nav-list{
      width: 100%;
      height: 50px;
      opacity: 1;
      padding-left: 44px;
      display: flex;
      align-items: center;
      cursor: pointer;
      .side-icon{
        width: 14px;
        height: 14px;
        background: #CCCCCC;

        &.active {
          background: #0870D1;
          color: #fff;
        }
      }
      .side-text{
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #999999;
        margin-left: 12px;

        &.active {
          //background: #0870D1;
          color: #0870D1;
          font-weight: bold;
        }
      }
    }
  }

</style>