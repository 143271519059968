import Vue, { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import lazyPlugin from 'vue3-lazy'
import axios from "axios";
import i18n from './language/i18n'
import VueClipboard from 'vue-clipboard2'
// axios.defaults.headers.get['header-name'] = 'value'
import GoogleAuth from '@/config/google_oAuth.js'
const gauthOption = {
    clientId: '676418604540-bnt82iftbgdk1t52ptbhtlq3aordloqn.apps.googleusercontent.com',
    // clientId: '481909600784-35t6sc0mbrik8vg6g0nqrog0tk6le53v.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
}

const app = createApp(App)

app.use(router)
app.use(store)
app.use(VueClipboard)


// 添加 Antd
app.use(Antd)

app.use(i18n)

app.use(GoogleAuth, gauthOption)

// 配置项
app.use(lazyPlugin, {
    preLoad: 1.3,
    loading: require('@/assets/empty.png'),
    error: require('@/assets/empty.png'),
    attempt: 1
})

app.mount('#app')
