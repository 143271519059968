<template>
  <div >
    <a-spin size="large" :spinning="spinning">
  <div
    class="home-box-my"
    @scroll="scrollEvent"
  >
    <Header
      @targetSide="targetSide"
      :tabs="tabs"
      :selectTab="selectTab"
      @changeTab="changeTab"
      :showSearch="showSearch"
      :value="search_value"
      @keydownEnter="keydownEnter"
      @changeSearchEvent="changeSearchEvent"
    />

    <div class="create-center">
      <div class="crete-center-box">
        <div class="cretate-select-info">
          <div class="create-left-info" :class="showSelect ? 'active':''" @click="showSelecthandle">
            {{ data.treeName ? $t('create.postToAfterSelect') : $t('create.postTo') }}
            <span v-if="data.treeName" style="color: #202124">{{ data.treeName }}</span>
            <img :src="require('@/assets/icon-right.svg')" alt="">
          </div>
          <div v-if="!data.treeName" class="create-right-info">
            <div class="slect-box-center">
              <div class="select-item-box">
                <div class="select-item" v-for="(item,index) in entryList.slice(0, 3)" :key="index" @click="selectdefaultEntry(item.title, item.id)">
                  {{ item.title }}
                </div>
              </div>
              <div class="select-text">{{$t('create.chooseTargetToLetMorePeopleKnow')}}</div>
            </div>
          </div>
          <img class="comment-cross" src="../assets/comment-cross.png" height="23" width="23" v-if="data.treeName" @click="close"/>
          <div class="create-modal-box" v-if="showSelect" @click.self="clickSearchExcept">
            <div class="search-item">
              <img class="search-icon" :src="require('@/assets/icon-search.svg')" alt="">
              <input id="keyword" v-model="value" class="search-input" :placeholder="$t('create.search')" @input="changeSearchVal($event)"/>

            </div>
            <div style="height: 400px;overflow:auto;" v-if="!showSearchList">
              <div class="title">{{$t('create.history')}}</div>
              <!--最近参与-->
              <div class="item" v-for="item in (isAllHistory ? allSearchHistories : searchHistories)" @click="selectEntry({id: item.entryId, title: item.entryName}, false)">
                <div class="item-left">
                  <div class="item-title">{{ item.entryName }}</div>
                </div>
                <!--<div class="item-right" @click="selectEntry({id: item.entryId, title: item.entryName}, false)">选择</div>-->
              </div>
              <div
                class="more-list"
                v-if="allSearchHistories.length > 3"
                @click="isAllHistory = !isAllHistory"
              >
                {{$t('create.historyAll')}}
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                >
                  <g
                    fill="currentColor"
                    transform="translate(267.814 69.919) rotate(180)"
                  >
                    <path
                      data-name="路径 524"
                      class="cls-1"
                      d="M260.542,63.619a.742.742,0,0,1-.515-.2.681.681,0,0,1,0-.99l6.545-6.3a.746.746,0,0,1,1.028,0,.681.681,0,0,1,0,.99l-6.545,6.3a.737.737,0,0,1-.513.2Zm0,0"
                      transform="translate(0 0)"
                    />
                    <path
                      data-name="路径 525"
                      class="cls-1"
                      d="M267.084,474.124a.742.742,0,0,1-.515-.2l-6.541-6.3a.681.681,0,0,1,0-.99.746.746,0,0,1,1.028,0l6.543,6.3a.681.681,0,0,1,0,.99A.738.738,0,0,1,267.084,474.124Zm0,0"
                      transform="translate(0 -404.205)"
                    />
                  </g>
                </svg>
              </div>

              <div class="title">{{$t('create.relatedSearches')}}</div>
              <!--猜你想发-->
              <div class="item item-text" v-for="item in hotSearch" @click="selectHotSearch(item)">
                <div class="item-left">
                  <div class="item-title">{{ item.entryName }}</div>
                  <!--                                    <div class="item-text">什么什么地方</div>-->
                </div>
                <!--<div class="item-right" @click="selectHotSearch(item.entryName)">选择</div>-->
              </div>
            </div>
            <div style="height: 400px;overflow:auto;" v-if="showSearchList">
              <!--选择条目-->
              <div class="item" v-for="item in searchList" @click="selectEntry(item)">
                <div class="item-left">
                  <div class="item-title">{{ item.title }}</div>
                </div>
                <!--<div class="item-right" @click="selectEntry(item)">选择</div>-->
              </div>
            </div>
              <div class="create-entries" v-if="createEntries">
                  <button @click="addEntries">{{$t('create.createEntry')}}</button>
              </div>
          </div>
        </div>
        <div class="create-box">
          <div class="info-box">
            <input
              class="info-title"
              type="text"
              :placeholder="$t('create.title')"
              maxlength="15"
              v-model="data.imgNote"
            >
            <span>({{data.imgNote?data.imgNote.toString().length:0}}/15)</span>
          </div>
          <div class="info-text">
            <textarea
              :placeholder="$t('create.description')"
              maxlength="500"
              v-model="data.imgComment"
            ></textarea>
            <span>({{data.imgComment?data.imgComment.toString().length:0}}/500)</span>
          </div>
          <div class="box-title">
            {{$t('create.addPictures')}}
          </div>
          <div class="img-box">
            <div
              class="img-list"
              v-for="(item, i) in data.files"
            >
              <img
                class="view-img"
                :src="getFileUrl(item)"
                alt=""
              >
              <img
                class="del-img"
                :src="require('@/assets/icon-close.png')"
                alt=""
                @click="delFile(i)"
              >
            </div>
            <label class="img-list img-list-add" v-show="data.files.length < 100">
              <img
                class="add-img"
                :src="require('@/assets/add-img.svg')"
                alt=""
              >
              <input
                type="file"
                @change="getFile($event)"
                multiple
                accept="image/gif, image/jpeg, image/png, image/jpg"
              >
              <!-- accept="image/*" -->
            </label>
            <span class="info-img">({{data.files ?data.files.length : 0}}/100)</span>
          </div>
        </div>
        <div class="create-date">
          <div class="create-date-left">{{$t('create.customShootingTime')}}</div>
          <div class="create-date-right">
            <a-date-picker
              v-model="time"
              format="YYYY-MM-DD HH:mm"
              :placeholder="$t('create.selectTime')"
              @change="onChange"
            >
              <span>{{ time ? time : $t('create.selectTime') }}</span>
            </a-date-picker>
          </div>
        </div>

      </div>
    </div>

    <div class="create-footer">
      <div
        class="create-footer-center"
        @click="uploadImg"
      >
        <button>{{$t('create.post')}}</button>
      </div>
    </div>
  </div>
  <SideNav :showSide="showSide" />

  <Login
    :visible="visible"
    :closeModal="closeModal"
  />
    </a-spin>
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import SideNav from '@/components/SideNav.vue'
import Footer from '@/components/Footer.vue'
import ListItem from '@/components/ListItem.vue'
import ListItemSimple from '@/components/ListItemSimple.vue'
import $ from 'jquery';
import moment from "moment";
import Api from "../Api";
import Login from "./Login";

let searchTimer = null

export default {
  name: 'Index',
  components: {
    Header,
    SideNav,
    Footer,
    Login,
    ListItem,
    ListItemSimple
  },
  data () {
    return {
        spinning: false,
        createEntries: false,
      entryList: [],
      visible: false,
      showSelect: false,
      showSearchList: false,
      select: '',
      time: undefined,
      pic_types: ["gif", "png", "jpg", "bmp", "ico", "jpeg"],
      initData: {
        treeId: this.$route.query.entryId,
        treeName: this.$route.query.entryName,
        imgTime: undefined,
        imgNote: undefined,
        imgComment: undefined,
        tagId: undefined,
        files: []
      },
      data: {
        treeId: this.$route.query.entryId,
        treeName: this.$route.query.entryName,
        imgTime: undefined,
        imgNote: undefined,
        imgComment: undefined,
        tagId: undefined,
        files: []
      },
      searchList: [],
      allSearchHistories: [],
      searchHistories: [],
      hotSearch: [],
      isAllHistory: false,
    }
  },
    created() {
        document.addEventListener("click", e => {
            let searchBox = document.querySelector('.create-modal-box')
            let nameTab = document.querySelector('.cretate-select-info')
            // 搜索框出现
            if (searchBox) {
                // 判断是否选中猜你想发
                if (this.showSearchList) {
                    // 选中猜你想发
                    // 判断选中的条目是否存在
                    if (this.createEntries) {
                        // 选中的条目存在
                        if (searchBox.contains(e.target) || nameTab.contains(e.target) ) {
                        } else {
                            this.showSelect = false;
                        }
                    } else {
                        // 选中的条目不存在（!document.querySelector('.item')——没有'.item'div条目选择时,点击猜你想发 搜索框不消失）
                        if (searchBox.contains(e.target) || nameTab.contains(e.target) || !document.querySelector('.item')) {
                        } else {
                            this.showSelect = false;
                        }
                    }
                } else {
                    // 没选中猜你想发
                    if (searchBox.contains(e.target) || nameTab.contains(e.target)) {
                    } else {
                        this.showSelect = false;
                    }
                }
            }
        });
        let date = new Date()
        let y = date.getFullYear()
        let M = date.getMonth() < 9 ? '0' + (date.getMonth() + 1):date.getMonth() + 1
        let d= date.getDate() < 9 ? '0' + date.getDate():date.getDate()
        let h= date.getHours() < 9 ? '0' + date.getHours():date.getHours()
        let m= date.getMinutes() < 9 ? '0' + date.getMinutes():date.getMinutes()
        this.time = y + '-' + M + '-' + d + ' ' + h + ':' + m
    },
  mounted () {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language','ja')
    }
    this.$i18n.locale=localStorage.getItem('language');
    this.getSearchHistory();
    this.findHotSearch();
  },
  methods: {
      addEntries(){
          if (this.checkLogin()) {
              return;
          }
          this.data.treeId = undefined
          // this.data.treeId = null
          this.data.treeName = this.value.trim()
          // this.data = {
          //     // treeId: null,
          //     treeId: this.$route.query.entryId,
          //     treeName: this.value.trim(),
          //     imgTime: undefined,
          //     imgNote: undefined,
          //     imgComment: undefined,
          //     tagId: undefined,
          //     files: []
          // }
          this.showSelect = false;
      },
      close() {
          this.value = null
          this.showSearchList = false
          this.createEntries = false
          this.data.treeId = undefined
          this.data.treeName = undefined
      },
    selectdefaultEntry (entryTitle, entryId) {
      this.data.treeId = entryId;
      this.data.treeName = entryTitle;
    },
    selectEntry (item, isSaveHistory = true) {
        this.data.treeId = item.id;
      this.data.treeName = item.title;
      this.showSelect = false;
      if (isSaveHistory) {
        this.saveSearchHistory(this.data.treeId, this.data.treeName);
      }
    },
    selectHotSearch (val, isSaveHistory = true) {
        this.data.treeId = val.entryId;
        this.data.treeName = val.entryName;
        let el = document.getElementById("keyword")
      el.value = val.entryName;
      el.dispatchEvent(new Event('input'));
        this.showSelect = false;
        if (isSaveHistory) {
            this.saveSearchHistory(this.data.treeId, this.data.treeName);
        }
    },
    findHotSearch () {
      Api.findHotSearch().then((res) => {
        if (res.error_code == 0) {
          this.hotSearch = res.result.data;
        }
      });
    },

    getSearchHistory () {
      // 获取首页的前三条条目
      this.entryList = localStorage.getItem('selectItemList') && JSON.parse(localStorage.getItem('selectItemList')) || [];
      console.log('this.entryList', this.entryList.slice(0, 3))
      let histories = localStorage.getItem("searchHistories") && JSON.parse(localStorage.getItem("searchHistories")) || [];
      this.allSearchHistories = histories;
      this.searchHistories = histories.slice(0, 3);
      return histories;
    },

    /**
     * 保存搜索记录
     */
    saveSearchHistory (entryId, entryName) {
      let histories = localStorage.getItem("searchHistories") && JSON.parse(localStorage.getItem("searchHistories")) || [];
      let i = 0;
      for (; i < histories.length; i++) {
        if (histories[i].entryId == entryId) {
          break;
        }
      }
      if (i >= histories.length) {
        histories.unshift({ entryId: entryId, entryName: entryName });
        localStorage.setItem("searchHistories", JSON.stringify(histories));
      }
    },

    changeSearchVal (e) {
      console.log(e.target.value);
      this.showSearchList = e.target.value ? true : false
        this.createEntries = false
        this.setSearchList(e);
    },

    setSearchList (e) {
      if (this.value && this.value.trim() != '') {
        clearTimeout(searchTimer)
        searchTimer = setTimeout(() => {
          Api.getSearch({
            keywords: this.value
          }).then((res) => {
            let data = res.result.data;
            if (data.length > 0) {
              this.searchList = data
             let newList = data.map(element => {return element.title;});
              for (let index = 0; index < newList.length; index++) {
                const elementItem = newList[index];
                if(elementItem === e.target.value){
                  return this.createEntries = false;
                }else{
                  return this.createEntries = true;
                }
              }
            } else {
                this.createEntries = true
            }
          })
        }, 200)

      } else {
        this.searchList = []
      }

    },

    getFileUrl (file) {
      let url;
      url = window.URL.createObjectURL(file);
      return url;
    },
    showSelecthandle () {
      this.showSelect = !this.showSelect
    },
    onChange (value, dateString) {
      this.time = dateString
    },
    delFile (index) {
      this.data.files.splice(index, 1);
    },
    getFile (event) {
      var file = event.target.files;
      for (var i = 0; i < file.length; i++) {
        //    上传类型判断
        var imgName = file[i].name;
        var idx = imgName.lastIndexOf(".");
        if (idx != -1) {
          var ext = imgName.substr(idx + 1).toUpperCase();
          ext = ext.toLowerCase();
          if (this.pic_types.indexOf(ext) > -1) {
            this.data.files.push(file[i]);
          }
        } else {

        }
      }
    },
    uploadImg () {
      if (this.checkLogin()) {
        return;
      }
      this.data.imgTime = (this.time ? moment(this.time) : moment()).format("YYYYMMDDHHmm");
      if (this.data.treeId === undefined && this.data.treeName === undefined) {
        this.$message.error(this.$t('create.prompt'));
      }
      let _that = this;
      if (this.data.treeId === undefined && this.data.treeName !== undefined) {
          let params = {
              title:this.data.treeName
          }
          // 创建条目
          this.spinning = true
          Api.createAddEntry(params).then((data) => {
              if (data.error_code == 0) {
                  this.data.treeId = data.result.data.id
                  Api.uploadImg(this.data).then((res) => {
                      console.log("upload ==>", res);
                      if (res.error_code == 0) {
                          const treeId = _that.data.treeId;
                          this.data = { ...this.initData };
                          this.spinning = false
                          this.$confirm({
                              // 询问是或否确定删除
                              content: this.$t('create.success'),
                              okText: this.$t('create.viewPost'),
                              okType: 'danger',
                              cancelText: this.$t('create.postNext'),
                              onOk () {
                                  _that.$router.push(`/${treeId}`)
                              },
                              oncancel () {
                              }
                          });
                      }
                  }).catch((err) => {
                      if (err.response.status == 401 || err.response.status == 400) {
                          this.$message.error(this.$t("tokenErrMessage"));
                        this.checkLogin();
                          this.visible = true
                      }
                      this.spinning = false
                      console.log("err ==>", err);
                  });
              }
          })
      } else if (this.data.treeId !== undefined && this.data.treeName !== undefined) {
          this.spinning = true
          Api.uploadImg(this.data).then((res) => {
              console.log("upload ==>", res);
              if (res.error_code == 0) {
                  const treeId = _that.data.treeId;
                  this.data = { ...this.initData };
                  this.spinning = false
                  this.$confirm({
                      // 询问是或否确定删除
                      content: this.$t('create.success'),
                      okText: this.$t('create.viewPost'),
                      okType: 'danger',
                      cancelText: this.$t('create.postNext'),
                      onOk () {
                          _that.$router.push(`/${treeId}`)
                      },
                      oncancel () {
                      }
                  });
              }
          }).catch((err) => {
              if (err.response.status == 401 || err.response.status == 400) {
                  this.$message.error(this.$t("tokenErrMessage"));
                this.checkLogin();
                  this.visible = true
              }
              this.spinning = false
              console.log("err ==>", err);
          });
      }
    },

    checkLogin () {
      this.visible = !localStorage.getItem('token');
      return this.visible;
    },

    closeModal () {
      this.visible = false;
    },
  }
}

</script>
<style lang="scss">
body {
  background: #f8f8f8 !important;
}
.create-center {
  height: calc(100vh - 80px - 67px - 20px);
  margin-top: 100px;
  overflow-y: auto;

  .crete-center-box {
    width: 840px;
    margin: 0 auto;

    padding: 20px 0px;

    .cretate-select-info {
      padding: 13px 20px;
      display: flex;
      align-items: center;
      background: #ffffff;
      border-radius: 8px;
      position: relative;
      height: 70px;

      .create-left-info {
        height: 24px;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        color: #0870d1;
        padding-right: 16px;
        cursor: pointer;

        > img {
          margin-left: 10px;
          width: 8px;
          vertical-align: middle;
          margin-top: -2px;
        }

        &.active {
          > img {
            transform: rotate(90deg);
          }
        }
      }

      .create-right-info {
        border-left: 1px solid #eeeeee;
        padding-left: 10px;
        border-radius: 8px;

        .slect-box-center {
          .select-item-box {
            overflow: hidden;

            .select-item {
              float: left;
              padding: 4px 10px;
              font-size: 14px;
              font-weight: 400;
              line-height: 1;
              // color: #999999;
              color: rgb(238, 238, 238);
              // background: #eff3f6;
              background-color: rgb(8, 112, 209);
              border-radius: 25px;
              margin-right: 10px;
              cursor: pointer;
            }
          }

          .select-text {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            color: #cccccc;
            margin-top: 7px;
          }
        }
      }
      .comment-cross{
        position: absolute;
        right: 15px;
        z-index: 5555;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .create-modal-box {
        position: absolute;
        top: 91px;
        left: 0;
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 8px;
        padding: 20px;
        z-index: 3;

        .search-item {
          width: 100%;
          /*width: 840px;*/
          height: 58px;
          background: #f8f8f8;
          border-radius: 25px;
          padding: 0 21px;
          display: flex;
          align-items: center;
          z-index: 1;
          position: relative;

          .search-icon {
            width: 25px;
            height: 25px;
          }

          .search-input {
            flex: 1;
            height: 25px;
            margin-left: 21px;
            font-size: 18px;
            font-weight: 400;
            border: none;
            outline: none;
            background: none;
          }

          .search-list {
            width: 100%;
            height: 380px;
            background: #fff;
            position: absolute;
            top: 50px;
            left: 0;
            padding-bottom: 16px;

            .search-list-item {
              font-size: 14px;
              font-weight: bold;
              color: #666666;
              line-height: 1;
              padding: 0 20px;
              margin-top: 16px;
              cursor: pointer;

              &.active {
                color: #0870d1;
              }
            }
          }
        }
          .create-entries{
              text-align: center;
              > button {
                  width: 112px;
                  height: 36px;
                  line-height: 34px;
                  background: #0870d1;
                  border-radius: 50px;
                  border: none;
                  outline: none;
                  color: #fff;
                  font-size: 16px;
                  cursor: pointer;
              }
          }

        .title {
          font-size: 18px;
          font-weight: bold;
          line-height: 24px;
          color: #333333;
          margin-top: 28px;
          padding-left: 15px;
          margin-bottom: 11px;
        }

        .item {
          padding: 0 15px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #eee;
          height: 54px;
          cursor: pointer;

          .item-left {
            flex: 1;

            .item-title {
              font-size: 16px;
              font-weight: 400;
              line-height: 21px;
              color: #333333;
            }

            .item-text {
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
              color: #cccccc;
              margin-top: 5px;
            }
          }

          .item-right {
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            color: #0870d1;
          }

          &.item-text {
            height: 64px;
          }
        }

        .more-list {
          text-align: center;
          height: 54px;
          font-size: 14px;
          font-weight: 500;
          line-height: 54px;
          color: #999999;
          cursor: pointer;

          svg {
            transform: rotate(90deg);
            vertical-align: middle;
            width: 6px;
            margin-left: 8px;
          }
        }
      }
    }

    .create-box {
      background: #fff;
      margin-top: 20px;
      padding: 42px 20px;

      .info-box {
        padding-bottom: 20px;
        line-height: 1;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        align-items: center;

        .info-title {
          flex: 1;
          font-size: 24px;
          font-weight: bold;
          line-height: 31px;
          color: #666666;
          border: none;
          outline: none;
        }

        .span {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          color: #cccccc;
        }
      }

      .info-text {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #666666;
        position: relative;
        padding-bottom: 43px;
        border-bottom: 1px solid #eeeeee;
        height: 163px;

        > textarea {
          width: 100%;
          height: 100%;
          padding: 0;
          outline: none;
          border: none;
          resize: none;
        }

        > span {
          position: absolute;
          bottom: 12px;
          right: 0;
          font-size: 14px;
          line-height: 1;
        }
      }

      .box-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #666666;
        margin-top: 20px;
        margin-bottom: 40px;
      }

      .img-box {
        width: 100%;
        overflow: hidden;
        position: relative;
        .info-img{
          position: absolute;
          bottom: 12px;
          right: 0;
          font-size: 14px;
          line-height: 1;
          }
        .img-list {
          float: left;
          width: 108px;
          height: 108px;
          line-height: 108px;
          margin-right: 20px;
          margin-bottom: 20px;
          text-align: center;
          border: 1px solid #eee;
          position: relative;

          .view-img {
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
            object-fit: cover;
          }

          .del-img {
            position: absolute;
            top: 6px;
            right: 6px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.2);
            cursor: pointer;
            padding: 2px;
          }

          &.img-list-add {
            cursor: pointer;

            input {
              width: 2px;
              height: 2px;
              z-index: 0;
              position: absolute;
              visibility: hidden;
            }
          }
        }
      }
    }

    .create-date {
      display: flex;
      background: #fff;
      margin-top: 20px;
      padding: 26px 20px;

      .create-date-left {
        flex: 1;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #333333;
      }

      .create-date-right {
        text-align: right;
        span{
          cursor:pointer;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #0870D1;
          opacity: 1;
        }
      }
    }
  }
}

.create-footer {
  height: 67px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: -3px 0px 6px rgba(0, 0, 0, 0.1);

  .create-footer-center {
    line-height: 67px;
    width: 840px;
    margin: 0 auto;
    text-align: right;

    > button {
      width: 112px;
      height: 36px;
      line-height: 34px;
      background: #0870d1;
      border: none;
      outline: none;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>
