<template>
    <title>Look / プライバシーポリシー</title>
        <body>
        <article class="clearfix">
            <section>
                    <Header
                        :tabs="tabs"
                        :selectTab="selectTab"
                        :showSearch="true"
                        @targetSide="targetSide"
                        @changeTab="changeTab"
                        :value="keywords"
                        @keydownEnter="keydownEnter"
                        @changeSearchEvent="changeSearchEvent"
                        :searchBox="handlstyle"
                    />
                <div class="part_one">
                    <div class="left">
                        <img src="../assets/decorate@2x.png" style="position: absolute;width: 60%;" alt="">
                    </div>
                    <div class="right">
                        <div class="right_text">
                         <span>为了你的数据安全</span>
                        <br>
                        <span>是否要删除你的用户数据？</span>
                        </div>
                        <div class="button">
                            <button id="no">否</button>
                            <button id="yes" @click="userDelete()">是</button>
                        </div>

                    </div>
                </div>
            </section>
        </article>
        <Footer/>
    </body>
    </template>
    <script>
    import Footer from '@/components/Footer.vue'
    import Header from '@/components/Header.vue'
    import Api from "../Api";
    import storage from "storejs";
    let searchTimer = undefined;

    export default {
        components:{
            Footer,
            Header,
        },
        data() {
            return{
            showSide:false,
			keywords: '', //搜索的绑定值
            showSearchList: false, //显示搜索列表
            searchList: [], //搜索列表
            activeSearch: '', //搜索列表选中
			selectTab: undefined,
			tabs: [],
        	handlstyle:{
				width:'440px',
				padding:'200px'
			}
            }
        },
        methods:{
            userDelete(){
                let userInfo = storage.get('userInfo') || '';
                console.log(userInfo)
                if(userInfo){
                    let params = {
                        userid:userInfo.userid
                    }
                    console.log(params)
                    Api.deleteUser(params).then((res) =>{
                        if(res.error_code == 0){
                            console.log("成功")
                        }
                    }).catch(err =>{
                        if (err.message.indexOf('code 400') > -1) {
                            this.$message.error(this.$t("tokenErrMessage"));
                            this.visible = true
                             }
                    })
                }
            },


        }
    }
    </script>
    <style lang="scss">
        .part_one{
            padding-left: 5%;
            padding-right: 5% ;
            padding-top: 10%;
            margin-top:52px;
            height: 1000px;
            width: 100%;
            background-color: #fbfdff;
            display: flex;
            // flex-direction: column;
            flex-direction: row;

            .left{
                width: 60%;
                display: flex;
                flex-grow: 2;
            }
            .right{
                width: 40%;
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                flex-wrap: nowrap;
                align-content: center;
                align-items: stretch;
                .right_text{
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 140px;
                }
                .button{
                    display:flex ;
                    flex-flow: column;
                    flex-direction: row;
                    align-content: center;
                    justify-content: center;
                    align-items: stretch;
                }
                #yes{
                    background-color: #0870d1;
                    // border-color: #0870d1;
                    // border-style: 2px solid #0870d1;
                    font-family:"Microsoft YaHei";
                    color: #ffffff;
                    border: #0870d1;
                    // border: none;
                }
                #no{
                    background-color: #ffffff;
                    // border-color: #999999;
                    border-color: #cccccc;
                    font-family:"Microsoft YaHei";
                    margin-right: 30px;
                    color: #999999;
                }
            }
            button{
                height: 48px;
                width: 220px;
                border-radius: 4px;
                font-size: 16px;
                outline: none;
                text-align: center;

                // color: #0870d1;

            }
            span{
                font-size: 28px;
                font-family:"Microsoft YaHei";
                color: #3c4043;

            }
        }
    </style>
