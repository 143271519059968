<template>
    <transition name="dialog">
        <div
                v-if="visibleSecond"
                id="dialog-bg"
                @touchmove.prevent
                @scroll.prevent
        @click="close">
            <div id="dialog" @click.stop="">
                <img class="comment-cross" src="../assets/comment-cross.png" height="23" width="23" @click="close"/>
                <div id="textareaDiv" class="comment-text-div">
                    <textarea id="textarea" class="comment-text" maxlength="500" :placeholder="$t('mdialog.saySomething')" v-model="content"
                              v-on:keyup="textChange"></textarea>
                    <div class="btn-emoticons">
                        <!-- todo 表情包-->
                        <!--<a-dropdown placement="bottomLeft" :overlay-style="{minWidth:'500px', width: '38%'}">-->
                            <!--<div class="icon-emoticons">-->
                                <!--<img class="img-emoticons" width="27" height="27" :src="require('@/assets/emotion.png')">-->
                                <!--<span>{{$t('expression')}}</span>-->
                            <!--</div>-->
                            <!--<template #overlay>-->
                                <!--<a-menu>-->
                                    <!--<emotion @emotion="handleEmotion" :height="110" style="border: none"></emotion>-->
                                <!--</a-menu>-->
                            <!--</template>-->
                        <!--</a-dropdown>-->
                    </div>
                    <div class="btn-box">
                        <button :style="'background-color:'+(isActive===true?'#0870d1': '#EEEEEE')+';color:'+(isActive===true?'#EEEEEE': '#cccccc')"
                                @click="addComment(type=0, dialogFlog='dialogFlog')">
                            {{$t('detail.suggest')}}
                        </button>
                        <button :style="'background-color:'+(isActive===true?'#1673CE': '#EEEEEE')+';color:'+(isActive===true?'#EEEEEE': '#cccccc')"
                                @click="addComment(type=1, dialogFlog='dialogFlog')">
                            {{$t('detail.question')}}
                        </button>
                        <button :style="'background-color:'+(isActive===true?'#1673CE': '#EEEEEE')+';color:'+(isActive===true?'#EEEEEE': '#cccccc')"
                                @click="addComment(type=2, dialogFlog='dialogFlog')">
                            {{$t('detail.comment')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import elementResizeDetectorMaker from "element-resize-detector";
    import {autoTextarea,autoTextareaDivChange} from '@/utils/common';
    import Emotion from '@/components/Emotion/index'
    export default {
        components: {
            Emotion
        },
        props: {
            visibleSecond: Boolean,
            type: Number,
            dialogFlog: String
        },
        data() {
            return {
                isActive: false,
                content: '',
            }
        },
        emits: ['addComment', 'handleCancelSecond'],
        methods: {
            // 表情包
            handleEmotion (i) {
                this.isActive = true
                this.content += i
            },
            close: function () {
                this.isActive = false
                this.content = ''
                this.$emit("closeDialog");
            },
            addComment: function () {
                this.$emit('addComment', this.type, this.content, this.dialogFlog);
                this.$nextTick(() => {
                    this.content = ''
                });
            },
            textChange() {
                const _this = this;
                const erd = elementResizeDetectorMaker();
                erd.listenTo(document.getElementById("textareaDiv"), element => {
                    _this.$nextTick(() => {
                        autoTextareaDivChange(document.getElementById("textarea"))
                    });
                });
                autoTextarea(document.getElementById("textarea"))
                if (this.content) {
                    this.isActive = true
                } else {
                    this.isActive = false
                }
            },
        },
        watch: {
            // visibleSecond: function(newVal, oldVal) {
            //     // TO DO
            //     console.log("newVal:", newVal);
            //     console.log("oldVal:", oldVal);
            // }
        }

    }
</script>

<style lang="scss" scoped>
    * {
        box-sizing: border-box;
    }

    #dialog-bg {
        top: 0;
        left: 0;
        position: fixed;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 98;
    }

    #dialog {
        border-radius: 20px 20px 0 0;
        position: fixed;
        bottom: 0;
        //background-color: #FFFFFF;
        width: 100vw;
        padding: 15px;
        padding-bottom: 25px;
        .comment-cross {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 5555;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

    }

    /* 适配电脑等大屏幕 */
    @media (min-width: 100px) {
        #dialog {
            width: 100%;
            max-width: 840px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }

        .comment-text-div {
            padding: 18px 20px;
            height: auto;
            background: #F9F9F9;
            border-radius: 8px;
            overflow: hidden;
            position: relative;

            .comment-text {
                width: 100%;
                height: 100%;
                resize: none;
                border: none;
                font-size: 14px;
                line-height: 19px;
                background: none;
                outline: none;
            }
            .btn-emoticons{
                /*position: absolute;*/
                float: left;
                left: 20px;
                bottom: 20px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                .icon-emoticons{
                    display: flex;
                    align-items: center;
                    span{
                        margin-left: 6px;
                        font-size: 15px;
                    }
                }
            }
            .btn-box {
                /*position: absolute;*/
                float: right;
                bottom: 20px;
                right: 20px;

                > button {
                    width: 72px;
                    height: 32px;
                    background: #EEEEEE;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 34px;
                    color: #CCCCCC;
                    border: none;
                    outline: none;
                    margin-left: 15px;
                    cursor: pointer;

                }
            }
        }
    }

    .dialog-button {
        width: 100%;
        background-color: #6367D5;
        border-width: 0;
        border-radius: 360px;
        padding: 10px;
        outline: none;
        color: white;
    }

    .dialog-button:focus {
        outline: none;
    }

    .dialog-button:active {
        background-color: #585dbe;
        border-color: #585dbe;
    }

    .dialog-enter-active, .dialog-leave-active {
        transition: all .5s;
    }

    .dialog-enter, .dialog-leave-to {
        opacity: 0;
        transform: translateY(300px);
    }
    textarea::-webkit-input-placeholder {
      /* WebKit browsers */
      color: #cccccc;
    }
</style>
