<template>
<title>Look / プライバシーポリシー</title>
    <body>
	<article class="clearfix">
		<section>
				<Header
					:tabs="tabs"
					:selectTab="selectTab"
					:showSearch="true"
					@targetSide="targetSide"
					@changeTab="changeTab"
					:value="keywords"
					@keydownEnter="keydownEnter"
					@changeSearchEvent="changeSearchEvent"
					:searchBox="handlstyle"
				/>
			<div class="onerow">
				<div class="under_Layer">
                    <div style="height:48px">
					<h1 class="p_ttl">プライバシーポリシー</h1>
                    </div>
					<p class="txt_s">「Look」（以下「本サービス」といいます。）を提供する当社は、本サービスを通じて、人々の生活が豊かになることを目指した事業を展開します。お客様から個人情報をお預かりするにあたり、役職員が個人情報保護に関する法令、国が定める指針およびその他の規範を厳守し、個人情報の管理に細心の注意を払うため、このプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。</p>

					<div class="txt_m bold">1. 個人情報の定義</div>
					<p class="txt_s mb_70">「個人情報」とは、個人に関する情報であり、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）を指します。</p>

					<div class="txt_m bold">2. 個人情報を保護するための体制について</div>
					<p class="txt_s mb_70">当社は、個人情報の漏洩、滅失またはき損の防止その他の個人情報の安全管理が図られるよう、個人情報保護規程を定め、従業員に対する必要かつ適切な監督を行います。</p>

					<div class="txt_m bold">3. 個人情報の第三者への提供</div>
					<p class="txt_s">当社は、以下のいずれかに該当する場合を除き、個人情報を第三者に提供することはございません。また、外国にある第三者に対して個人情報を提供する場合についても同様となります。</p>

					<p class="txt_s_mbfix mt-20">・ご本人の同意がある場合</p>
					<p class="txt_s_mbfix">・人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難であるとき</p>
					<p class="txt_s_mbfix">・公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の同意を得ることが困難であるとき</p>
					<p class="txt_s_mbfix">・国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</p>
					<p class="txt_s_mbfix">・第三者に対し、当社の運営に必要な業務の一部もしくは一切を委託する場合またはビジネスの移管を行う場合</p>
					<p class="txt_s_mbfix mb_70">・その他法令に基づく場合</p>

					<div class="txt_m bold">4. 個人情報の利用目的</div>
					<p class="txt_s">当社は、「2. 個人情報を保護するための体制について」で定める適切な処置を行った上で、個人情報を以下の目的で利用できるものとします。</p>
					<p class="txt_s">（ 1 ） 本サービスをご利用いただく皆様に関する個人情報</p>

					<p class="txt_s_mbfix mt-20">・本サービスのご提供およびご案内のため</p>
					<p class="txt_s_mbfix">・本人確認、認証サービスのため</p>
					<p class="txt_s_mbfix">・本サービスおよび広告配信をカスタマイズするため</p>
					<p class="txt_s_mbfix">・アフターサービス、問い合わせ、苦情対応のため</p>
					<p class="txt_s_mbfix">・アンケート実施のため</p>
					<p class="txt_s_mbfix">・キャンペーン等のプレゼント発送のため</p>
					<p class="txt_s_mbfix">・サービス改善や新サービスの開発等に役立てるため</p>
					<p class="txt_s_mbfix">・利用規約に違反する態様でのご利用を防止するため</p>
					<p class="txt_s_mbfix">・マーケティングデータの調査、統計、分析のため</p>
					<p class="txt_s_mbfix">・システムの維持、不具合対応のため</p>
					<p class="txt_s_mbfix">・新サービスの告知、サービス運営上の事務連絡のため</p>
					<p class="txt_s_mbfix mb_36">・その他当社の各サービスにおいて個別に定める目的のため</p>

					<p class="txt_s">（ 2 ） お取引先の皆様に関する情報</p>
					<p class="txt_s_mbfix mt-20">・業務上必要なご連絡、契約の履行、商談等のため</p>
					<p class="txt_s_mbfix mb_36">・取引先情報の管理のため</p>

					<p class="txt_s">（ 3 ） お問い合わせをいただく皆様に関する個人情報</p>
					<p class="txt_s_mbfix mt-20">・取引先情報の管理のため</p>
					<p class="txt_s_mbfix mb_36">・お問い合わせいただいた内容への対応のため</p>

					<p class="txt_s">（ 4 ） セミナー、イベント等にご参加いただく皆様に関する個人情報</p>
					<p class="txt_s_mbfix mt-20">・セミナー、イベント等の運営管理のため</p>
					<p class="txt_s_mbfix mb_36">・セミナー、イベント等に関するご連絡、資料の発送のため</p>

					<p class="txt_s">（ 5 ） 当社の採用選考にご応募いただく皆様に関する個人情報</p>
					<p class="txt_s_mbfix mt-20">・採用応募者への情報提供、ご連絡、通知のため</p>
					<p class="txt_s_mbfix mb_36">・採用選考の合否判定のため</p>

					<p class="txt_s">（ 6 ） 委託を受けた個人情報</p>
					<p class="txt_s_mbfix mt-20 mb_36">・委託業務遂行のため</p>

					<p class="txt_s mb_70">上記以外の個人情報の利用目的については、お問い合わせ先まで個別にお問い合わせください</p>

					<div class="txt_m bold">5. 個人情報の委託</div>
					<p class="txt_s mb_70">当社は、利用目的の達成に必要な範囲内において、取得した個人情報の取り扱いの全部または一部を委託する場合があります。
						その場合には、個人情報の委託に係わる基本契約等の必要な契約を締結し、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。</p>

					<div class="txt_m bold">6. 個人情報の訂正等</div>
					<p class="txt_s mb_70">当社は、ご本人から、個人情報が真実でないという理由によって、個人情報保護法の定めに基づき、当該個人情報の内容の訂正、追加または削除（以下「訂正等」といいます。）を求められた場合には、他の法令の規定により特別の手続きが定められている場合を除き、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨ご本人に通知します。</p>

					<div class="txt_m bold">7. 個人情報の利用停止等</div>
					<p class="txt_s mb_70">当社は、ご本人から、ご本人の個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由、または偽りその他不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨ご本人に通知します。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ご本人の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。</p>

					<div class="txt_m bold">8. 個人情報の開示等の手続き</div>
					<p class="txt_s mb_70">当社は、ご本人より当該本人の個人情報の利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者提供の停止のお申し出に対して、遅滞なく応じます。ただし、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（１件あたり１，０００円）を頂戴しておりますので、あらかじめご了承ください。</p>

					<div class="txt_m bold">9. 免責</div>
					<p class="txt_s">以下の場合は、当社は何ら責任を負いません。</p>

					<p class="txt_s_mbfix mt-20">・ご本人が本サービスの機能または別の手段を用いて第三者に個人情報を明らかにした場合</p>
					<p class="txt_s_mbfix mb_70">・ご本人が本サービス上にて開示した情報等により、個人を識別可能な状態となった場合</p>

					<div class="txt_m bold">10. 統計データの利用</div>
					<p class="txt_s">当社は、取得した個人情報をもとに、個人を識別できないように加工した統計データを作成することがあります。 当該統計データについては、当社は何ら制限なく利用することができるものとします。
						当社は、提供しているサービスや広告の内容をよりご本人に適したものとするために、以下を例とする個人を識別できない情報を取得し、利用することがあります。また、同様の目的で広告主や情報提供元、サービス提供元などに提供する場合があります。</p>

					<p class="txt_s_mbfix mt-20">・クッキーやアクセスしたURL、コンテンツ、参照順などの行動履歴</p>
					<p class="txt_s_mbfix">・お客様が本サービスを利用した際のIPアドレス、ブラウザの種類、ブラウザ言語等の情報</p>
					<p class="txt_s_mbfix">・性別、職業、年齢などの属性情報</p>
					<p class="txt_s_mbfix">・携帯電話等から送信される位置情報</p>
					<p class="txt_s_mbfix mb_70">・お客様が利用される機器情報（OS、端末の個体識別情報、コンピュータ名等）</p>

					<div class="txt_m bold">11. 改定</div>
					<p class="txt_s mb_70">当社は、本ポリシーを随時改定することがあります。本ポリシーの重要な改定は、最新のものとともに当社のウェブサイトに掲示します。本ポリシーの改定は、当社がウェブサイト上またはアプリ内に掲載した時点から適用されるものとします。ただし、法令の改定に基づく内容の改定の場合は、所定の方法により改定が行われるものとします。</p>

					<div class="txt_m">本ポリシーに関するお問い合わせ</div>
					<p class="txt_s mb_10">info@imamemo.co.jp</p>
					<p class="txt_s mb_10">2020年7月7日　制定</p>
                    <div class="txt_ss mb_36">Copyright Imamemo inc. All Rights Reserved. </div>
				</div>               
			</div>
		</section>
	</article>
	<Footer/>
</body>
</template>
<script>
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
let searchTimer = undefined;

export default {
	components:{
		Footer,
		Header,
	},
	data() {
        return{
			showSide:false,
			keywords: '', //搜索的绑定值
            showSearchList: false, //显示搜索列表
            searchList: [], //搜索列表
            activeSearch: '', //搜索列表选中
			selectTab: undefined,
			tabs: [],
			handlstyle:{
				width:'440px',
				padding:'200px'
			}
		}
	},
	methods:{
		targetSide() {
            this.showSide = !this.showSide
        },
		//Header
         keydownEnter () {
      　　this.toSearchPage()
    　　},
		changeSearchEvent(val) {
            this.keywords = val;
            this.setSearchList();
        },
		 //搜索页
        toSearchPage() {
            if (this.keywords != '') {
                this.$router.push({name: 'search', query: {keywords: this.keywords}})
            }
        },
		 setSearchList() {
            if (this.keywords && this.keywords.trim() != '') {
                clearTimeout(searchTimer)
                searchTimer = setTimeout(() => {
                    if (this.keywords) {
                        Api.getSearch({
                            keywords: this.keywords
                        }).then((res) => {
                            let data = res.result.data
                            if (data.length > 0) {
                                this.searchList = data
                            }
							console.log(this.searchList);
                        });
                    }
                }, 300)
            } else {
                this.searchList = []
            }

        },
		changeTab (val) {
		this.selectTab = val
	　},
	}
}
</script>
<style lang="scss">
	.onerow{
		width: 1300px;
		margin: 0 auto;
        background-color: #FFFFFF;
        .under_Layer{
            padding: 77px 25px;
            line-height: 20px;
            font-size: 12px;
            font-family: 'Microsoft YaHei UI';
            color: #040000;
            font-weight: 300;
        }
	}
	h1{
		text-align: center;
        font-size: 24px;
        font-family:'Segoe UI';
        font-weight: 400 !important;
        // line-height: 0px;
        color: #707070 !important; 
        opacity: 1;
        padding: 5px;
	}
</style>