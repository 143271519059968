<template>
  <transition name="dialog">
    <div
        v-if="showDialog"
        id="dialog-bg"
        @touchmove.prevent
        @scroll.prevent @click="close">
      <div id="dialog" @click.stop="">
        <img class="comment-cross" src="../assets/comment-cross.png" height="23" width="23" @click="close"/>
        <div id="textareaDiv" class="comment-text-div">
          <textarea id="textarea" class="comment-text" maxlength="500" :placeholder="$t('mdialog.saySomething')" v-model="content" v-on:keyup="textChange"></textarea>
          <div class="btn-emoticons">
            <!-- todo 表情包-->
              <!--<a-dropdown placement="bottomLeft" :overlay-style="{minWidth:'500px', width: '38%'}">-->
              <!--<div class="icon-emoticons">-->
                <!--<img class="img-emoticons" width="27" height="27" :src="require('@/assets/emotion.png')">-->
                <!--<span>{{$t('expression')}}</span>-->
              <!--</div>-->
              <!--<template #overlay>-->
                <!--<a-menu>-->
                  <!--<emotion @emotion="handleEmotion" :height="110" style="border: none"></emotion>-->
                <!--</a-menu>-->
              <!--</template>-->
            <!--</a-dropdown>-->
          </div>
          <div class="btn-box">
            <button @click="submit" :style="'background-color:'+(isActive===true?'#0870d1': '#EEEEEE')+';color:'+(isActive===true?'#EEEEEE': '#cccccc')">{{$t('mdialog.replay')}}</button>
<!--            20210930 取消按钮删除-->
<!--            <button @click="close" :style="'background-color:'+(isActive===true?'#0870d1': '#EEEEEE')+';color:'+(isActive===true?'#EEEEEE': '#cccccc')">{{$t('mdialog.cancel')}}</button>-->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import elementResizeDetectorMaker from "element-resize-detector";
  import {autoTextarea,autoTextareaDivChange} from '@/utils/common';
  import Emotion from '@/components/Emotion/index'
export default{
    components: {
        Emotion
    },
  props:{
    showDialog: Boolean,
    // content: String
  },
    data() {
        return {
            isActive: false,
            content: ''
        }
    },
  emits: ['submit'],
  methods: {
      // 表情包
      handleEmotion (i) {
          this.isActive = true
          this.content += i
      },
      // 评论显示表情
      emotion (res) {
          let word = res.replace(/\#|\;/gi,'')
          const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
          let index = list.indexOf(word)
          return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" align="middle">`
      },
    close: function(){
        this.isActive = false
        this.content = ''
      this.$emit("closeDialog");
    },
    submit: function(){
      this.$emit('submit', this.content);
        this.$nextTick(() => {
            this.content = ''
        });
    },
      textChange() {
        const _this = this;
        const erd = elementResizeDetectorMaker();
        erd.listenTo(document.getElementById("textareaDiv"), element => {
          _this.$nextTick(() => {
            autoTextareaDivChange(document.getElementById("textarea"))
          });
        });
        autoTextarea(document.getElementById("textarea"))
          if (this.content) {
              this.isActive = true
          } else {
              this.isActive = false
          }
      },
  },

  watch:{

  }

}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

#dialog-bg{
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 98;
}

#dialog{
  //border-radius: 20px 20px 0 0;
  position: fixed;
  bottom: 0;
  //background-color: #FFFFFF;
  width: 100vw;
  padding: 15px;
  padding-bottom: 25px;
  .comment-cross {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 5555;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

/* 适配电脑等大屏幕 */
@media (min-width: 100px) {
  #dialog{
    width: 100%;
    max-width: 840px;
    left:0;
    right:0;
    margin:0 auto;
  }



  .comment-text-div {
    padding: 18px 20px;
    height: auto;
    background: #F9F9F9;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    .comment-text {
      width: 100%;
      height: auto;
      resize: none;
      border: none;
      font-size: 14px;
      line-height: 19px;
      background: none;
      outline: none;
    }
    .btn-emoticons{
      /*position: absolute;*/
      float: left;
      left: 20px;
      bottom: 20px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      .icon-emoticons{
        display: flex;
        align-items: center;
        span{
          margin-left: 6px;
          font-size: 15px;
        }
      }
    }
    .btn-box {
      /*position: absolute;*/
      float: right;
      bottom: 20px;
      right: 20px;

      > button {
        width: 64px;
        height: 32px;
        background: #EEEEEE;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 34px;
        color: #CCCCCC;
        border: none;
        outline: none;
        margin-left: 15px;
        cursor: pointer;

      }
    }
  }
}

.dialog-button{
  width: 100%;
  background-color: #6367D5;
  border-width: 0;
  border-radius: 360px;
  padding: 10px;
  outline: none;
  color: white;
}

.dialog-button:focus {
  outline: none;
}

.dialog-button:active{
  background-color: #585dbe;
  border-color: #585dbe;
}


.dialog-enter-active, .dialog-leave-active {
  transition: all .5s;
}

.dialog-enter, .dialog-leave-to {
  opacity: 0;
  transform: translateY(300px);
}
textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #cccccc;
}
</style>
