<script>
export default {

    name: "Authority",
    components: {
    },
    data() {
        return {
            code: null,
            state: null,
        }
    },

    mounted() {
        this.getRouteParams();
    },
    methods: {
        //Header
        getRouteParams() {
            this.code = this.$route.query.code;
            this.state = this.$route.query.state;
            console.log("code ===>", this.code);
            console.log("state ===>", this.state);
        },
    }
}
</script>

