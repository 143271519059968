<template>
  <Embeddialog :showDialog="dialogVisible" ref="Embeddialog" :content="iframe" :footer="null" centered cancelText @submit="submit" @close="close"/>
  <Reportdialog :reportDialog="reportDialog" ref="Reportdialog" :footer="null" centered cancelText @reportsubmit="reportsubmit($event)" @reportClose="reportClose"/>
  <TitleDialog
          ref="TitleDialog"
          width=480px
          :bodyStyle="{ overflowY: 'auto', background: '#f9f9f9', border: '10px'}"
          centered
          cancelText
          @loginVisible="visibleTitleList = false,visible=true"
          :visible="visibleTitleList"
          @cancel="visibleTitleList=false"
          @refreshTitle="refreshTitle"
          :footer="null"
          style="border-radius: 10px;"
  ></TitleDialog>
  <DetailInfo
          v-if="detailInfoVisible"
          ref="detailInfo"
          @refreshDataList="refreshComments()"
          @onCopy="onCopy()"
          @onEmbed="onEmbed()"
  ></DetailInfo>
  <Header
          :showSearch="!showCommentHeader"
          :entryId="entryId"
          :entryName="entryName"
          @targetSide="targetSide"
          :style="{position: 'relative',}"
          :value="search_value"
          @keydownEnter="keydownEnter"
          @changeSearchEvent="changeSearchEvent"
  />

  <Header
          @targetSide="targetSide"
          :tabs="topTabs"
          :selectTab="selectTab"
          @changeTab="selectTabHandle"
          :showSearch="showCommentHeader"
          :value="search_value"
          @keydownEnter="keydownEnter"
          @changeSearchEvent="changeSearchEvent"
          :style="headerStyle"
          :list="total"
  />
  <DeleteDialog :deleteDialog="deleteDialog" :list="currPicture" ref ="DeleteDialog" :footer="null" centered cancelText @deleteSubmit="deleteSubmit($event)" @deleteClose="deleteClose" />

  <div class="detail-box" @scroll="scrollEvent">
    <div class="container">
      <div class="container-c">
        <div class="container-c-left">
          <div class="item-list" v-if="datas.length > 0">
            <div class="item-icon-tabs">
              <!-- <span class="icon-bg-box guanzhu">
              </span> -->
              <span class="icon-def-box">
                <!-- <svg
                        width="18"
                        height="22"
                        viewBox="0 0 18 22"
                        fill="currentColor"
                >
                  <path
                          data-name="路径 695"
                          class="cls-1"
                          d="M110,56.89a1.036,1.036,0,0,1-1.5-.906V36.753A1.772,1.772,0,0,1,110.291,35h14.419a1.772,1.772,0,0,1,1.791,1.753V55.984a1.036,1.036,0,0,1-1.5.907l-7.53-3.692Zm.569-19.86V54.335L117,51.158a1.057,1.057,0,0,1,.932,0l6.5,3.184V37.029Z"
                          transform="translate(-108.5 -35)"
                  />
                </svg> -->
                <a-popover placement="bottom"  v-model="reportVisible"  >
                   <template #content >
                     <div style="display: flex;flex-direction:column">
                       <a-Button style="color: #0870D1;border: 0" @click="blockUser">{{$t('detail.notShowAgainLeft') + currPicture.insertUser + $t('detail.notShowAgainRight')}}</a-Button>
                       <a-Button style="color: #0870D1;margin-top: 5px;  border: 0" @click="report">{{$t('detail.report')}}</a-Button>
                       <a-Button style="color: #0870D1;margin-top: 5px;  border: 0" v-show="isShow" @click="deleteImag">{{this.$t('deletePicture.delete')}}</a-Button>
                     </div>
                   </template>
                  <div  style="width: 20px;height: 20px;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 4 18" @click="reportClick">
                     <path id="路径_640" data-name="路径 640" d="M0,2A2,2,0,1,0,2,0,2,2,0,0,0,0,2ZM7,2A2,2,0,1,0,9,0,2,2,0,0,0,7,2Zm7,0a2,2,0,1,0,2-2,2,2,0,0,0-2,2Z" transform="translate(4) rotate(90)" fill="#5f646e"/>
                   </svg>
                  </div>

                </a-popover>
              </span>
            </div>
            <div class="item-list-title" @click="titleClick()">
                {{ currPicture.note ? currPicture.note :$t('detail.title') }}
            </div>
            <div class="item-list-imgbox" v-if="currPicture.pictureUrl">
              <div class="item-list-imgbox-center">
                <img class="item-list-img" @load="onload" :src="currPicture.pictureUrl" @click="addOrUpdateHandle(currPicture, datas)"/>
              </div>
            </div>
            <div class="item-dec" v-if="!currPicture.pictureUrl">
              <span v-html="currPicture.comment"></span>
            </div>
            <div class="item-info">
              <div class="info-left">

                <a-popover placement="bottomLeft">
                   <template #content>
                       <div class="searchTitle">{{$t('searchTerm')}}</div>
                       <img class="searchImg" src="../assets/guge.png" @click="goGuge(currPicture.entryTitle)" height="21.5" width="26"/>
                       <img class="searchImg" src="../assets/weiji.png" @click="goWeiji(currPicture.entryTitle)" height="26" width="26"/>
                   </template>
                   <span class="item-info-name">{{ currPicture.entryTitle }}</span>
                </a-popover>

                <span class="item-info-time">
                    <!-- {{this.getWaitDurationTime(currPicture.insertTime)}} -->
                    {{this.getNewsTime(currPicture.insertTime)}}
                </span>
              </div>
              <div class="info-right">
                <div class="icon-box" style="margin-top: 10px">
                  <a-popover placement="bottom" v-model="popVisible">
                        <template #content>
                          <div class="link">{{shareUrl+'/'+currPicture.entryId+'?'+'pictureId='+currPicture.pictureId}}</div>
                          <a-Button type="danger" class="embed" @click="onEmbed">{{$t('embeddedCode')}}</a-Button>
                          <a-Button class="copy" @click="onCopy">{{$t('copyLink')}}</a-Button>
                        </template>
                        <span class="icon-def-box">
                           <svg width="20" height="17" fill="currentColor" style="margin-top: -20px" @click="click">
                            <path d="M85.239,149.619a1.179,1.179,0,0,1-.172-.013,1.287,1.287,0,0,1-1.057-1.443c.439-4.221,1.882-7.359,4.291-9.331a11.025,11.025,0,0,1,6.512-2.365l.149-.009v-2.345a1.549,1.549,0,0,1,.4-1.022,1.45,1.45,0,0,1,1.059-.472,1.419,1.419,0,0,1,1.028.44l5.836,6.2a2.642,2.642,0,0,1,0,3.6l-5.838,6.2a1.428,1.428,0,0,1-.988.438h-.042a1.484,1.484,0,0,1-1.46-1.485V145.5h-.249c-2.25,0-6.339.462-8.453,3.557a1.226,1.226,0,0,1-1.021.56h0Zm9.474-5.558c.351,0,.628.01.794.016h.016l.13,0a.707.707,0,0,1,.692.721v3.379l5.957-6.323a1.163,1.163,0,0,0,0-1.587l-5.955-6.332v3.224a.707.707,0,0,1-.692.721c-3.954,0-8.921,1.623-10.13,9.356l-.084.535.355-.4C88.38,144.492,92.492,144.061,94.713,144.061Z" transform="translate(-83.999 -132.619)"/>
                           </svg>
                        </span>
                    </a-popover>
                  <!-- <span class="icon-def-box">
                    <svg
                            width="20"
                            height="17"
                            viewBox="0 0 20 17"
                            fill="currentColor"
                    >
                      <path
                              d="M85.239,149.619a1.179,1.179,0,0,1-.172-.013,1.287,1.287,0,0,1-1.057-1.443c.439-4.221,1.882-7.359,4.291-9.331a11.025,11.025,0,0,1,6.512-2.365l.149-.009v-2.345a1.549,1.549,0,0,1,.4-1.022,1.45,1.45,0,0,1,1.059-.472,1.419,1.419,0,0,1,1.028.44l5.836,6.2a2.642,2.642,0,0,1,0,3.6l-5.838,6.2a1.428,1.428,0,0,1-.988.438h-.042a1.484,1.484,0,0,1-1.46-1.485V145.5h-.249c-2.25,0-6.339.462-8.453,3.557a1.226,1.226,0,0,1-1.021.56h0Zm9.474-5.558c.351,0,.628.01.794.016h.016l.13,0a.707.707,0,0,1,.692.721v3.379l5.957-6.323a1.163,1.163,0,0,0,0-1.587l-5.955-6.332v3.224a.707.707,0,0,1-.692.721c-3.954,0-8.921,1.623-10.13,9.356l-.084.535.355-.4C88.38,144.492,92.492,144.061,94.713,144.061Z"
                              transform="translate(-83.999 -132.619)"
                      />
                    </svg>
                  </span> -->
                </div>
                <div class="icon-box" @click="this.favoriteHandle" :class="currPicture.isFavorite ? 'ismy':''">
                  <span class="icon-bg-box shoucang" :style="{clipPath: 'polygon(0% ' + calcPer(currPicture.favoriteNum || 0) + '%, 100% ' + calcPer(currPicture.favoriteNum || 0) + '%, 100% 100%, 0% 100%)'}">
                  </span>
                  <span class="icon-def-box">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                      <path d="M67.93,96.69a1.5,1.5,0,0,0,1.53.08L74,94.374l4.538,2.4a1.5,1.5,0,0,0,1.531-.08,1.353,1.353,0,0,0,.591-1.351l-.884-5.179,3.815-3.735a1.332,1.332,0,0,0,.335-1.4,1.431,1.431,0,0,0-1.151-.926l-5.2-.759-2.263-4.6a1.474,1.474,0,0,0-2.615,0l-2.262,4.6-5.2.759a1.43,1.43,0,0,0-1.151.926,1.333,1.333,0,0,0,.336,1.4l3.813,3.735-.885,5.179A1.354,1.354,0,0,0,67.93,96.69ZM65.214,85.471l5.188-.756.913-.133.4-.814L74,79.127l2.282,4.641.4.814.913.133,5.188.756L79,89.171l-.635.622.148.867.884,5.169-4.572-2.411L74,92.983l-.826.436L68.6,95.831l.884-5.17.148-.867L69,89.171Z" transform="translate(-63.998 -77.942)"/>
                    </svg>
                  </span>
                </div>
                <div class="icon-box" :class="isCommentMap[currPicture.pictureId] ? 'ismy':''" @click="openComment()">
                <!-- <div class="icon-box" :class="currPicture.commentNum ? 'ismy':''" @click="openComment()"> -->
                  <span class="icon-bg-box pinglun" :style="{clipPath: 'polygon(0% ' + calcPer(currPicture.commentNum || 0) + '%, 100% ' + calcPer(currPicture.commentNum || 0) + '%, 100% 100%, 0% 100%)'}">
                  </span>
                  <span class="icon-def-box">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                      <path d="M1.7,15c-0.3-0.4-0.4-0.7-0.5-0.8C0.4,12.9,0,11.5,0,10C-0.3,4.8,4,0.3,9.5,0S19.7,3.8,20,9s-4,9.7-9.5,10c-0.2,0-0.3,0-0.5,0c-1.8,0-3.6-0.5-5.1-1.2l-0.5-0.3L2,18c-0.4,0.1-0.8-0.1-0.9-0.5c0-0.1,0-0.3,0-0.4L1.7,15zM3.2,15l-0.4,1.4L4.4,16c0.2-0.1,0.4,0,0.6,0.1c0.3,0.1,0.5,0.3,0.8,0.4c1.4,0.8,3,1.1,4.6,1.1c4.7-0.2,8.3-4,8.1-8.4s-4.2-7.9-8.9-7.7C5.1,1.7,1.5,5.3,1.5,9.6c0,1.3,0.4,2.7,1.1,3.8c0.1,0.2,0.3,0.5,0.7,1C3.3,14.6,3.3,14.8,3.2,15L3.2,15z"/>
                    </svg>
                  </span>
                </div>
                <div class="icon-box" @click="this.likeHandle" :class="currPicture.isLike ? 'ismy':''">
                  <span class="icon-bg-box dianzan" :style="{clipPath: 'polygon(0% ' + calcPer(currPicture.likeNum || 0) + '%, 100% ' + calcPer(currPicture.likeNum || 0) + '%, 100% 100%, 0% 100%)'}">
                  </span>
                  <span class="icon-def-box">
                    <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                      <path d="M140.883,137.666H129.416a3.252,3.252,0,0,1-2.18-.907,2.971,2.971,0,0,1-.992-2.115l-.244-7.4v-.01a2.909,2.909,0,0,1,2.925-2.887h.428c.954,0,1.185-.186,2.111-1.06a5.319,5.319,0,0,0,1.245-2.122c.075-.248.1-.365.124-.443a1.5,1.5,0,0,1,.185-.451c.019-.042.077-.176.116-.268.121-.282.142-.331.166-.375a1.878,1.878,0,0,1,1.581-.956,3.121,3.121,0,0,1,2.579,1.166l.031.041a4.716,4.716,0,0,1,.868,2.149.442.442,0,0,1,.005.11h0a7.355,7.355,0,0,1-.117,1.183l-.1,1.024h4.932a2.936,2.936,0,0,1,2.409,1.252,2.854,2.854,0,0,1,.32,2.669l-2.193,7.548-.012.029a2.909,2.909,0,0,1-2.717,1.824Zm-13.6-10.443.247,7.4v.011a1.982,1.982,0,0,0,1.888,1.766h11.465a1.63,1.63,0,0,0,1.514-1l2.192-7.544.011-.029a1.6,1.6,0,0,0-.168-1.506,1.647,1.647,0,0,0-1.357-.709h-5.032l-.029,0-.458-.008a.956.956,0,0,1-.529-.273c-.408-.419-.287-1.018-.148-1.712.057-.279.118-.582.157-.9a.52.52,0,0,1,0-.165v-.018c0-.049.011-.12.02-.222.006-.066.013-.133.017-.18a3.436,3.436,0,0,0-.617-1.508l-.025-.034a1.837,1.837,0,0,0-1.458-.658.6.6,0,0,0-.537.282c-.021.045-.08.183-.12.277-.116.27-.136.317-.159.359s-.055.1-.066.123-.007.027-.011.046c-.021.085-.057.227-.141.5a6.586,6.586,0,0,1-1.554,2.642l-.021.021a3.715,3.715,0,0,1-3.013,1.423h-.428A1.632,1.632,0,0,0,127.284,127.223Zm5.738-6.965a.009.009,0,0,0,0,0A.014.014,0,0,1,133.022,120.258Z" transform="translate(-126 -118.666)"/>
                    </svg>
                  </span>
                </div>

                <div class="item-icon-tabs" @click="this.bloomHandle">
                  <!-- <span class="icon-bg-box guanzhu">
                  </span> -->
                  <!-- <span class="icon-def-box"> -->
                    <!-- <svg
                            width="18"
                            height="22"
                            viewBox="0 0 18 22"
                            fill="currentColor"
                    >
                      <path
                              d="M110,56.89a1.036,1.036,0,0,1-1.5-.906V36.753A1.772,1.772,0,0,1,110.291,35h14.419a1.772,1.772,0,0,1,1.791,1.753V55.984a1.036,1.036,0,0,1-1.5.907l-7.53-3.692Zm.569-19.86V54.335L117,51.158a1.057,1.057,0,0,1,.932,0l6.5,3.184V37.029Z"
                              transform="translate(-108.5 -35)"
                      />
                    </svg> -->
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18">
  <path id="路径_640" data-name="路径 640" d="M0,2A2,2,0,1,0,2,0,2,2,0,0,0,0,2ZM7,2A2,2,0,1,0,9,0,2,2,0,0,0,7,2Zm7,0a2,2,0,1,0,2-2,2,2,0,0,0-2,2Z" transform="translate(4) rotate(90)" fill="#5f646e"/>
</svg> -->
                  <!-- </span> -->
                </div>
              </div>
            </div>
            <div class="item-dec" v-if="currPicture.pictureUrl">
                <!-- {{ currPicture.comment }} -->
              <span v-html="currPicture.comment"></span>
            </div>
          </div>
        </div>
        <div class="container-c-right">
          <div class="container-right-box">
            <div class="center-top">
              <div class="tab-commont">
                <div @click="changeTab(1)" class="tab-item" :class="activeTab==1?'active':''">
                    {{$t('detail.all')}}
                </div>
                <div @click="changeTab(2)" class="tab-item" :class="activeTab==2?'active':''">
                    {{$t('detail.impression')}}
                </div>
                <div @click="changeTab(3)" class="tab-item" :class="activeTab==3?'active':''" v-if="rightFilterTabShow">
                    {{$t('detail.queryResults')}}
                  <span @click="closeTab">×</span>
                </div>
              </div>
              <div @click="showFilterHandler" class="filter">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor">
                  <path class="cls-1" d="M82.618,76.328H78.741c-.161-.051-.216-.28-.216-.459v-7.4a.07.07,0,0,0-.021-.049l-5.182-5.29a.58.58,0,0,1-.057-.067,1.636,1.636,0,0,1-.448-1.126,1.6,1.6,0,0,1,1.576-1.61H87.242a1.6,1.6,0,0,1,1.576,1.61,1.617,1.617,0,0,1-.433,1.106.372.372,0,0,1-.047.054L82.96,68.589a.073.073,0,0,0-.021.05v7.23C82.939,76.094,82.893,76.33,82.618,76.328Zm-2.945-.742h2.119V68.638a1.25,1.25,0,0,1,.354-.878l5.362-5.472c.017-.02.032-.037.049-.055a.439.439,0,0,0-.316-.733H74.394a.438.438,0,0,0-.309.739c.028.03.053.062.077.091l5.155,5.261a1.263,1.263,0,0,1,.356.878v7.117Zm0,0" transform="translate(-72.818 -60.328)"/>
                </svg>
              </div>

            </div>
            <div @scroll="scrollEvent1" class="center-box-info-box" id="centerBox" v-show="activeTab == 1">
              <img class="item-list-img-last" @click="toLast()"  v-show="this.firstScrollTop > 0" src="../assets/up.png"/>
              <div class="list-item-simple" v-for="(item, i) in (activeTab == 1 || activeTab == 2 ? datas : filter.list)"
                   v-show="activeTab == 2 || (i == 0 || (item.pictureId != datas[0].pictureId))">
                <div class="list-item-simple-box" v-if="item.pictureUrl" @click="selectPicture(i)">
                  <div class="list-item-simple-box-center">
                    <img class="item-list-img-simple" :class="'item-list-img-simple-box'+i" @load="onloadvice(i)" v-lazy="item.pictureUrl"/>
                    <div class="list-item-simple-title">{{ item.note }}</div>
                  </div>
                </div>
                <div class="list-item-simple-box" v-if="!item.pictureUrl && (item.note || item.comment)" @click="selectPicture(i)">
                  <div class="list-item-simple-box-center">
                    <div class="item-list-info-title line-clamp1" v-if="item.note">
                        {{item.note }}
                    </div>
                    <div class="item-list-info-dec line-clamp2" v-if="!item.note">
                      <span v-html="item.comment"></span>
                        <!-- {{item.comment }} -->
                    </div>
                  </div>
                </div>
              </div>
              <img class="item-list-img-next" @click="toNext()"  v-if="this.listLength > 10" v-show="this.firstShows" src="../assets/down.png"/>
            </div>
            <Loading :loading="loading"/>
            <!--filter list-->
            <div class="center-box-info-box" v-show="activeTab == 3" id="filter-box" @scroll="handleScroll3">
               <img class="item-list-img-last" @click="toLast3()"  v-show="this.filterScrollTop > 0" src="../assets/up.png"/>
               <div class="list-item-simple" v-for="(item, i) in filter.list">
                <div class="list-item-simple-box" v-if="item.pictureUrl" @click="selectPicture(i)">
                  <div class="list-item-simple-box-center">
                    <img class="item-list-img-simple" :class="'item-list-img-simple-box'+i" @load="onloadvice(i)" v-lazy="item.pictureUrl"/>
                    <div class="list-item-simple-title">{{ item.note }}</div>
                  </div>
                </div>
                <div class="list-item-simple-box" v-if="!item.pictureUrl && (item.note || item.comment)" @click="selectPicture(i)">
                  <div class="list-item-simple-box-center">
                    <div class="item-list-info-title line-clamp1" v-if="item.note">
                        {{item.note }}
                    </div>
                    <div class="item-list-info-dec line-clamp2" v-if="!item.note">
                      <span v-html="item.comment"></span>
                        <!-- {{item.comment }} -->
                    </div>
                  </div>
                </div>
              </div>
              <img class="item-list-img-next" @click="toNext3()"  v-if="this.filter.list.length > 12" v-show="this.filterShows" src="../assets/down.png"/>
            </div>
            <div class="center-box-info-box-yx" v-show="activeTab==2" >
              <div class="item-list-box" id="img-box" @scroll="handleScroll2">
                <img class="item-list-img-last"  @click="toUp()"  v-show="upScrollTop > 0" src="../assets/up.png"/>
                <div class="item-list" v-for="(item,i) in impression.list">
                  <!--没有图片-->
                  <div v-if="item.pictureUrl === null" class="item-list-info-box">
                    <div class="item-list-info-title line-clamp1">{{item.note}}</div>
                    <div class="item-list-info-dec line-clamp2"><span v-html="item.comment"></span></div>
                    <div class="item-list-info-time">{{this.getWaitDurationTime(item.insertTime)}}
                    </div>
                  </div>
                  <!--有图片-->
                  <div
                          v-if="item.pictureUrl !== null"
                          class="item-list-info-box-img"
                  >
                    <div class="item-list-img-box" @click="selectPicture(i)">
                      <img
                              class="item-list-img-impression"
                              :class="'item-list-img'+i"
                              @load="onloadimpression(i)"
                              v-lazy="item.pictureUrl"
                      />
                    </div>
                    <div class="item-list-info">
                      <div class="item-list-info-title">{{item.note}}</div>
                      <div class="item-list-info-time" v-if="i > 2">
                        {{this.getWaitDurationTime(item.insertTime)}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img class="item-list-img-next" @click="toDown()" v-if="this.listLength > 3" v-show="this.shows" src="../assets/down.png"/>
               <!--20210922 隐藏印象时间条-->
              <!--20210922 隐藏印象时间条-->
               <!--20210922 隐藏印象时间条-->
              <!--                            <div class="time-axis">-->
              <!--                                <div class="time-axis-list">-->
              <!--                                    <div class="text">now</div>-->
              <!--                                    <div class="line-box">-->
              <!--                                        <div class="quan"></div>-->
              <!--                                        <div class="xian"></div>-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                                <div class="time-axis-list active">-->
              <!--                                    <div class="text">一年前</div>-->
              <!--                                    <div class="line-box">-->
              <!--                                        <div class="quan"></div>-->
              <!--                                        <div class="xian"></div>-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                                <div class="time-axis-list">-->
              <!--                                    <div class="text">最终</div>-->
              <!--                                    <div class="line-box">-->
              <!--                                        <div class="quan"></div>-->
              <!--                                        <div class="xian"></div>-->
              <!--                                    </div>-->
              <!--                                </div>-->
              <!--                            </div>-->
            </div>
            <!--<div-->
            <!--@scroll="scrollEvent2"-->
            <!--class="center-box-info-box"-->
            <!--v-if="activeTab==4"-->
            <!--&gt;-->
            <!--<div class="list-item-simple">-->
            <!--<div-->
            <!--class="list-item-simple-box"-->
            <!--:style="{backgroundImage: `url(${require('@/assets/logo.png')})`}"-->
            <!--&gt;-->
            <!--<div class="list-item-simple-box-center">-->
            <!--<div class="list-item-simple-title">奥术大师大所大所大所大所大所多</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="list-item-simple">-->
            <!--<div-->
            <!--class="list-item-simple-box"-->
            <!--:style="{backgroundImage: `url(${require('@/assets/logo.png')})`}"-->
            <!--&gt;-->
            <!--<div class="list-item-simple-box-center">-->
            <!--<div class="list-item-simple-dec line-clamp4">-->
            <!--奥术大师大所大所大所大所大所多奥术大师大所大所大所大所大所多奥术大师大所大所大所大所大所多奥术大师大所大所大所大所大所多-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="list-item-simple">-->
            <!--<div-->
            <!--class="list-item-simple-box"-->
            <!--:style="{backgroundImage: `url(${require('@/assets/logo.png')})`}"-->
            <!--&gt;-->
            <!--<div class="list-item-simple-box-center">-->
            <!--<div class="list-item-simple-title">奥术大师大所大所大所大所大所多</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--<div class="list-item-simple">-->
            <!--<div-->
            <!--class="list-item-simple-box"-->
            <!--:style="{backgroundImage: `url(${require('@/assets/logo.png')})`}"-->
            <!--&gt;-->
            <!--<div class="list-item-simple-box-center">-->
            <!--<div class="list-item-simple-dec line-clamp4">-->
            <!--奥术大师大所大所大所大所大所多奥术大师大所大所大所大所大所多奥术大师大所大所大所大所大所多奥术大师大所大所大所大所大所多-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
            <!--</div>-->
          </div>
        </div>
      </div>

      <div class="item-center">

        <div class="comment-box">
          <div class="comment-text-div" id="comment-text-div">
            <textarea class="comment-text" id="comment-text" :placeholder="$t('detail.saySomething')" v-model="commentContext" v-on:keyup="textChange()">
            </textarea>
            <!--<div class="btn-emoticons">-->
                <!--&lt;!&ndash; todo 表情包&ndash;&gt;-->
              <!--&lt;!&ndash;<a-dropdown placement="bottomLeft" :overlay-style="{minWidth:'500px', width: '38%'}">&ndash;&gt;-->
                <!--&lt;!&ndash;<div class="icon-emoticons">&ndash;&gt;-->
                  <!--&lt;!&ndash;<img class="img-emoticons" width="27" height="27" :src="require('@/assets/emotion.png')">&ndash;&gt;-->
                  <!--&lt;!&ndash;<span>{{$t('expression')}}</span>&ndash;&gt;-->
                <!--&lt;!&ndash;</div>&ndash;&gt;-->
                <!--&lt;!&ndash;<template #overlay>&ndash;&gt;-->
                  <!--&lt;!&ndash;<a-menu>&ndash;&gt;-->
                    <!--&lt;!&ndash;<emotion @emotion="handleEmotion" :height="110" style="border: none"></emotion>&ndash;&gt;-->
                  <!--&lt;!&ndash;</a-menu>&ndash;&gt;-->
                <!--&lt;!&ndash;</template>&ndash;&gt;-->
              <!--&lt;!&ndash;</a-dropdown>&ndash;&gt;-->
            <!--</div>-->
            <div class="btn-box">
              <button :style="'background-color:'+(isActive===true?'#0870d1': '#EEEEEE')+';color:'+(isActive===true?'#EEEEEE': '#cccccc')" @click="addComment(0)">
                  {{$t('detail.suggest')}}
              </button>
              <button :style="'background-color:'+(isActive===true?'#1673CE': '#EEEEEE')+';color:'+(isActive===true?'#EEEEEE': '#cccccc')" @click="addComment(1)">
                  {{$t('detail.question')}}
              </button>
              <button :style="'background-color:'+(isActive===true?'#1673CE': '#EEEEEE')+';color:'+(isActive===true?'#EEEEEE': '#cccccc')" @click="addComment(2)">
                  {{$t('detail.comment')}}
              </button>
            </div>
          </div>

          <div class="tab-box">
            <div class="tab-item-box" id="tabItemBox">
              <div class="tab-item" v-for="(item,i) in tabs" :class="item.tagId==selectTab?'active':''" :key="item.tagId" @click="selectTabHandle(item)">
                  {{ item.tagName }} ({{ i == 0 ? total.suggestionCnt : (i == 1 ? total.questionCnt : total.commentCnt) }})
              </div>
            </div>
            <div class="filter-item">
              <span class="active" v-show="!selectSort" @click="changeHeat(1)">
                <svg fill="currentColor" width="11.966" height="14.196" viewBox="0 0 11.966 14.196">
                  <g transform="translate(0 0)">
                    <path data-name="路径 700" class="cls-1" d="M122.908,218.86h-10.8A.131.131,0,0,0,112,219v1.01a.131.131,0,0,0,.11.144h10.8a.131.131,0,0,0,.11-.144V219A.131.131,0,0,0,122.908,218.86Z" transform="translate(-111.627 -205.964)"/>
                    <path data-name="路径 706" class="cls-1" d="M10.167,8.019c-1.353-.025-3.806.108-4.98-.862C3.753,5.889,4.076,5.188,4.733,3.984c.715-.854.791-1.163.477-1.743C4.742,1.7,2.427,1.28.789,1.318c-.174,0-.764-.2-.788-.649S.513,0,.686,0C1.164-.012,5.43.174,6.6,1.609c.5.84.471,1.171-.249,2.49-.429.553-1.362,1.609.113,2.4a19.631,19.631,0,0,0,4.073.23c1.189.069.465.028.683.047a.753.753,0,0,1,.763.644c-.061.811-.778.63-.946.634C10.937,8.049,10.523,8.025,10.167,8.019Z" transform="translate(0 11.108) rotate(-68)"/>
                  </g>
                </svg>
                {{$t('detail.hot')}}
              </span>
              <span class="active" v-show="selectSort" @click="changeHeat(0)">
                <svg fill="currentColor" width="12" height="12" viewBox="0 0 12 12">
                  <path data-name="路径 699" class="cls-1" d="M91.333,86.233a5.1,5.1,0,1,1-5.1,5.1,5.1,5.1,0,0,1,5.1-5.1m0-.9a6,6,0,1,0,6,6A6,6,0,0,0,91.333,85.333Z" transform="translate(-85.333 -85.333)"/>
                  <path data-name="路径 700" class="cls-1" d="M483.385,273.438a.542.542,0,0,1-.321-.107l-2.851-2.138a.527.527,0,0,1-.214-.428V267.2a.535.535,0,0,1,1.069,0v3.293l2.637,1.981a.535.535,0,0,1,.107.713A.527.527,0,0,1,483.385,273.438Z" transform="translate(-474.843 -264.55)"/>
                </svg>
                {{$t('detail.time')}}
              </span>
            </div>
            <div class="line"></div>
          </div>
          <div class="comment-list-box">
            <div class="comment-item" v-for="(item, commentIndex) in (infoPage[selectTab].list) ">
              <div class="comment-item-info">
                <div class="user-ava">
                  <img class="img-user" :src="item.userLogo ? item.userLogo : require('@/assets/def-ava.svg')" alt="">
                </div>
                <div class="info-text" @mouseover="showLike">
                  <span class="comment-content-span" v-html="item.content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, emotion)"></span>
                  <div style="display:inline-block">
                    <span class="text">
                      {{ getWaitDurationTime(item.createDate) }}
                  </span>
                  <span class="text text-btn" @click="showDialog(commentIndex)">{{$t('detail.replay')}}</span>
                  </div>
                </div>
                <div class="info-dianzan">
                  <div class="icon-box" @click="this.commentLikeHandle(commentIndex)" :class="item.doLike == '1' ? 'ismy':''">
                    <span class="icon-bg-box dianzan" :style="{clipPath: 'polygon(0% ' + calcPer(item.likes || 0) + '%, 100% ' + calcPer(item.likes || 0) + '%, 100% 100%, 0% 100%)'}">
                    </span>
                    <span class="icon-def-box">
                      <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                        <path d="M140.883,137.666H129.416a3.252,3.252,0,0,1-2.18-.907,2.971,2.971,0,0,1-.992-2.115l-.244-7.4v-.01a2.909,2.909,0,0,1,2.925-2.887h.428c.954,0,1.185-.186,2.111-1.06a5.319,5.319,0,0,0,1.245-2.122c.075-.248.1-.365.124-.443a1.5,1.5,0,0,1,.185-.451c.019-.042.077-.176.116-.268.121-.282.142-.331.166-.375a1.878,1.878,0,0,1,1.581-.956,3.121,3.121,0,0,1,2.579,1.166l.031.041a4.716,4.716,0,0,1,.868,2.149.442.442,0,0,1,.005.11h0a7.355,7.355,0,0,1-.117,1.183l-.1,1.024h4.932a2.936,2.936,0,0,1,2.409,1.252,2.854,2.854,0,0,1,.32,2.669l-2.193,7.548-.012.029a2.909,2.909,0,0,1-2.717,1.824Zm-13.6-10.443.247,7.4v.011a1.982,1.982,0,0,0,1.888,1.766h11.465a1.63,1.63,0,0,0,1.514-1l2.192-7.544.011-.029a1.6,1.6,0,0,0-.168-1.506,1.647,1.647,0,0,0-1.357-.709h-5.032l-.029,0-.458-.008a.956.956,0,0,1-.529-.273c-.408-.419-.287-1.018-.148-1.712.057-.279.118-.582.157-.9a.52.52,0,0,1,0-.165v-.018c0-.049.011-.12.02-.222.006-.066.013-.133.017-.18a3.436,3.436,0,0,0-.617-1.508l-.025-.034a1.837,1.837,0,0,0-1.458-.658.6.6,0,0,0-.537.282c-.021.045-.08.183-.12.277-.116.27-.136.317-.159.359s-.055.1-.066.123-.007.027-.011.046c-.021.085-.057.227-.141.5a6.586,6.586,0,0,1-1.554,2.642l-.021.021a3.715,3.715,0,0,1-3.013,1.423h-.428A1.632,1.632,0,0,0,127.284,127.223Zm5.738-6.965a.009.009,0,0,0,0,0A.014.014,0,0,1,133.022,120.258Z" transform="translate(-126 -118.666)"/>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
              <div class="comment-item-reply" v-if="item.replyInfos && item.replyInfos.length > 0">
                <div class="reply-item-box">
                  <div class="reply-item" v-for="(replyItem, replyIndex) in item.replyInfos" :key="replyIndex"  v-show="!pullDown || replyIndex < 3">
                    <div class="user-ava">
                      <img class="img-user" :src="replyItem.reply_userLogo ? replyItem.reply_userLogo : require('@/assets/def-ava.svg')" alt="">
                    </div>
                    <div class="info-text">
                      <span class="comment-content-span" v-html="replyItem.reply_content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, emotion)"></span>
                      <!-- <span class="text">
                          {{getWaitDurationTime(replyItem.reply_createDate)}}
                      </span> -->
                    </div>
                    <div class="info-dianzan">
                      <div class="icon-box" @click="this.replyLikeHandle(commentIndex, replyIndex)" :class="replyItem.reply_doLike === '1' ? 'ismy':''">
                        <span class="icon-bg-box dianzan" :style="{clipPath: 'polygon(0% ' + calcPer(replyItem.reply_likes || 0) + '%, 100% ' + calcPer(replyItem.reply_likes || 0) + '%, 100% 100%, 0% 100%)'}">
                        </span>
                        <span class="icon-def-box">
                          <svg width="16" height="15" viewBox="0 0 20 19" fill="currentColor">
                            <path d="M140.883,137.666H129.416a3.252,3.252,0,0,1-2.18-.907,2.971,2.971,0,0,1-.992-2.115l-.244-7.4v-.01a2.909,2.909,0,0,1,2.925-2.887h.428c.954,0,1.185-.186,2.111-1.06a5.319,5.319,0,0,0,1.245-2.122c.075-.248.1-.365.124-.443a1.5,1.5,0,0,1,.185-.451c.019-.042.077-.176.116-.268.121-.282.142-.331.166-.375a1.878,1.878,0,0,1,1.581-.956,3.121,3.121,0,0,1,2.579,1.166l.031.041a4.716,4.716,0,0,1,.868,2.149.442.442,0,0,1,.005.11h0a7.355,7.355,0,0,1-.117,1.183l-.1,1.024h4.932a2.936,2.936,0,0,1,2.409,1.252,2.854,2.854,0,0,1,.32,2.669l-2.193,7.548-.012.029a2.909,2.909,0,0,1-2.717,1.824Zm-13.6-10.443.247,7.4v.011a1.982,1.982,0,0,0,1.888,1.766h11.465a1.63,1.63,0,0,0,1.514-1l2.192-7.544.011-.029a1.6,1.6,0,0,0-.168-1.506,1.647,1.647,0,0,0-1.357-.709h-5.032l-.029,0-.458-.008a.956.956,0,0,1-.529-.273c-.408-.419-.287-1.018-.148-1.712.057-.279.118-.582.157-.9a.52.52,0,0,1,0-.165v-.018c0-.049.011-.12.02-.222.006-.066.013-.133.017-.18a3.436,3.436,0,0,0-.617-1.508l-.025-.034a1.837,1.837,0,0,0-1.458-.658.6.6,0,0,0-.537.282c-.021.045-.08.183-.12.277-.116.27-.136.317-.159.359s-.055.1-.066.123-.007.027-.011.046c-.021.085-.057.227-.141.5a6.586,6.586,0,0,1-1.554,2.642l-.021.021a3.715,3.715,0,0,1-3.013,1.423h-.428A1.632,1.632,0,0,0,127.284,127.223Zm5.738-6.965a.009.009,0,0,0,0,0A.014.014,0,0,1,133.022,120.258Z" transform="translate(-126 -118.666)"/>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                   <li class="fold-btn" v-if="item.replyInfos.length > 3" style="posit">
                      <span v-if="pullDown" @click="setPullDown(false)">{{$t('comment.more')}}>></span>
                      <span v-else @click="setPullDown(true)">{{$t('comment.packUp')}}</span>
                  </li>
                </div>
              </div>
              <div class="line"></div>
            </div>

            <div
                    class="list-loading"
                    v-if="commentLoading"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>

          </div>
        </div>

      </div>

    </div>
  </div>
  <my-dialog
          :content="replyInfo.replyContext"
          :showDialog="isShowDialog"
          @submit="addReply"
          @closeDialog="isShowDialog=false"
  ></my-dialog>
  <SideNav :showSide="showSide"/>
  <Footer/>

  <div
          class="filter-box-modal"
          v-if="showFilter"
  >
    <div class="filter-box-bg" @click="hideFilterHandle"></div>
    <div
            class="filter-box"
            :style="filterStyle"
            @click.stop=""
    >
      <div class="filter-box-header">
        <span class="filter-box-header-text">{{$t('detail.filter')}}</span>
        <span
                class="filter-box-header-clsoe"
                @click="hideFilterHandle"
        >
          <img
                  class=""
                  :src="require('@/assets/icon-close.png')"
                  alt=""
          >
        </span>
      </div>
      <div class="filter-box-cente">
        <div class="filter-box-item">
          <div class="filter-box-item-name">{{$t('detail.date')}}</div>
          <div class="filter-box-item-center">
            <a-month-picker
                    v-model:value="filterDate"
                    :placeholder="$t('detail.selectDate')"
                    style="width: 100%"
            ></a-month-picker>
          </div>
        </div>
        <div class="filter-box-item">
          <div class="filter-box-item-name">{{$t('detail.other')}}</div>
          <div class="filter-box-item-center">
            <span
                    class="select-filter"
                    v-for="(item,index) in filterTags"
                    :class="filterSelect == item.tagId ? 'active':''"
                    @click="filterSelectHandle(item)"
            >{{ item.tagName }}</span>
          </div>
        </div>
      </div>
      <div class="filter-box-footer">
        <span
                class="clear-filter"
                @click="filterClearHandle"
        >{{$t('detail.clear')}}</span>
        <button
                class="submut-filter"
                @click="filterSubitHandle"
        >{{$t('done')}}
        </button>
      </div>
    </div>
  </div>
  <comment-dialog
          :content="replyInfo.replyContext"
          @addComment="addComment"
          :visibleSecond="visibleSecond"
          @closeDialog="visibleSecond=false"
  >
  </comment-dialog>

  <Login
          :visible="visible"
          :closeModal="closeModal"
  />
</template>
<script>
  import Header from '@/components/Header.vue'
  import SideNav from '@/components/SideNav.vue'
  import Footer from '@/components/Footer.vue'
  import ListItem from '@/components/ListItem.vue'
  import ListItemSimple from '@/components/ListItemSimple.vue'
  import TitleDialog from '@/components/TitleDialog.vue'
  import Api from "../Api";
  import moment from "moment";
  import storage from "storejs";
  import Login from "./Login";
  import MyDialog from "@/components/mdialog.vue";
  import CommentDialog from "@/components/Commentdialog.vue";
  import {calcPercentage} from '@/utils/common';
  import DetailInfo from '@/components/DetailInfo.vue'
  import Embeddialog from "@/components/Embeddialog.vue";
  import Reportdialog from "@/components/Reportdialog.vue";
  import {shareURL} from '@/utils/request';
  import Emotion from '@/components/Emotion/index'
  import elementResizeDetectorMaker from "element-resize-detector";
  import {autoTextarea,autoTextareaDivChange} from '@/utils/common';
  import DeleteDialog from '../components/DeleteDialog.vue';
  import Loading from '@/components/Loading.vue'

  export default {
    name: 'Index',
    components: {
      DetailInfo,
      Header,
      SideNav,
      Footer,
      Login,
      ListItem,
      ListItemSimple,
      MyDialog,
      TitleDialog,
      CommentDialog,
      Embeddialog,
      Reportdialog,
      Emotion,
      DeleteDialog,
      Loading
    },
    computed: {
      tabs() {
        return [
        {tagName: this.$t('detail.suggest'), tagId: 0},
        {tagName: this.$t('detail.question'),tagId: 1},
        {tagName: this.$t('detail.comment'), tagId: 2}]
      }
    },
    data() {
      return {
        // 图片的userId
        insertUserId:'',
        // 删除按钮是否显示
        isShow:false,
        // 显示更多
        pullDown:true,
         // 箭头按钮参数
        upScrollTop:0,
        shows:true,
        showsSecond:true,
        upWindowHeight:0,
        upScrollHeight:0,
        showIndex:0,
        // // tab1
         firstShows:true,
        firstScrollHeight:0,
        firstWindowHeight:0,
        firstScrollTop:0,
        listLength:0,
        // tab3
        filterShows:true,
        filterScrollHeight:0,
        filterWindowHeight:0,
        filterScrollTop:0,

        selectSort: false,
        shareUrl: '',
        reportDialog: false,
        reportVisible: false,
        deleteDialog: false,
        deleteVisible: false,
        iframe: '',
        dialogVisible: false,
        popVisible: false,
        visibleSecond: false,
        imgList: [],
        imgTabNum: 1,
        visibleTitleList: false,
        commentTitle: '',
        detailInfoVisible: false,
        isShowDialog: false,
        headerStyle: {
          transform: `translateY(-52px)`
        },
        showSide: false,
        visible: false,
        showCommentHeader: false,
        search_value: '',

        topTabs: [],
        selectTab: 0,

        activeTab: 1,
        showFilter: false,
        filterStyle: {
          right: 0,
          top: 0,
        },

        loading: false,
        commentLoading: false,
        commentContext: '',
        replyInfo: {
          commentIndex: undefined,
          replyContent: undefined,
          commentId: undefined,
          replyUserId: undefined,
        },

        entryId: this.$route.params.id,
        pictureId: this.$route.query.pictureId,
        initPictureId: this.$route.params.pictureId,
        entryName: undefined,
        datas: [],
        page: 1,
        totalPage: 1,
        pageSize: 20,
        currPicture: {},

        isCommentMap: {},

        isHeat: 0,
        commentCondition: {
          type: 0,
          pictureId: undefined,
        },
        initPage: {
          page: 1,
          totalPage: 1,
          pageSize: 20,
          list: []
        },
        infoPage: [
          {
            page: 1,
            totalPage: 1,
            pageSize: 20,
            list: []
          },
          {
            page: 1,
            totalPage: 1,
            pageSize: 20,
            list: []
          },
          {
            page: 1,
            totalPage: 1,
            pageSize: 20,
            list: []
          },
        ],

        list: [],

        rightFilterTabShow: false,

        filterTags: [],
        filterDate: '',
        filterSelect: '',
        filter: {
          filterDate: '',
          filterSelect: '',
          page: 1,
          totalPage: 1,
          pageSize: 20,
          list: []
        },
        impression: {
          page: 1,
          totalPage: 1,
          pageSize: 20,
          list: []
        },
        total: {
          suggestionCnt: 0,
          questionCnt: 0,
          commentCnt: 0
        },
        isReply: false,

        rightAllLoading: false,
        rightTabLoading: false,

        showEmoji: true,
        pictureLoading: false,
        isActive: false,
      }
    },

    mounted() {
      this.shareUrl = shareURL
      if (!localStorage.getItem('language')) {
        localStorage.setItem('language', 'ja')
      }
      this.$i18n.locale = localStorage.getItem('language')
      this.getTagByRecommend();
      if(this.initPictureId != null){
        this.pictureId=this.initPictureId;
      }
      if (this.pictureId) {
          this.getPicture();
        } else {
          this.getPicturePage();
        }
      this.getImpression();
      // this.showFilterHandle();
       //变量scrollHeight是滚动条的总高度
    },
    methods: {
       deleteImag(){
         if (this.checkLogin()) {
                return;
          }
        this.deleteVisible = false
        this.deleteDialog = true

      },
       deleteClose(){
        this.deleteDialog = false
      },
      setPullDown (data) {
            this.pullDown = data;
      },
      handleScroll2(){
         // tab2的scrollTop
        this.upScrollTop = document.getElementById("img-box").scrollTop;
        // 窗口可视区域高度
         this.upWindowHeight = document.getElementById("img-box").clientHeight
       //变量scrollHeight是滚动条的总高度
         this.upScrollHeight = document.getElementById("img-box").scrollHeight;
      if(this.upWindowHeight > 0){
        if(this.upScrollTop+this.upWindowHeight>=this.upScrollHeight){
          //  console.log(  "upScrollTop"+this.upScrollTop +"upWindowHeight" + this.upWindowHeight+  "upScrollHeight" + this.upScrollHeight);
       // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
           this.shows = false
        }else{
          this.shows = true
        }
         }
      },
       handleScroll3(){
         // tab2的scrollTop
        this.filterScrollTop = document.getElementById("filter-box").scrollTop;
        // 窗口可视区域高度
         this.filterWindowHeight = document.getElementById("filter-box").clientHeight
       //变量scrollHeight是滚动条的总高度
         this.filterScrollHeight = document.getElementById("filter-box").scrollHeight;
      if(this.filterWindowHeight > 0){
        if(this.filterScrollTop+this.filterWindowHeight>=this.filterScrollHeight){
          //  console.log(  "upScrollTop"+this.filterScrollTop +"upWindowHeight" + this.filterWindowHeight+  "upScrollHeight" + this.filterScrollHeight);
       // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
           this.filterShows = false
        }else{
          this.filterShows = true
        }
         }
      },
      // 移动函数
       toUp() {
       let upTop = document.getElementById("img-box").scrollTop;
        if(upTop >= 0){
          document.getElementById("img-box").scrollTop-=120;
         }
       },
     toDown() {
      let top = document.getElementById("img-box").scrollTop;
        if(top >= 0){
          document.getElementById("img-box").scrollTop+=120;
        }
    },
    toLast(){
       let top = document.getElementById("centerBox").scrollTop;
       if(top >= 0){
          document.getElementById("centerBox").scrollTop-= 235;
        }
    },
    toNext(){
      let top = document.getElementById("centerBox").scrollTop;
      if(top >= 0){
               document.getElementById("centerBox").scrollTop += 235;
      }
    },
      toLast3(){
       let top = document.getElementById("filter-box").scrollTop;
       if(top >= 0){
          document.getElementById("filter-box").scrollTop-= 120;
        }
    },
    toNext3(){
      let top = document.getElementById("filter-box").scrollTop;
      if(top >= 0){
               document.getElementById("filter-box").scrollTop += 120;
      }
    },

        // 表情包
        handleEmotion (i) {
            this.isActive = true
            this.commentContext += i
        },
        // 评论显示表情
        emotion (res) {
            let word = res.replace(/\#|\;/gi,'')
            const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
            let index = list.indexOf(word)
            return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" align="middle">`
        },
      reportClick () {
        this.reportVisible = true
      },
      report () {
         this.reportVisible = false
         this.reportDialog = true

      },
      blockUser () {
         this.reportVisible = false
         this.user = storage.get('userInfo') || {};
         Api.blockUser(this.currPicture.insertUser, this.user.userid).then((res) => {
          if (res.error_code == 0) {
            this.$message.success("不再显示本用户内容成功")
              location.reload();
          }
        }).catch(err => {
          console.log("Get Picture Page ==> ", err)
             if (err.response.status == 401 || err.response.status == 400) {
                 this.$message.error(this.$t("tokenErrMessage"));
                 this.visible = true
             }
        }).finally(() => {
          this.loading = false
        });
      },
      click () {
         this.popVisible = true
      },
       // 复制成功时的回调函数
      onCopy () {
          this.popVisible = false
          this.$copyText(this.shareUrl+'/'+this.currPicture.entryId+'?'+'pictureId='+this.currPicture.pictureId).then(() => {
            this.$message.success(this.$t('index.copySucceed'))
            }).catch(() => {
               this.$message.error(this.$t('index.copyFailed'))
            })
      },
      // 复制嵌入代码
      submit() {
        this.$copyText(this.iframe).then(() => {
          this.$message.success(this.$t('index.copySucceed'))
         }).catch(() => {
            this.$message.error(this.$t('index.copyFailed'))
        })
      },
      // 举报
      reportsubmit(value) {
        this.user = storage.get('userInfo') || {};
        var pictureId = this.currPicture.pictureId.toString();
         Api.reportImage(this.currPicture.entryId, pictureId, value, this.user.userid).then((res) => {
          if (res.error_code == 0) {
            this.$message.success("举报成功")
              location.reload();
            this.reportDialog = false
          }
        }).catch(err => {
          console.log("Get Picture Page ==> ", err)
             if (err.response.status == 401 || err.response.status == 400) {
                 this.$message.error(this.$t("tokenErrMessage"));
                 this.visible = true
             }
        }).finally(() => {
          this.loading = false
        });
      },
      // 删除图片
      deleteSubmit(value){
        // 获取信息
        this.user = storage.get('userInfo') || {};
        if(this.user){
        // 获取到登录用户的id
        var userId =  this.user.userid

        // 获取到图片发布者的id
        var insertUser = this.currPicture.insertUser.toString();
        // pictrueId
        var pictureId = this.currPicture.pictureId.toString();
        if(userId === insertUser){
           Api.deleteImage(this.currPicture.entryId, pictureId,).then((res) => {
          if (res.error_code == 0) {
            this.$message.success(this.$t("deletePicture.deleteSuccess"));
              location.reload();
            this.reportDialog = false
          }
        }).catch(err => {
          console.log("Get Picture Page ==> ", err)
             if (err.response.status == 401 || err.response.status == 400) {
                 this.$message.error(this.$t("tokenErrMessage"));
                 this.visible = true
             }
        }).finally(() => {
          this.loading = false
        });
        }else{
          this.deleteDialog = false;
          console.log(this.user.userid);
          console.log(insertUser);
          return this.$message.error(this.$t("deletePicture.noPermission"));
         }
        }
      },
      onEmbed () {
         this.popVisible = false
         this.iframe = `<iframe src=${this.currPicture.pictureUrl} width=${this.currPicture.pictureWidth} height=${this.currPicture.pictureHeight} frameborder="0" scrolling="auto" referrerpolicy="unsafe-url" allowfullscreen></iframe>`
         this.dialogVisible = true
      },
      // 隐藏嵌入代码
      close() {
         this.dialogVisible = false
      },
      reportClose() {
        this.reportDialog = false
      },
      openComment() {
          if (this.checkLogin()) {
              return;
          }
          this.visibleSecond = true
          this.$nextTick(()=>{
              $('#textarea')[0].select()
          })
      },
      goGuge(key) {
        window.open('https://www.google.com/search?q='+key,"_blank")
      },
      goWeiji(key) {
        window.open('https://'+this.$i18n.locale+'.wikipedia.org/wiki/'+key,"_blank")
      },
      refreshTitle() {
        Api.getPicture(this.currPicture.pictureId).then((res) => {
          if (res.error_code == 0) {
            let data = res.result.data;
            if (data) {
              this.currPicture.note = data.note;
            }
          }
        }).catch(err => {
          console.log("Get Picture Page ==> ", err)
            if (err.response.status == 401 || err.response.status == 400) {
                this.$message.error(this.$t("tokenErrMessage"));
                this.visible = true
            }
        }).finally(() => {
          this.loading = false
        });
      },
      titleClick() {
          if (this.checkLogin()) {
              return;
          }
        this.visibleTitleList = true
        this.user = storage.get('userInfo') || {};
        this.$refs.TitleDialog.getImageTitleInfos(this.currPicture.pictureId, this.user.userid, 1, 10000, this.pictureId)
      },
      getImpression() {
        let params = {
          entryId: this.entryId,
          pageSize: this.pageSize
        }
        Api.getImpression(params).then((res) => {
          this.impression.list = res.result.data;
          this.listLength = res.result.data.length
        })
      },
      addOrUpdateHandle() {
        this.detailInfoVisible = true
        this.$nextTick(() => {
          this.$refs.detailInfo.init(this.currPicture, this.imgList ,this.currPictureIndex )
        })
      },
      onload() {
        $('.item-list-img').hide();
        $('.item-list-img').fadeIn(250);
       this.user = storage.get('userInfo') || {};
       this.insertUserId = this.currPicture.insertUser.toString();
       if(this.user != null){
         if(this.user.userid == this.insertUserId){
         this.isShow = true;
           }else{
             this.isShow = false;
           }
         }
      },
      onloadvice(i) {
        $('.item-list-img-simple-box' + i).hide();
        $('.item-list-img-simple-box' + i).fadeIn(500);
      },
      onloadimpression(i) {
        $('.item-list-img' + i).hide();
        $('.item-list-img' + i).fadeIn(500);
      },
      textChange() {
          const _this = this;
          const erd = elementResizeDetectorMaker();
          erd.listenTo(document.getElementById("comment-text-div"), element => {
              _this.$nextTick(() => {
                  autoTextareaDivChange(document.getElementById("comment-text"))
              });
          });
          autoTextarea(document.getElementById("comment-text"))
        if (this.commentContext) {
          this.isActive = true
        } else {
          this.isActive = false
        }
      },
      clickEmoji() {
        this.showEmoji = !this.showEmoji;
      },
      calcPer(val) {
        if (!val) {
          return 100;
        }
        if (typeof val == "string") {
          val = parseInt(val);
        }
        return 100 - calcPercentage(val);
      },
      showDialog(commentIndex) {
          if (this.checkLogin()) {
              return;
          }
        this.isShowDialog = true;

        let comment = this.infoPage[this.selectTab].list[commentIndex];
        this.replyInfo = {
          commentIndex: commentIndex,
          replyContent: undefined,
          commentId: comment.commentId,
          replyUserId: comment.userId,
        };
          this.$nextTick(()=>{
              $('#textarea')[0].select()
          })
      },

      addReply(content = undefined) {
        if (this.checkLogin()) {
          return;
        }
        if (!content) {
          return;
        }
        let userInfo = storage.get('userInfo') || '';
        if (userInfo) {
          let params = {
            ...this.replyInfo,
            userId: userInfo.userid,
            treeId: this.currPicture.entryId,
            imageId: this.currPicture.pictureId,
            content: content,
          }
          console.log("add reply params ==>", params);
          let _that = this;
          Api.addReply(params).then((res) => {
            this.replyInfo = {
              commentIndex: undefined,
              replyContent: undefined,
              commentId: undefined,
              replyUserId: undefined,
            };

            if (res.error_code == 0) {
              this.$message.success(this.$t('detail.replaySuccess'));
              console.log("reply res ==>", res);
                this.content = ''
                this.isShowDialog = false;
              const data = res.result.data;
              const info = {
                replyId: data.replyId,
                reply_commentId: data.commentId,
                reply_content: data.content,
                reply_createDate: data.createDate,
                reply_doLike: data.doLike,
                reply_likes: data.likes,
                reply_userId: data.userId,
                reply_userLogo: data.userLogo,
                reply_userName: ''
              };
              _that.infoPage[_that.selectTab].list[params.commentIndex].replyInfos = (_that.infoPage[_that.selectTab].list[params.commentIndex].replyInfos || []);
              _that.infoPage[_that.selectTab].list[params.commentIndex].replyInfos.unshift(info);
              // this.infoPage[type] = {...this.initPage};
              // this.getCommentPage(type);
            }
          }).catch(err => {
              if (err.message.indexOf('code 400') > -1) {
                  this.$message.error(this.$t("tokenErrMessage"));
                  this.visible = true
              }
          })
        }
      },
      closeModal() {
        this.visible = false;
      },
      selectPicture(index) {
        let newPicture =null
        switch (this.activeTab) {
          case 1:
              this.imgTabNum === this.activeTab
              if (this.currPicture.pictureUrl !== this.datas[index].pictureUrl) {
                  $('.item-list-img').fadeOut(200);
              }
            newPicture = JSON.parse(JSON.stringify(this.datas[index]))
            setTimeout(() => {
                this.currPicture = JSON.parse(JSON.stringify(this.datas[index]))
                this.currPictureIndex = 0
                this.imgList = this.datas.filter((obj, i) => {
                    if (obj.pictureUrl !== null && i < index) {
                        this.currPictureIndex++;
                    }
                    return obj.pictureUrl !== null
                })
            }, 200)
              break;
            case 2:
                this.imgTabNum === this.activeTab
            if (this.currPicture.pictureUrl !== this.impression.list[index].pictureUrl) {
              $('.item-list-img').fadeOut(200);
            }
            newPicture = JSON.parse(JSON.stringify(this.impression.list[index]))
            setTimeout(() => {
                this.currPicture = this.impression.list[index];
                this.currPicture = JSON.parse(JSON.stringify(this.impression.list[index]))
                this.currPictureIndex = 0
                this.imgList = this.impression.list.filter((obj, i) => {
                    if (obj.pictureUrl !== null && i < index) {
                        this.currPictureIndex++;
                    }
                    return obj.pictureUrl !== null
                })
            }, 200)
                break;
            case 3:
                this.imgTabNum === this.activeTab
            if (this.currPicture.pictureUrl !== this.filter.list[index].pictureUrl) {
              $('.item-list-img').fadeOut(200);
            }
            newPicture = JSON.parse(JSON.stringify(this.filter.list[index]))
            setTimeout(() => {
                this.currPicture = this.filter.list[index];
                this.currPicture = JSON.parse(JSON.stringify(this.filter.list[index]))
                this.currPictureIndex = 0
                this.imgList = this.filter.list.filter((obj, i) => {
                    if (obj.pictureUrl !== null && i < index) {
                        this.currPictureIndex++;
                    }
                    return obj.pictureUrl !== null
                })
            }, 200)
                break;
        }
          if (this.currPicture.isComment) {
          this.isCommentMap[newPicture.pictureId] = true;
        }
        // this.commentCondition.type = 0;
        this.commentCondition.pictureId = newPicture.pictureId;
        this.infoPage[0] = {...this.initPage};
        this.infoPage[1] = {...this.initPage};
        this.infoPage[2] = {...this.initPage};
        this.total = {
          suggestionCnt: newPicture.suggestionCnt,
          questionCnt: newPicture.questionCnt,
          commentCnt: newPicture.commentCnt,
        }
          for (let i = 0; i <3 ; i++) {
              this.getCommentPage(i);
          }
      },
      changeHeat(isHeat, type = undefined) {
        this.selectSort = !this.selectSort;
        this.isHeat = isHeat;
        this.infoPage[0] = {...this.initPage};
        this.infoPage[1] = {...this.initPage};
        this.infoPage[2] = {...this.initPage};
        this.getCommentPage(type);
      },
      //Header
      targetSide() {
        this.showSide = !this.showSide
      },
      getTagByRecommend() {
        Api.getTagByRecommend("filter_recommend").then((res) => {
          let data = res.result.data;
            this.filterTags = data;
        })
      },
      keydownEnter() {
        if (this.search_value != '') {
          window.open('/search?keywords=' + this.search_value)
          // this.$router.push({name:'search',query: {search_value:this.search_value}})
        }
      },
      changeSearchEvent(val) {
        this.search_value = val;
      },

      getFilterPicturePage() {
        if (this.filter.totalPage < this.filter.page) {
          this.filterLoading = false
          return;
        }
        Api.getPicturePage(this.entryId, undefined, this.filter.filterSelect, this.filter.filterDate, this.filter.page, this.filter.pageSize).then((res) => {
          if (res.error_code == 0) {
            let data = res.result.data;
            const isInit = !this.filter.list || this.filter.list.length < 1;
            this.filter.list = this.filter.list.concat(data.list);
            if (this.imgTabNum === this.activeTab) {
                this.currPictureIndex = 0
                this.imgList = this.filter.list.filter((obj,i) => {
                    if (obj.pictureUrl !== null && i < index) {
                        this.currPictureIndex++;
                    }
                    return obj.pictureUrl !== null
                })
            }
            this.filter.totalPage = data.totalPageCount;
            this.filter.page++;
            if (isInit) {
              this.commentCondition.pictureId = this.currPicture.pictureId;
              this.total = {
                suggestionCnt: this.currPicture.suggestionCnt,
                questionCnt: this.currPicture.questionCnt,
                commentCnt: this.currPicture.commentCnt,
              }
              this.getCommentPage();
            }
          }

        }).catch(err => {
          console.log("Get Picture Page ==> ", err)
        }).finally(() => {
          this.loading = false
        });
      },
      getPicture() {
        Api.getPicture(this.pictureId).then((res) => {
          if (res.error_code == 0) {
            let data = res.result.data;
            if (data) {
              this.entryName = data.entryTitle;
              document.title = this.entryName
              this.commentCondition.pictureId = data.pictureId
            }
            this.datas = this.datas.concat(data);
            if (this.imgTabNum === this.activeTab) {
                this.currPictureIndex = 0
                this.imgList = this.datas.filter((obj,i) => {
                    return obj.pictureUrl !== null
                })
            }
            this.getPicturePage()
          }
        }).catch(err => {
          console.log("Get Picture Page ==> ", err)
        }).finally(() => {
          this.loading = false
        });
      },
      getPicturePage() {
          if (this.totalPage < this.page) {
          this.loading = false
          return;
        }
        Api.getPicturePage(this.entryId, undefined, this.tagId, this.filterDate, this.page, this.pageSize).then((res) => {
          if (res.error_code == 0) {
            this.loading = true
            let data = res.result.data;
            this.totalPage = data.totalPageCount;
            const isInit = !this.datas || this.datas.length < 1;
            if (!this.pictureId) {
              if (data.list && data.list.length > 0) {
                this.entryName = data.list[0].entryTitle;
                document.title = this.entryName
              }
            }
            this.datas = this.datas.concat(data.list);
            if (this.page == 1) {
                this.currPicture = JSON.parse(JSON.stringify(this.datas[0]))
                if (this.currPicture.isComment) {
                this.isCommentMap[this.currPicture.pictureId] = true;
              }
            }
              if (this.imgTabNum === this.activeTab) {
                  this.currPictureIndex = 0
                  this.imgList = this.datas.filter((obj,i) => {
                      return obj.pictureUrl !== null
                  })
              }
               this.page++;
            if (isInit) {
              this.commentCondition.pictureId = this.currPicture.pictureId;
              this.total = {
                suggestionCnt: this.currPicture.suggestionCnt,
                questionCnt: this.currPicture.questionCnt,
                commentCnt: this.currPicture.commentCnt,
              }
              this.getCommentPage();
            }
          }

        }).catch(err => {
          console.log("Get Picture Page ==> ", err)
        }).finally(() => {
          this.loading = false
        });
      },
      getCommentPage(type = undefined) {
        type = type == undefined ? this.commentCondition.type : type;
        let page = this.infoPage[type];
        if (page.totalPage < page.page) {
          this.commentLoading = false
          return;
        }
        Api.getCommentPage(this.commentCondition.pictureId, type,
            this.isHeat,
            page.page, page.pageSize).then((res) => {
          if (res.error_code == 0) {
            let data = res.result.data;
            let newList = data.list.filter(value => this.infoPage[type].list.map(v => v.commentId ).indexOf(value.commentId) === -1)
            this.infoPage[type].list = this.infoPage[type].list.concat(newList);
            this.infoPage[type].totalPage = data.totalPageCount;
            this.infoPage[type].page++;
            switch (type) {
              case 0:
                this.total.suggestionCnt = data.totalSize;
                break;
              case 1:
                this.total.questionCnt = data.totalSize;
                break;
              case 2:
                this.total.commentCnt = data.totalSize;
                break;
            }
          }

        }).catch(err => {
          console.log("Get Comment Page ==> ", err)
        }).finally(() => {
          this.commentLoading = false
        });
      },
      //评论筛选
      selectTabHandle(val) {
        this.selectTab = val.tagId;
        this.commentCondition.type = this.selectTab;
        if (this.infoPage[this.selectTab].page <= this.infoPage[this.selectTab].totalPage) {
          this.getCommentPage();
        }
      },

      // 右侧tab
      changeTab(val) {
        this.activeTab = val

      },

      scrollEvent(event) {
        let headerStyle = {
          transform: `translateY(-52px)`
        }
        if ($('#tabItemBox').offset().top <= 0) {
          this.topTabs = this.tabs;
          this.showCommentHeader = true;
          let tNum = -(52 + $('#tabItemBox').offset().top);
          if (tNum >= 0) {
            tNum = 0
          }
          headerStyle = {
            transform: `translateY(${tNum}px)`
          }
        } else {
          this.showCommentHeader = false;
          this.topTabs = [];
          headerStyle = {
            transform: `translateY(-52px)`
          }
        }
        this.headerStyle = headerStyle

        if (!this.commentLoading) {
          if (event.target.scrollHeight < (event.target.scrollTop + event.target.clientHeight + 30)) {
            this.commentLoading = true
            this.getCommentPage()
          }
        }
         $('.list-item-simple-box-center').click(
        function(){
          event.target.scrollTop = 0;
         })
      },
      getList() {
        setTimeout(() => {
          this.list = this.list.concat([1, 2, 3, 4, 5])
          this.loading = false
        }, 2000)
      },

      //右侧的
      scrollEvent1(event) {
        if (!this.loading) {
          if (event.target.scrollHeight < (event.target.scrollTop + event.target.clientHeight + 30)) {
            this.loading = true
            this.getPicturePage();
            if (this.activeTab === 3) this.getFilterPicturePage()
          }
        }
          //tab1的scrollTop
         this.firstScrollTop =document.getElementById("centerBox").scrollTop;
        // this.firstScrollTop = document.body.scrollTop;
             // 窗口可视区域高度
         this.firstWindowHeight = document.getElementById("centerBox").clientHeight;
       //变量scrollHeight是滚动条的总高度
         this.firstScrollHeight = document.getElementById("centerBox").scrollHeight
         if(this.firstWindowHeight > 0){
        if(this.firstScrollTop+this.firstWindowHeight>=this.firstScrollHeight){
          // console.log(  "firstScrollTop"+this.firstScrollTop +"firstWindowHeight" + this.firstWindowHeight+  "firstScrollHeight" + this.firstScrollHeight);
       // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
           this.firstShows = false
        }else{
          this.firstShows = true
        }
      }
      },
      getList1() {
        setTimeout(() => {
          this.rightAllLoading = false
        }, 2000)
      },

      scrollEvent2(event) {
        if (!this.rightTabLoading) {
          if (event.target.scrollHeight < (event.target.scrollTop + event.target.clientHeight + 30)) {
            this.rightTabLoading = true
            this.getList2()
          }
        }
      },
      getList2() {
        setTimeout(() => {
          this.rightTabLoading = false
        }, 2000)
      },


      //过滤器
      showFilterHandler() {
        this.filterStyle = {
          right: $(window).width() - $('.filter').offset().left - $('.filter').width() - 20 + 'px',
          top: $('.filter').offset().top + 22 + 'px',
        };
        this.showFilter = true;
      },
      hideFilterHandle() {
        this.showFilter = false;
      },
        closeTab(e) {
            e.stopPropagation();
            e.preventDefault();
            this.rightFilterTabShow = false;
            if (this.activeTab == 3) {
                this.activeTab = 1
                this.imgTabNum = this.activeTab
                this.currPictureIndex = 0
                this.imgList = this.datas.filter((obj,i) => {
                  return obj.pictureUrl !== null
                })
                this.currPicture = this.datas[0]
                this.changeTab(1);
            }
        },

      filterSelectHandle(val) {
        if (this.filterSelect == val.tagId) {
          this.filterSelect = '';
        } else {
          this.filterSelect = val.tagId;
        }
      },
      filterClearHandle() {
        this.filterDate = ''
        this.filterSelect = ''
      },
      filterSubitHandle() {
        if (!this.filterDate && !this.filterSelect) {
          return;
        }
        this.filter = {
          ...this.initPage,
          filterDate: moment(this.filterDate).format("YYYY-MM"),
          filterSelect: this.filterSelect
        }

        this.getFilterPicturePage();
        this.filterDate = ''
        this.filterSelect = ''
        this.showFilter = false;
        this.rightFilterTabShow = true;
        this.activeTab = 3;
      },

      addComment(type, content = undefined, dialogFlog) {
          if (this.checkLogin()) {
          return;
        }
        if (dialogFlog) {
            if (!content) {
                return;
            }
        } else {
            if (!this.commentContext) {
                return;
            }
        }
        let userInfo = storage.get('userInfo') || '';
        if (userInfo) {
          this.commentContext = this.commentContext.replace(/\n/g, '<br>');
          let params = {
            userId: userInfo.userid,
            treeId: this.currPicture.entryId,
            imageId: this.currPicture.pictureId,
            content: dialogFlog === 'dialogFlog' ? content : this.commentContext,
            type: type
          }
          console.log("add comment params ==>", params);
          Api.addComment(params).then((res) => {
            this.commentContext = '';
            if (res.error_code == 0) {
                this.visibleSecond = false
              this.$message.success(this.$t('detail.commentSuccess'));
              this.infoPage[type] = {...this.initPage};
              this.getCommentPage(type);

              this.isCommentMap[params.imageId] = true;
            }
          }).catch(err => {
                  if (err.message.indexOf('code 400') > -1) {
                      this.$message.error(this.$t("tokenErrMessage"));
                      this.visible = true
                  }
              }
          )
        }
      },
      refreshComments() {
        for (let i = 0; i < 3; i++) {
          this.infoPage[i] = {...this.initPage};
          this.getCommentPage(i);
          this.isCommentMap[this.currPicture.pictureId] = true;
        }
      },
      checkLogin() {
        this.visible = !localStorage.getItem('token');
        return this.visible;
      },
        // commentEnt() {
        //     this.visibleSecond = !localStorage.getItem('token');
        //     return this.visibleSecond;
        // },
      favoriteHandle() {
        if (this.checkLogin()) {
          return;
        }
        let userInfo = storage.get('userInfo') || '';
        if (userInfo) {
          let params = {
            userId: userInfo.userid,
            imageId: this.currPicture.pictureId
          }
          if (this.currPicture.isFavorite) {
            Api.postCancelFavorite(params).then((res) => {
              this.currPicture.isFavorite = false
              this.$message.success(this.$t("detail.cancelCollectionSuccess"));
            }).catch(err => {
                    if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
          } else {
            Api.postFavorite(params).then((res) => {
              this.currPicture.isFavorite = true
              this.$message.success(this.$t("detail.collectionSuccess"));
            }).catch(err => {
                    if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
          }
        }

      },

      likeHandle() {
        if (this.checkLogin()) {
          return;
        }
        let userInfo = storage.get('userInfo') || '';
        if (userInfo) {
          let params = {
            pictureId: this.currPicture.pictureId
          }
          if (this.currPicture.isLike) {
            Api.postpictureLike(params).then((res) => {
              this.currPicture.isLike = false
              this.$message.success(this.$t('detail.cancelLikeSuccess'));
            }).catch(err => {
                    if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
          } else {
            Api.postpictureLike(params).then((res) => {
              this.currPicture.isLike = true
              this.$message.success(this.$t('detail.likeSuccess'));
            }).catch(err => {
                    if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
          }
        }
      },

      commentLikeHandle(commentIndex) {
        if (this.checkLogin()) {
          return;
        }
        let userInfo = storage.get('userInfo') || '';
        if (userInfo) {
          let comment = this.infoPage[this.selectTab].list[commentIndex];
          let params = {
            imageId: this.currPicture.pictureId,
            commentId: comment.commentId,
            userId: userInfo.userid,
            treeId: comment.entryId
          }
          if (comment.doLike && comment.doLike != '0') {
            Api.commentLike(params).then((res) => {
              this.infoPage[this.selectTab].list[commentIndex].doLike = '0'
              this.$message.success(this.$t('detail.cancelLikeSuccess'));
            }).catch(err => {
                    if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
          } else {
            Api.commentLike(params).then((res) => {
              this.infoPage[this.selectTab].list[commentIndex].doLike = '1'
              this.$message.success(this.$t('detail.likeSuccess'));
            }).catch(err => {
                    if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
          }
        }
      },

      replyLikeHandle(commentIndex, replyIndex) {
        if (this.checkLogin()) {
          return;
        }
        let userInfo = storage.get('userInfo') || '';
        if (userInfo) {
          let comment = this.infoPage[this.selectTab].list[commentIndex];
          let reply = comment.replyInfos[replyIndex];
          let params = {
            imageId: this.currPicture.pictureId,
            commentId: comment.commentId,
            replyId: reply.replyId,
            userId: userInfo.userid,
            treeId: comment.entryId
          }
          if (reply.reply_doLike && reply.reply_doLike != '0') {
            Api.replyLike(params).then((res) => {
              this.infoPage[this.selectTab].list[commentIndex].replyInfos[replyIndex].reply_doLike = '0'
              this.$message.success(this.$t('detail.cancelLikeSuccess'));
            }).catch(err => {
                    if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
          } else {
            Api.replyLike(params).then((res) => {
              this.infoPage[this.selectTab].list[commentIndex].replyInfos[replyIndex].reply_doLike = '1'
              this.$message.success(this.$t('detail.likeSuccess'));
            }).catch(err => {
                    if (err.response.status == 401 || err.response.status == 400) {
                                this.$message.error(this.$t("tokenErrMessage"));
                                this.visible = true
                            }
                        }
                        )
          }
        }
      },
      getNewsTime(pubdate) {
        let timeZone = new Date().getTimezoneOffset()/60
        // console.log('时区', new Date().getTimezoneOffset()/60)
        if (typeof timeZone !== "number") {
          return new Date();
        }
        if (pubdate, timeZone) {
          let pubdateTime = moment(pubdate);
          let newPubdate = new Date(pubdateTime - 3600000 * timeZone);
          // let newPubdateTime = moment(newPubdate);
          // let date = new Date(newPubdate)
          let y = newPubdate.getFullYear()
          let M = newPubdate.getMonth() < 9 ? '0' + (newPubdate.getMonth() + 1):newPubdate.getMonth() + 1
          let d= newPubdate.getDate() < 9 ? '0' + newPubdate.getDate():newPubdate.getDate()
          let h= newPubdate.getHours() < 9 ? '0' + newPubdate.getHours():newPubdate.getHours()
          let m= newPubdate.getMinutes() < 9 ? '0' + newPubdate.getMinutes():newPubdate.getMinutes()
          return y + '-' + M + '-' + d + ' ' + h + ':' + m
        }
      },
      getWaitDurationTime(start, end = moment()) {
        console.log('Detail.vue')
        let timeZone = new Date().getTimezoneOffset()/60
        // console.log('时区', new Date().getTimezoneOffset()/60)
        if (typeof timeZone !== "number") {
          return new Date();
        }
        if (start, timeZone) {
          let startTime = moment(start);
          let newStart = new Date(startTime - 3600000 * timeZone);
          let newTime = moment(newStart);
          let endTime = moment(end);
          if (newTime.isSame(end)) {
            return ''
          }

          let waitTime = moment.duration(endTime - newTime, 'ms');
          let years = waitTime.get('years');
          let months = waitTime.get('months');
          let days = waitTime.get('days');
          let hours = waitTime.get('hours');
          let minutes = waitTime.get('minutes');

          if (!years && !months && !days && !hours) {
            if (minutes >= 1) {
              return minutes + this.$t('time.minutesAgo')
            } else {
              return this.$t('time.justNow')
            }
          } else if (!years && !months && !days && hours) {
            if (hours > 1) {
              return hours + this.$t('time.hoursAgo')
            } else {
              return hours + this.$t('time.hourAgo')
            }
          } else if (!years && !months && days) {
            if (days > 1) {
              return days + this.$t('time.daysAgo')
            } else {
              return days + this.$t('time.dayAgo')
            }
          } else if (!years && months) {
            if (months > 1) {
              return months + this.$t('time.monthsAgo')
            } else {
              return months + this.$t('time.monthAgo')
            }
          } else if (years) {
            if (years > 1) {
              return years + this.$t('time.yearsAgo')
            } else {
              return years + this.$t('time.yearAgo')
            }
          } else {
            return moment(newTime).format('YYYY.M.D HH:mm:ss')
          }

        }
        return ''
      },
       showLike(){
             var div = document.getElementsByClassName("reply-item");
             for(var i =0;i<div.length;i++){
                div[i].onmouseover = function(e){
                        var xuan = this.getElementsByClassName("info-dianzan")[0];
                        xuan.style.display = "block";
                };
                div[i].onmouseout = function(e){
                        var xuan = this.getElementsByClassName("info-dianzan")[0];
                        xuan.style.display = "none";
                };
             }
     },
    }
  }

</script>
<style scoped lang="scss">
  body {
    background: #f8f8f8;
  }
  textarea::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #cccccc;
  }
  .searchTitle {
    margin-top: 3px;
    float: left;
    font-size: 14px;
    color: #0870D1;
  }
  .searchImg {
    margin-left: 10px;
    cursor: pointer;
  }
  .detail-box {
    width: 100%;
    height: calc(100vh - 100px);
    overflow-y: auto;

    .container {
      /*display: flex;*/
      width: 1300px;
      margin: 0 auto;
      margin-top: 20px;

      .container-c {
        width: 1300px;
        display: flex;

        .container-c-left {
          width: 840px;

          .item-list {
            width: 100%;
            padding: 20px;
            background: #fff;
            position: relative;
            margin-bottom: 20px;

            .item-icon-tabs {
              position: absolute;
              right: 20px;
              top: 20px;
              cursor: pointer;
              color: #999;

              &.ismy {
                color: #f69c4a;
              }

              .icon-bg-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                &.guanzhu {
                  background-image: url("../assets/report.svg");
                  background-repeat: no-repeat;
                  background-position: -17px -17px;
                  background-size: 52px;
                }
              }

              .icon-def-box {
                position: relative;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                display: inline-block;
              }
            }

            .item-list-title {
              cursor:pointer;
              height: 31px;
              width: 100%;
              text-align: center;
              font-size: 20px;
              font-weight: bold;
            }

            .item-list-imgbox {
              margin-top: 16px;
              width: 100%;
              padding-bottom: 59%;
              position: relative;

              .item-list-imgbox-center {
                  cursor:pointer;
                background: #f3f3f3;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
              }

              .item-list-img {
                min-width: 100%;
                min-height: 100%;
              }
            }

            .item-info {
              margin-top: 20px;
              line-height: 24px;
              display: flex;

              .info-left {
                flex: 1;

                .item-info-name {
                  font-size: 18px;
                  font-weight: bold;
                  color: #0870d1;
                  margin-right: 30px;
                }

                .item-info-time {
                  font-size: 16px;
                  font-weight: 400;
                  color: #999999;
                }
              }

              .info-right {
                position: relative;
                color: #999;

                .icon-box {
                  width: 20px;
                  height: 19px;
                  display: inline-block;
                  position: relative;
                  margin-left: 50px;
                  vertical-align: middle;
                  line-height: 1;
                  cursor: pointer;

                  .icon-bg-box {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                    &.shoucang {
                      background-image: url("../assets/collection.svg");
                      background-repeat: no-repeat;
                      background-position: -10px -11px;
                    }

                    &.pinglun {
                      background-image: url("../assets/comment.svg");
                      background-repeat: no-repeat;
                      background-position: -10px -13px;
                    }

                    &.dianzan {
                      background-image: url("../assets/like.svg");
                      background-repeat: no-repeat;
                      background-position: -10px -10px;
                    }
                  }

                  .icon-def-box {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                  }

                  &.ismy {
                    color: #f69c4a;
                  }
                }

                .item-icon-tabs {
                  display: none;

                  .icon-bg-box {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                    &.guanzhu {
                      background-image: url("../assets/report.svg");
                      background-repeat: no-repeat;
                      background-position: -17px -17px;
                      background-size: 52px;
                    }
                  }

                  .icon-def-box {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                  }
                }
              }
            }

            .item-dec {
              margin-top: 20px;
              font-size: 14px;
              font-weight: 400;
              line-height: 19px;
              color: #999999;
              opacity: 1;
            }
          }
        }

        .container-c-right {
          margin-left: 20px;
          width: 440px;
          flex: 1;

          .container-right-box {
            /*position: sticky;*/
            position: fixed;
            top: 72px;
            width: 440px;

            .center-top {
              background: #fff;
              position: relative;

              .tab-commont {
                /*display: flex;*/
                /*align-items: center;*/
                /*justify-content: center;*/
                padding-top: 20px;
                padding-bottom: 14px;
                overflow: auto;
                white-space: nowrap;
                text-align: center;
                position: relative;
                z-index: 2;

                .tab-item {
                  display: inline-block;
                  white-space: nowrap;
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 24px;
                  color: #cccccc;
                  margin-left: 60px;
                  position: relative;
                  cursor: pointer;
                  &:first-child {
                    margin-left: 0;
                  }

                  &.active {
                    border-bottom: 3px solid #0870d1;
                    font-size: 20px;
                    font-weight: bold;
                    line-height: 26px;
                    padding-bottom: 4px;
                    color: #0870d1;
                  }

                  > span {
                    display: inline-block;
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    color: #fff;
                    top: -12px;
                    right: -14px;
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 50%;
                    font-size: 14px;
                    text-align: center;
                    line-height: 14px;

                    &:hover {
                      background: #0870d1;
                    }
                  }
                }
              }

              .filter {
                position: absolute;
                right: 20px;
                top: 26px;
                cursor: pointer;
                color: #5f646e;
                z-index: 2;
              }
            }

            .center-box-info-box {
              background: #fff;
              overflow: hidden;
              padding-left: 20px;
              display: flex;
              flex-wrap: wrap;
              max-height: calc(100vh - 245px);
              align-items: flex-end;
              align-content: flex-start;
              overflow-y: auto;
              .item-list-img-last {
              position: sticky;
              z-index: 100;
              border-radius: 50px;
               width: 30px;
               height: 30px;
               margin-left: 93%;
               top:0%;
               cursor: pointer;
                       }
                .item-list-img-next {
                 position: absolute;
                 z-index: 98;
                 border-radius: 50px;
                  width: 30px;
                  height: 30px;
                  cursor: pointer;
                  margin-left: 90.5%;
                   }
              .list-item-simple {
                position: relative;
                margin-right: 20px;
                width: 190px;
                border-radius: 4px;
                margin-bottom: 21px;

                .list-item-simple-box {
                  cursor:pointer;
                  background: #f8f8f8;
                  background-size: 100%;
                  background-position: center;
                  width: 100%;
                  padding-bottom: 58%;
                  position: relative;

                  .item-list-img-simple {
                    width: 190px;
                    height: 110px;
                    background-size: 100%;
                    object-fit: cover;
                  }

                  .list-item-simple-box-center {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                  }

                  .list-item-simple-title {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: #ffffff;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  .list-item-simple-dec {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #333333;
                  }

                  .item-list-info-title {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    color: #333333;
                    opacity: 1;
                    text-align: center;
                    width: 100%;
                  }

                  .item-list-info-dec {
                    margin-top: 11px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #999999;
                  }
                }
              }
            }

            .center-box-info-box-yx {
              height: 680px;
              position: absolute;
              width: 100%;
              margin-top: 0;
              .item-list-img-last {
              position: absolute;
              z-index: 98;
              border-radius: 50px;
               width: 30px;
               height: 30px;
               margin-left: 93%;
               cursor: pointer;
                }
              .item-list-img-next {
                position: absolute;
                z-index: 98;
                border-radius: 50px;
                width: 30px;
                height: 30px;
                cursor: pointer;
                margin-left: 93%;
              }

              .item-list-box {
                height: 100%;
                overflow-y: auto;

                .item-list-info-box-img {
                  background: #ffffff;
                  border-radius: 4px;
                  width: 100%;
                  height: 190px;
                  display: flex;

                  .item-list-img-box {
                    cursor:pointer;
                    /*width:210px*/
                    flex: 0.8;
                    height: 195px;
                    background: rgba(0, 0, 0, 0);
                    opacity: 1;
                    border-radius: 4px;
                    background-size: 100%;
                    background-position: center;
                    display: flex;

                    .item-list-img-impression {
                      width: 100%;
                      background-size: 100%;
                      object-fit: cover;
                      border-radius: 50%;
                    }
                  }

                  .item-list-info {
                    flex: 1;
                    position: relative;
                    width:50%;
                    .item-list-info-title {
                      margin-top: 35%;
                      font-size: 18px;
                      font-weight: 400;
                      line-height: 24px;
                      color: #333333;
                      margin-left: 20px;
                      word-wrap:break-word;
                      word-break:break-all;
                      text-align: center;
                    }

                    .item-list-info-time {
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 19px;
                      color: #999999;
                      position: absolute;
                      left: 20px;
                      bottom: 10px;
                    }
                  }
                }

                .item-list {
                  background: #ffffff;
                  border-radius: 4px;
                  margin-bottom: 35px;
                  width: 100%;
                  height: 195px;
                  display: flex;

                  &:first-child {
                    margin-top: 0;
                  }

                  .item-list-info-box {
                    flex: 1;
                    padding: 10px;
                    position: relative;

                    .item-list-info-title {
                      font-size: 18px;
                      font-weight: 400;
                      line-height: 24px;
                      color: #333333;
                      opacity: 1;
                      text-align: center;
                    }

                    .item-list-info-dec {
                      margin-top: 11px;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      color: #999999;
                    }

                    .item-list-info-time {
                      text-align: right;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 19px;
                      color: #999999;
                      position: absolute;
                      right: 20px;
                      bottom: 10px;
                    }
                  }
                }
              }

              .time-axis {
                position: absolute;
                right: -53px;
                top: 0px;
                bottom: 0;
                display: flex;
                flex-direction: column;

                .time-axis-list {
                  flex: 1;
                  position: relative;

                  .text {
                    position: absolute;
                    font-size: 12px;
                    word-break: break-all;
                    font-weight: 400;
                    line-height: 14px;
                    color: #999999;
                    left: -12px;
                    width: 12px;
                  }

                  .line-box {
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    .quan {
                      width: 15px;
                      height: 15px;
                      border-radius: 50%;
                      background: #eee;
                    }

                    .xian {
                      flex: 1;
                      width: 1px;
                      margin-left: 7px;
                      background: #eee;
                    }
                  }

                  &.active {
                    .line-box {
                      .quan {
                        background: #0870d1;
                        border: 1px solid #fff;
                        box-shadow: 0px 0px 0px 1px #0870d1;
                      }

                      .xian {
                        background: #0870d1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .item-center {
        width: 840px;

        .analyse-box {
          width: 100%;
          height: 119px;
          padding: 28px 51px;
          background: #fff;
          margin-top: 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .comment-box {
          padding: 20px;
          background: #fff;
          margin-top: 20px;

          .comment-text-div {
            padding: 18px 20px;
            height: auto;
            background: #f9f9f9;
            border-radius: 8px;
            overflow: hidden;
            position: relative;

            .comment-text {
              overflow-y: visible;
              width: 100%;
              height: auto;
              resize: none;
              border: none;
              font-size: 14px;
              line-height: 19px;
              background: none;
              outline: none;
            }
            .btn-emoticons{
              float: left;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              .icon-emoticons{
                display: flex;
                align-items: center;
                span{
                  margin-left: 6px;
                  font-size: 15px;
                }
              }
            }
            .btn-box {
                float: right;
              /*position: absolute;*/
              bottom: 20px;
              right: 20px;
              > button {
                width: 72px;
                height: 32px;
                background: #eeeeee;
                border-radius: 4px;
                font-size: 14px;
                font-weight: 400;
                line-height: 34px;
                color: #cccccc;
                border: none;
                outline: none;
                margin-left: 15px;
                cursor: pointer;
              }
            }
          }

          .tab-box {
            width: 100%;
            margin-top: 20px;
            display: flex;
            position: relative;
            flex-direction: row;
            flex-wrap: wrap;

            .tab-item-box {
              min-width: 240px;
              flex: 1;
              height: 35px;

              .tab-item {
                color: #cccccc;
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                margin-right: 30px;
                display: inline-block;
                cursor: pointer;

                &.active {
                  color: #333333;
                  font-weight: bold;
                  border-bottom: 2px solid #0870d1;
                  padding-bottom: 9px;
                }
              }
            }

            .filter-item {
              > span {
                margin-left: 40px;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                color: #666666;
                cursor: pointer;

                &.active {
                  color: #0870d1;
                }

                > svg {
                  margin-right: 2px;
                  position: relative;
                  top: 1px;
                }
              }
            }

            .line {
              position: absolute;
              height: 1px;
              left: -20px;
              right: -20px;
              bottom: 0;
              background: #eeeeee;
            }
          }

          .comment-item {
            margin-top: 20px;
            display: flex;
            position: relative;
            padding-bottom: 20px;
            flex-direction: column;

            .comment-item-info {
              display: flex;
              width: 100%;
              position: relative;

              .user-ava {
                width: 30px;
                height: 30px;
                margin-right: 16px;

                .img-user {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                }
              }

              .info-text {
                padding-top: 4px;
                flex: 1;
                overflow: hidden;
                word-break: break-word;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: #333333;
                margin-right: 8%;
                // white-space:pre-wrap;
                .comment-content-span{
                  word-wrap:break-word;
                  word-break:break-all;
                }
                .text {
                  font-size: 10px;
                  color: #cccccc;
                  margin-left: 10px;

                  &.text-btn {
                    cursor: pointer;
                    color: #999999;
                  }
                }
              }

              .info-dianzan {
                width: 20px;
                position: absolute;
                bottom: 0;
                right: 0px;
                cursor: pointer;
                top: 0;
                color: #999999;

                .icon-box {
                  width: 20px;
                  /*height: 20px;*/
                  display: inline-block;
                  position: relative;
                  vertical-align: middle;
                  line-height: 1;
                  cursor: pointer;

                  .icon-bg-box {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                    &.dianzan {
                      background-image: url("../assets/like.svg");
                      background-repeat: no-repeat;
                      background-position: -10px -10px;
                    }
                  }

                  .icon-def-box {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                  }

                  &.ismy {
                    color: #f69c4a;
                  }
                }

                .item-icon-tabs {
                  position: absolute;
                  right: 20px;
                  top: 20px;
                  cursor: pointer;
                  color: #999;

                  &.ismy {
                    color: #f69c4a;
                  }

                  .icon-bg-box {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                    &.guanzhu {
                      background-image: url("../assets/report.svg");
                      background-repeat: no-repeat;
                      background-position: -17px -17px;
                      background-size: 52px;
                    }
                  }

                  .icon-def-box {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                  }
                }
              }
            }

            .comment-item-reply {
              font-size: 14px;
              flex: 1;
              overflow: hidden;
              margin-left: 35px;
              margin-top:10px;

              .reply-item-box {
                padding-left: 10px;

                .reply-item {
                  display: flex;
                  width: 100%;
                  position: relative;
                  margin-top: 10px;

                  &:first-child {
                    margin-top: 0;
                  }

                  .user-ava {
                    width: 15px;
                    height: 15px;
                    margin-right: 10px;
                    line-height: 1;

                    .img-user {
                      width: 15px;
                      height: 15px;
                      border-radius: 50%;
                    }
                  }

                  .info-text {
                    flex: 1;
                    overflow: hidden;
                    word-break: break-word;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 19px;
                    color: #333333;
                    margin-right: 8%;
                    .comment-content-span{
                      white-space:pre-wrap;
                    }
                    .text {
                      font-size: 10px;
                      color: #cccccc;
                      margin-left: 10px;

                      &.text-btn {
                        cursor: pointer;
                        color: #999999;
                      }
                    }
                  }

                  .info-dianzan {
                    width: 20px;
                    position: absolute;
                    bottom: 0px;
                    right: 0;
                    cursor: pointer;
                    display: none;
                    color: #999999;


                    .icon-box {
                      width: 20px;
                      /*height: 20px;*/
                      display: inline-block;
                      position: relative;
                      vertical-align: middle;
                      line-height: 1;
                      cursor: pointer;

                      .icon-bg-box {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                        &.dianzan {
                          background-image: url("../assets/like.svg");
                          background-repeat: no-repeat;
                          background-position: -10px -10px;
                        }
                      }

                      .icon-def-box {
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                      }

                      &.ismy {
                        color: #f69c4a;
                      }
                    }
                  }
                }

                .fold-btn {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 19px;
                  color: #cccccc;
                  display: inline-block;
                  margin-left: 25px;
                  margin-top: 10px;
                }
              }
            }

            .line {
              position: absolute;
              height: 1px;
              background: #eeeeee;
              left: 46px;
              right: 0;
              bottom: 0;
            }
          }

          .list-loading {
            display: flex;
            align-items: center;
            margin-top: 20px;
            height: 40px;
            justify-content: center;

            > span {
              display: inline-block;
              width: 16px;
              height: 16px;
              margin-right: 6px;
              border-radius: 50%;
              background: #ebebeb;
              -webkit-animation: load 1.04s ease infinite;

              &:last-child {
                margin-right: 0px;
              }
            }

            @-webkit-keyframes load {
              0% {
                opacity: 1;
              }
              100% {
                opacity: 0;
              }
            }

            span:nth-child(1) {
              -webkit-animation-delay: 0.13s;
            }

            span:nth-child(2) {
              -webkit-animation-delay: 0.26s;
            }

            span:nth-child(3) {
              -webkit-animation-delay: 0.39s;
            }
          }
        }
      }
    }
  }
  .link {
       float: left;
       margin-top: 6px;
       font-size: 14px;
       color: #4D5156;
    }
    .embed {
        background: #F48234;
        border-radius: 4px;
        margin-left: 16px;
        border-color: unset;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
    }
    .copy {
       background: #2C8CF0;
       border-radius: 4px;
       margin-left: 16px;
       border-color: unset;
       font-size: 14px;
       color: #FFFFFF;
       cursor: pointer;
    }
  .filter-box-modal {
    .filter-box-bg {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 888;
      background: rgba(0, 0, 0, 0.3);
    }

    .filter-box {
      position: absolute;
      /*top: 64px;*/
      /*left: 0;*/
      width: 440px;
      background: #ffffff;
      opacity: 1;
      border-radius: 4px;
      z-index: 999;

      .filter-box-header {
        height: 50px;
        border-bottom: 1px solid #cccccc;
        line-height: 50px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .filter-box-header-text {
          font-size: 16px;
          font-weight: 400;
          color: #333333;
        }

        .filter-box-header-clsoe {
          display: inline-block;
          width: 20px;
          height: 20px;
          line-height: 1;
          cursor: pointer;

          > img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .filter-box-cente {
        padding: 40px 20px;

        .filter-box-item {
          width: 242px;
          height: 32px;
          margin: 0 auto;
          margin-top: 20px;
          display: flex;
          align-items: center;

          &:first-child {
            margin-top: 0;
          }

          .filter-box-item-name {
            font-size: 16px;
            font-weight: bold;
            line-height: 21px;
            color: #333333;
          }

          .filter-box-item-center {
            margin-left: 10px;
            flex: 1;

            .select-filter {
              font-size: 14px;
              font-weight: 400;
              line-height: 1;
              color: #666666;
              padding: 3px 7px;
              border-radius: 43px;
              margin-right: 24px;
              cursor: pointer;

              &:last-child {
                margin-right: 0;
              }

              &.active {
                background: #0870d1;
                color: #fff;
              }
            }
          }
        }
      }

      .filter-box-footer {
        width: 100px;
        padding: 10px 20px 26px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        .clear-filter {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #808695;
          margin-right: 20px;
          cursor: pointer;
        }

        .submut-filter {
          width: 60px;
          height: 32px;
          line-height: 34px;
          background: #2c8cf0;
          border-radius: 4px;
          outline: none;
          border: none;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 840px) {
    .detail-box .container {
      width: 100%;
      padding: 0 30px;
    }
    .detail-box .container .container-c {
      width: 100%;
      display: block;

      .container-c-left {
        width: 100%;
      }

      .container-c-right {
        margin-left: 0px;
        width: 100%;
        flex: 1;

        .container-right-box {
          width: 100%;
          position: relative;
          top: 0;
          padding-left: 0;

          .center-box-info-box {
            .list-item-simple {
              width: 50%;
              margin-right: 0;
              padding-right: 20px;

              .list-item-simple-box {
                width: 100%;
                padding-bottom: 58%;

                .list-item-simple-box-center {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  display: flex;
                  align-items: center;
                }

                .list-item-simple-title {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                  color: #ffffff;
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .list-item-simple-dec {
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 24px;
                  color: #333333;
                  width: 100%;
                  position: absolute;
                }
              }
            }
          }

          .center-box-info-box-yx {
            max-height: 680px;
            position: relative;

            .time-axis {
              right: -25px;

              .time-axis-list {
                .text {
                  font-size: 12px;
                }

                .line-box {
                  .quan {
                    width: 11px;
                    height: 11px;
                  }

                  .xian {
                    margin-left: 5px;
                  }
                }

                &.active {
                  .line-box {
                    .quan {
                    }

                    .xian {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .detail-box .container .item-center {
      width: 100%;
      .comment-box{
        .comment-text-div{
          .btn-emoticons{
            .icon-emoticons{
              display: flex;
              align-items: center;
              span{
                margin-left: 6px;
                font-size: 14px;
              }
            }
          }
          .btn-box {
            > button {
              width: 70px;
              font-size: 13px;
            }
          }
        }
      }

    }

    .detail-box .container .container-c .container-c-left .item-list {
      .item-icon-tabs {
        display: none;
      }

      .item-info {
        display: block;

        .info-right {
          .icon-box {
            margin-left: 0;
            margin-right: 25px;
          }

          .item-icon-tabs {
            display: block;
            position: absolute;
            right: 0;
            top: 2px;
          }
        }
      }
    }

    .detail-box .container .item-center .comment-box {
      .tab-box {
        .tab-item-box {
          .tab-item {
            font-size: 16px;
            margin-right: 18px;
          }
        }

        .filter-item {
          > span {
            margin-left: 12px;
          }
        }
      }

      .comment-list-box {
        .comment-item {
          display: block;

          .comment-item-info {
            width: 100%;
          }

          .comment-item-reply {
            margin-top: 12px;

            .reply-item-box {
              padding-top: 12px;
              border-left: none;
              border-top: 1px solid #eeebeb;
            }
          }
        }
      }
    }
  }
</style>
