<template>
 <DetailInfo
          v-if="detailInfoVisible"
          ref="detailInfo"
          @refreshDataList="refreshComments()"
          @onCopy="onCopy()"
          @onEmbed="onEmbed()"
  ></DetailInfo>
    <div class="item-list">
        <div class="item-icon-tabs" @click="bloomHandle" :class="data.isFollow ? 'ismy':''">
            <span class="icon-bg-box guanzhu" :style="{
                        clipPath: 'polygon(0% ' + calcPer(data.followNum || 0) + '%, 100% ' + calcPer(data.followNum || 0) + '%, 100% 100%, 0% 100%)'
                    }">
            </span>
            <span class="icon-def-box">
                <svg width="18" height="22" viewBox="0 0 18 22" fill="currentColor">
                    <path data-name="路径 695" class="cls-1"
                          d="M110,56.89a1.036,1.036,0,0,1-1.5-.906V36.753A1.772,1.772,0,0,1,110.291,35h14.419a1.772,1.772,0,0,1,1.791,1.753V55.984a1.036,1.036,0,0,1-1.5.907l-7.53-3.692Zm.569-19.86V54.335L117,51.158a1.057,1.057,0,0,1,.932,0l6.5,3.184V37.029Z"
                          transform="translate(-108.5 -35)"/>
                </svg>
            </span>

        </div>
        <div class="item-list-title" @click="goInDetail(data.id ? data.id : data.entryId, data.title ? data.title : data.entryTitle, data.lastPicture ? data.lastPicture?.pictureId : data.pictureId)">
            {{data.lastPicture?data.lastPicture.note:''}}
        </div>
        <div class="item-list-imgbox"
             v-if="data.lastPicture ? data.lastPicture.pictureUrl : data.pictureUrl">
            <div class="item-list-imgbox-center">
                <img @load="onload" @click="addOrUpdateHandle(data.id ? data.id : data.entryId, data.title ? data.title : data.entryTitle, data.lastPicture ? data.lastPicture?.pictureId : data.pictureId)" v-lazy="data.lastPicture ? data.lastPicture.pictureUrl : data.pictureUrl" :class="'item-list-img'+index"
                     class="item-list-img"/>
                     <!-- <div class="item-list-imgbox-center-info"></div> -->
            </div>
        </div>
        <div class="item-info">
            <div class="info-left" @click="goInDetail(data.id ? data.id : data.entryId,data.title ? data.title : data.entryTitle, data.lastPicture ? data.lastPicture?.pictureId : data.pictureId)">
                <a-popover placement="bottomLeft">
                   <template #content>
                       <div class="searchTitle">{{$t('searchTerm')}}</div>
                       <img class="searchImg" src="../assets/guge.png" @click="goGuge(data.title ? data.title : data.entryTitle)" height="21.5" width="26"/>
                       <img class="searchImg" src="../assets/weiji.png" @click="goWeiji(data.title ? data.title : data.entryTitle)" height="26" width="26"/>
                   </template>
                   <span class="item-info-name">{{data.title ? data.title : data.entryTitle}}</span>
                </a-popover>

                <span class="item-info-time">{{this.getWaitDurationTime(data.lastPicture ? data.lastPicture.insertTime : data.insertTime)}}</span>
            </div>
            <div class="info-right">
                <div class="icon-box" style="margin-top: 10px">
                     <a-popover placement="bottom" v-model="popVisible">
                        <template #content>
                          <div class="link">{{shareUrl+'/'+(data.id?data.id:data.entryId)+'?'+'pictureId='+(data.lastPicture?data.lastPicture.pictureId:data.pictureId)}}</div>
                          <a-Button type="danger" class="embed" @click="onEmbed">{{$t('embeddedCode')}}</a-Button>
                          <a-Button class="copy" @click="onCopy">{{$t('copyLink')}}</a-Button>
                        </template>
                        <span class="icon-def-box">
                           <svg width="20" height="17" fill="currentColor" style="margin-top: -20px" @click="click">
                            <path d="M85.239,149.619a1.179,1.179,0,0,1-.172-.013,1.287,1.287,0,0,1-1.057-1.443c.439-4.221,1.882-7.359,4.291-9.331a11.025,11.025,0,0,1,6.512-2.365l.149-.009v-2.345a1.549,1.549,0,0,1,.4-1.022,1.45,1.45,0,0,1,1.059-.472,1.419,1.419,0,0,1,1.028.44l5.836,6.2a2.642,2.642,0,0,1,0,3.6l-5.838,6.2a1.428,1.428,0,0,1-.988.438h-.042a1.484,1.484,0,0,1-1.46-1.485V145.5h-.249c-2.25,0-6.339.462-8.453,3.557a1.226,1.226,0,0,1-1.021.56h0Zm9.474-5.558c.351,0,.628.01.794.016h.016l.13,0a.707.707,0,0,1,.692.721v3.379l5.957-6.323a1.163,1.163,0,0,0,0-1.587l-5.955-6.332v3.224a.707.707,0,0,1-.692.721c-3.954,0-8.921,1.623-10.13,9.356l-.084.535.355-.4C88.38,144.492,92.492,144.061,94.713,144.061Z"
                                  transform="translate(-83.999 -132.619)"/>
                           </svg>
                        </span>
                    </a-popover>
                 </div>
                <div class="icon-box" @click="this.favoriteHandle" :class="(data.lastPicture ? data.lastPicture.isFavorite : data.isFavorite) ? 'ismy':''">
                    <!--这里两个55%用于显示进度-->
                    <span class="icon-bg-box shoucang" :style="{
                        clipPath: 'polygon(0% ' + calcPer((data.lastPicture ? data.lastPicture.favoriteNum : data.favoriteNum) || 0) + '%, 100% ' + calcPer((data.lastPicture ? data.lastPicture.favoriteNum : data.favoriteNum) || 0) + '%, 100% 100%, 0% 100%)'
                    }">
                    </span>
                    <span class="icon-def-box">
                        <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                            <path d="M67.93,96.69a1.5,1.5,0,0,0,1.53.08L74,94.374l4.538,2.4a1.5,1.5,0,0,0,1.531-.08,1.353,1.353,0,0,0,.591-1.351l-.884-5.179,3.815-3.735a1.332,1.332,0,0,0,.335-1.4,1.431,1.431,0,0,0-1.151-.926l-5.2-.759-2.263-4.6a1.474,1.474,0,0,0-2.615,0l-2.262,4.6-5.2.759a1.43,1.43,0,0,0-1.151.926,1.333,1.333,0,0,0,.336,1.4l3.813,3.735-.885,5.179A1.354,1.354,0,0,0,67.93,96.69ZM65.214,85.471l5.188-.756.913-.133.4-.814L74,79.127l2.282,4.641.4.814.913.133,5.188.756L79,89.171l-.635.622.148.867.884,5.169-4.572-2.411L74,92.983l-.826.436L68.6,95.831l.884-5.17.148-.867L69,89.171Z"
                                  transform="translate(-63.998 -77.942)"/>
                        </svg>
                    </span>
                </div>
                <div class="icon-box" @click="this.commentHandle" :class="data.isComment ? 'ismy':''">
                    <span class="icon-bg-box pinglun" :style="{
                        clipPath: 'polygon(0% ' + calcPer((data.lastPicture ? data.lastPicture.commentNum : data.commentNum) || 0) + '%, 100% ' + calcPer((data.lastPicture ? data.lastPicture.commentNum : data.commentNum) || 0) + '%, 100% 100%, 0% 100%)'
                    }">
                    </span>
                    <span class="icon-def-box">
                        <svg
                                width="20"
                                height="19"
                                viewBox="0 0 20 19"
                                fill="currentColor"
                        >
                      <path
                              d="M1.7,15c-0.3-0.4-0.4-0.7-0.5-0.8C0.4,12.9,0,11.5,0,10C-0.3,4.8,4,0.3,9.5,0S19.7,3.8,20,9
	s-4,9.7-9.5,10c-0.2,0-0.3,0-0.5,0c-1.8,0-3.6-0.5-5.1-1.2l-0.5-0.3L2,18c-0.4,0.1-0.8-0.1-0.9-0.5c0-0.1,0-0.3,0-0.4L1.7,15z
	 M3.2,15l-0.4,1.4L4.4,16c0.2-0.1,0.4,0,0.6,0.1c0.3,0.1,0.5,0.3,0.8,0.4c1.4,0.8,3,1.1,4.6,1.1c4.7-0.2,8.3-4,8.1-8.4
	s-4.2-7.9-8.9-7.7C5.1,1.7,1.5,5.3,1.5,9.6c0,1.3,0.4,2.7,1.1,3.8c0.1,0.2,0.3,0.5,0.7,1C3.3,14.6,3.3,14.8,3.2,15L3.2,15z"
                      />
                    </svg>
                    </span>
                </div>
                <div class="icon-box" @click="this.likeHandle" :class="(data.lastPicture ? data.lastPicture.isLike : data.isLike) ? 'ismy':''">
                    <span class="icon-bg-box dianzan" :style="{
                        clipPath: 'polygon(0% ' + calcPer((data.lastPicture ? data.lastPicture.likeNum : data.likeNum) || 0) + '%, 100% ' + calcPer((data.lastPicture ? data.lastPicture.likeNum : data.likeNum) || 0) + '%, 100% 100%, 0% 100%)'
                    }">
                    </span>
                    <span class="icon-def-box">
                        <svg width="20" height="19" viewBox="0 0 20 19" fill="currentColor">
                          <path d="M140.883,137.666H129.416a3.252,3.252,0,0,1-2.18-.907,2.971,2.971,0,0,1-.992-2.115l-.244-7.4v-.01a2.909,2.909,0,0,1,2.925-2.887h.428c.954,0,1.185-.186,2.111-1.06a5.319,5.319,0,0,0,1.245-2.122c.075-.248.1-.365.124-.443a1.5,1.5,0,0,1,.185-.451c.019-.042.077-.176.116-.268.121-.282.142-.331.166-.375a1.878,1.878,0,0,1,1.581-.956,3.121,3.121,0,0,1,2.579,1.166l.031.041a4.716,4.716,0,0,1,.868,2.149.442.442,0,0,1,.005.11h0a7.355,7.355,0,0,1-.117,1.183l-.1,1.024h4.932a2.936,2.936,0,0,1,2.409,1.252,2.854,2.854,0,0,1,.32,2.669l-2.193,7.548-.012.029a2.909,2.909,0,0,1-2.717,1.824Zm-13.6-10.443.247,7.4v.011a1.982,1.982,0,0,0,1.888,1.766h11.465a1.63,1.63,0,0,0,1.514-1l2.192-7.544.011-.029a1.6,1.6,0,0,0-.168-1.506,1.647,1.647,0,0,0-1.357-.709h-5.032l-.029,0-.458-.008a.956.956,0,0,1-.529-.273c-.408-.419-.287-1.018-.148-1.712.057-.279.118-.582.157-.9a.52.52,0,0,1,0-.165v-.018c0-.049.011-.12.02-.222.006-.066.013-.133.017-.18a3.436,3.436,0,0,0-.617-1.508l-.025-.034a1.837,1.837,0,0,0-1.458-.658.6.6,0,0,0-.537.282c-.021.045-.08.183-.12.277-.116.27-.136.317-.159.359s-.055.1-.066.123-.007.027-.011.046c-.021.085-.057.227-.141.5a6.586,6.586,0,0,1-1.554,2.642l-.021.021a3.715,3.715,0,0,1-3.013,1.423h-.428A1.632,1.632,0,0,0,127.284,127.223Zm5.738-6.965a.009.009,0,0,0,0,0A.014.014,0,0,1,133.022,120.258Z" transform="translate(-126 -118.666)"/>
                        </svg>
                    </span>
                </div>
            </div>
        </div>
        <div class="item-dec"><span v-html="data.lastPicture ? data.lastPicture.comment : data.comment"></span></div>
        <!-- <div class="item-dec">{{data.lastPicture ? data.lastPicture.comment : data.comment}}</div> -->

    </div>
</template>
<script>
    import moment from "moment";
    import {calcPercentage} from '@/utils/common';
    import {shareURL} from '@/utils/request';
    import DetailInfo from '@/components/DetailInfo.vue';
     import Api from "../Api";
    export default {
        name: 'ListItem',
         components: {
            DetailInfo,
         },
        props: ['data', 'index'],
        emits: ['onload', 'goInDetail', 'bloomHandle', 'shareHandle', 'favoriteHandle', 'commentHandle', 'likeHandle', 'onCopy', 'onEmbed'],
        data() {
            return {
                shareUrl: '',
                popVisible: false,
                // 放大图片数据
                detailInfoVisible: false,
                datas: [],
                currPicture: {},
                imgList: [],
                pictureId:'',
                entryId:'',


            }
        },
        computed: {
        },
        mounted() {
          this.shareUrl = shareURL
        },
        methods: {
            click () {
              this.popVisible = true
            },
            // 复制成功时的回调函数
            onCopy () {
               this.popVisible = false
               this.$emit('onCopy');
            },
            onEmbed () {
               this.popVisible = false
               this.$emit('onEmbed');
            },
            goGuge(key) {
              window.open('https://www.google.com/search?q='+key,"_blank")
            },
            goWeiji(key) {
              window.open('https://'+this.$i18n.locale+'.wikipedia.org/wiki/'+key,"_blank")
            },
            calcPer(val) {
                if (!val) {
                    return 100;
                }
                if (typeof val == "string") {
                    val = parseInt(val);
                }
                return 100 - calcPercentage(val);
            },
            onload() {
                this.$emit('onload');
            },
            goInDetail(entryId, entryTitle, pictureId) {
                this.$emit('goInDetail', entryId, entryTitle, pictureId);
            },
            bloomHandle(val) {
                this.$emit('bloomHandle', val);
            },
            shareHandle(val) {
                this.$emit('shareHandle', val);
            },
            favoriteHandle(val) {
                this.$emit('favoriteHandle', val);
            },
            commentHandle(val) {
                this.$emit('commentHandle', val);
            },
            likeHandle(val) {
                this.$emit('likeHandle', val);
            },

            getWaitDurationTime(start, end = moment()) {
              let timeZone = new Date().getTimezoneOffset()/60
              // console.log('时区', new Date().getTimezoneOffset()/60)
              if (typeof timeZone !== "number") {
                return new Date();
              }

              if (start, timeZone) {
                    let startTime = moment(start);
                    let newStart = new Date(startTime - 3600000 * timeZone);
                    let newTime = moment(newStart);
                    let endTime = moment(end);
                    if (newTime.isSame(end)) {
                        return ''
                    }

                    let waitTime = moment.duration(endTime - newTime, 'ms');
                    let years = waitTime.get('years');
                    let months = waitTime.get('months');
                    let days = waitTime.get('days');
                    let hours = waitTime.get('hours');
                    let minutes = waitTime.get('minutes');

                    if (!years && !months && !days && !hours) {
                        if (minutes >= 1) {
                            return minutes + this.$t('time.minutesAgo')
                        } else {
                            return this.$t('time.justNow')
                        }
                    } else if (!years && !months && !days && hours) {
                        if (hours > 1) {
                            return hours + this.$t('time.hoursAgo')
                        } else {
                            return hours + this.$t('time.hourAgo')
                        }
                    } else if (!years && !months && days ) {
                        if (days > 1) {
                            return days + this.$t('time.daysAgo')
                        } else {
                            return days + this.$t('time.dayAgo')
                        }
                    } else if (!years && months ) {
                        if (months > 1) {
                            return months + this.$t('time.monthsAgo')
                        } else {
                            return months + this.$t('time.monthAgo')
                        }
                    } else if (years ) {
                        if (years > 1) {
                            return years + this.$t('time.yearsAgo')
                        } else {
                            return years + this.$t('time.yearAgo')
                        }
                    } else {
                        return moment(newTime).format('YYYY.M.D HH:mm:ss')
                    }

                }
                return ''
            },
            refreshComments() {
        for (let i = 0; i < 3; i++) {
          this.infoPage[i] = {...this.initPage};
          this.getCommentPage(i);
          this.isCommentMap[this.currPicture.pictureId] = true;
        }
      },
        onEmbed () {
         this.popVisible = false
         this.iframe = `<iframe src=${this.currPicture.pictureUrl} width=${this.currPicture.pictureWidth} height=${this.currPicture.pictureHeight} frameborder="0" scrolling="auto" referrerpolicy="unsafe-url" allowfullscreen></iframe>`
         this.dialogVisible = true
      },
      addOrUpdateHandle(entryId, entryTitle, pictureId) {
           this.pictureId = pictureId
           this.entryId = entryId
           this.detailInfoVisible = true
           this.getPicture();
           setTimeout(() => {
                       this.$refs.detailInfo.init(this.datas,this.imgList,0)
                    },500)
      },
        getPicture() {
        Api.getPicture(this.pictureId).then((res) => {
          if (res.error_code == 0) {
            let data = res.result.data;
            this.datas = data
            this.getPicturePage(this.entryId)
          }
        }).catch(err => {
          console.log("Get Picture Page ==> ", err)
        }).finally(() => {
          this.loading = false
        });
      },
        getPicturePage(){
        if (this.totalPage < this.page) {
          this.loading = false
          return;
        }
        Api.getPicturePage(this.entryId, undefined, 0, this.filterDate, 1, 20).then((res) => {
            this.imgList = [];
          if (res.error_code == 0) {
            let data = res.result.data;
            const isInit = !this.imgList || this.imgList.length < 1;
            if (!this.pictureId) {
              if (data.list && data.list.length > 0) {
                this.entryName = data.list[0].entryTitle;
                document.title = this.entryName
              }
            }
            this.imgList = this.imgList.concat(data.list);
            this.totalPage = data.totalPageCount;
            if (this.page == 1) {
                this.currPicture = JSON.parse(JSON.stringify(this.imgList[0]))
                if (this.currPicture.isComment) {
                this.isCommentMap[this.currPicture.pictureId] = true;
              }
            }
              if (this.imgTabNum === this.activeTab) {
                  this.currPictureIndex = 0
                  this.imgList = this.imgList.filter((obj,i) => {
                      return obj.pictureUrl !== null
                  })
              }
            this.page++;
          }

        }).catch(err => {
          console.log("Get Picture Page ==> ", err)
        }).finally(() => {
          this.loading = false
        });
      },

        }
    }

</script>
<style scoped lang="scss">
    .item-list {
        width: 100%;
        padding: 20px;
        background: #fff;
        position: relative;
        margin-bottom: 20px;

        .item-icon-tabs {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
            color: #999;
            &.ismy {
                color: #f69c4a;
            }

            .icon-bg-box {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);
                &.guanzhu {
                    background-image: url('../assets/follow.svg');
                    background-repeat: no-repeat;
                    background-position: -17px -17px;
                    background-size: 52px;
                }
            }
            .icon-def-box {
                position: relative;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                display: inline-block;
            }
        }

        .item-list-title {
            height: 31px;
            width: 100%;
            // text-align: center;
            font-size:20px;
            font-weight: bold;
            line-height: 14px;
            font-family: Microsoft YaHei;
        }

        .item-list-imgbox {
            width: 100%;
            overflow: hidden;
            padding-bottom: 71.25%;
            position: relative;
            .item-list-imgbox-center {
                cursor:pointer;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .item-list-img {
                min-width: 100%;
                min-height: 100%;
            }
            .item-list-imgbox-center-info{
                height: 26.5%;
                width: 800px;
                position: absolute;
                bottom: 0;
                background-color: #1E2021;
                opacity: 0.8;
            }
        }

        .item-info {
            margin-top: 20px;
            line-height: 24px;
            display: flex;

            .info-left {
                flex: 1;
                cursor: pointer;
                .item-info-name {
                    font-size: 18px;
                    font-weight: bold;
                    color: #0870D1;
                    margin-right: 30px;
                }

                .item-info-time {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                }
            }

            .info-right {
                position: relative;
                color: #999;
                .icon-box {
                    width: 20px;
                    /*height: 20px;*/
                    display: inline-block;
                    position: relative;
                    margin-left: 50px;
                    vertical-align: middle;
                    line-height: 1;
                    cursor: pointer;
                    .icon-bg-box {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);

                        &.shoucang {
                            background-image: url('../assets/collection.svg');
                            background-repeat: no-repeat;
                            background-position: -10px -11px;
                        }
                        &.pinglun {
                            background-image: url('../assets/comment.svg');
                            background-repeat: no-repeat;
                            background-position: -10px -10px;
                        }
                        &.dianzan {
                            background-image: url('../assets/like.svg');
                            background-repeat: no-repeat;
                            background-position: -10px -10px;
                        }

                    }
                    .icon-def-box {
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                    }

                    &.ismy {
                        color: #f69c4a;
                    }
                }

                .item-icon-tabs {
                    display: none;

                    .icon-bg-box {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        clip-path: polygon(0% 20%, 100% 20%, 100% 100%, 0% 100%);
                        &.guanzhu {
                            background-image: url('../assets/follow.svg');
                            background-repeat: no-repeat;
                            background-position: -17px -17px;
                            background-size: 52px;
                        }
                    }
                    .icon-def-box {
                        position: relative;
                        top: 0;
                        left: 0;
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                    }
                }
            }
        }

        .item-dec {
            margin-top: 20px;
            // font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            color: #999999;
            opacity: 1;
        }

    }
    .searchTitle {
        margin-top: 3px;
        float: left;
        font-size: 14px;
        color: #0870D1;
    }
    .searchImg {
        margin-left: 10px;
        cursor: pointer;
    }
    .link {
       float: left;
       margin-top: 6px;
       font-size: 14px;
       color: #4D5156;
    }
    .embed {
        background: #F48234;
        border-radius: 4px;
        margin-left: 16px;
        border-color: unset;
        font-size: 14px;
        color: #FFFFFF;
        cursor: pointer;
    }
    .copy {
       background: #2C8CF0;
       border-radius: 4px;
       margin-left: 16px;
       border-color: unset;
       font-size: 14px;
       color: #FFFFFF;
       cursor: pointer;
    }
    @media (max-width: 840px) {
        .item-list {
            .item-icon-tabs {
                display: none;
            }
            .item-info {
                display: block;
                .info-right {
                    .icon-box {
                        margin-left: 0;
                        margin-right: 25px;
                    }
                    .item-icon-tabs {
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 2px;
                    }
                }
            }
        }
    }
</style>
