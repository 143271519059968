<template>
    <div class="login-div">
        <a-modal
                :title="$t('login.login')"
                :visible="visible"
                @cancel="handleCancel"
                centered
                :okText="$t('done')"
                :cancelText="$t('cancel')"
                footer=""
                wrapClassName="login-modal-wrap"
        >
            <a-tabs :active-key="activeKey" @change="callback" animated="false">
                <a-tab-pane key="code" :tab="$t('login.verificationCodeLogin')"></a-tab-pane>
                <a-tab-pane key="password" :tab="$t('login.passwordLogin')"></a-tab-pane>
                <a-tab-pane key="register" :tab="$t('login.register')"></a-tab-pane>
            </a-tabs>
            <div  class="login-code-login">
                <a-form ref="formRef" :model="formData" :rules="rules">
                    <a-form-item ref="phone" name="phone" v-if="activeKey == 'code'">
                        <a-input v-model:value="formData.phone" :placeholder="$t('login.phone')" @change="onChangeForm($event)"/>
                    </a-form-item>
                    <a-form-item ref="code" name="code" class="form-item-code" v-if="activeKey == 'code'">
                        <a-input v-model:value="formData.code" :placeholder="$t('login.code')" @change="onChangeForm($event)" @keyup.enter="loginPhone"/>
                        <span class="get-code-btn" @click="getCode" v-if="canSendSms && formData.phone">{{codeTextButton}}</span>
                        <span class="get-code-btn-disable" v-if="!canSendSms || !formData.phone">{{codeTextButton}}</span>
                    </a-form-item>
                    <a-form-item ref="userid" name="userid" v-if="activeKey == 'password'">
                        <a-input v-model:value="formData.userid" :placeholder="$t('login.phoneAndEmail')" @change="onChangeForm($event)"/>
                    </a-form-item>
                    <a-form-item ref="password" name="password" v-if="activeKey == 'password'">
                        <a-input type="password" v-model:value="formData.password" :placeholder="$t('login.password')" @change="onChangeForm($event)" @keyup.enter="login"/>
                    </a-form-item>
                     <!-- 注册用户名 -->
                     <a-form-item ref="registerUsername" name="registerUsername" v-if="activeKey == 'register'">
                        <a-input v-model:value="formData.registerUsername" :placeholder="$t('login.username')"  maxlength = "12"/>
                    </a-form-item>
                    <div style="position: relative">
                            <span v-if="activeKey == 'register' &&formData.registerUsername" style="color:#42b983;position: absolute;top: -22px">{{tip}}</span>
                    </div>
                    <!-- 注册密码 -->
                    <div v-if="activeKey == 'register'" style="display: inline-flex">
                    <a-form-item  ref="registerPassword" name="registerPassword">
                        <a-input type="password" v-model:value="formData.registerPassword" :placeholder="$t('login.password')"  maxlength = "20" />
                     </a-form-item>
                     <span>&nbsp;&nbsp;&nbsp;</span>
                    <a-form-item  ref="seconPassword" name="seconPassword" >
                         <a-input type="password" v-model:value="formData.seconPassword" :placeholder="$t('login.seconPass')" maxlength = "20" />
                    </a-form-item>
                    </div>
                    <a-button block shape="round" type="primary" class="login-btn" @click="login" :disabled="isDisabled" v-if="activeKey == 'password'">{{$t('login.login')}}</a-button>
                    <a-button block shape="round" type="primary" class="login-btn" @click="loginPhone" :disabled="true" v-if="activeKey == 'code'">{{$t('login.login')}}</a-button>
                     <a-button block shape="round" type="primary" class="login-btn" @click="register" :disabled="registerDisabled" v-if="activeKey == 'register'">{{ $t("login.register")}}</a-button>
                    <div class="other-login-type">
                        <span class="label-text"></span>
                        <div class="other-login-logo" style="margin: 0 auto">
                            <!--苹果第三方登录-->
                            <a href="#" class="google-signup" @click.prevent="loginWithApple">
                            <img @load="onload" class="other-login-logo-apple" :src="require('@/assets/apple.svg')" alt="">
                            </a>
                            <!--谷歌第三方登录-->
                            <a href="#" class="google-signup" @click.prevent="loginWithGoogle">
                            <img @load="onload" class="other-login-logo-G" :src="require('@/assets/G.svg')" alt="">
                            </a>
                            <!--Facebook第三方登录-->
                            <a href="#" class="google-signup" @click.prevent="loginWithFacebook">
                            <img @load="onload" class="other-login-logo-F" :src="require('@/assets/F.svg')" alt="">
                            </a>
                            <!--微信第三方登录-->
                            <a href="#" class="google-signup" @click.prevent="loginWithWechat">
                            <img @load="onload" class="other-login-logo-wechat" :src="require('@/assets/wechat.svg')" alt="">
                            </a>
                        </div>
                    </div>
                    <a-form-item ref="agreement" name="agreement" class="form-item-agreement" style="text-align: center">
                        <a-checkbox v-model:checked="formData.agreement" @change="onChangeForm($event)">
                            {{$t('login.agree')}}
                            <a href="">
                                {{$t('login.OurTermsOfService')}}
                            </a>
                            {{$t('login.and')}}
                            <a href="">{{$t('login.privacyPolicy')}}</a>
                            {{$t('login.privacyPolicyAfter')}}
                        </a-checkbox>
                    </a-form-item>
                </a-form>
            </div>
        </a-modal>
    </div>
</template>

<script>
    import Api from '@/Api/index'
    import { initFbsdk } from '@/config/facebook_oAuth.js'

    const {Constants} = require("../constants");

    let registerUsernameTimer= null;
    let registerRulesFlag= [false,false,false];
    export default {
        name: "Login",
        props: {
            visible:Boolean,
            closeModal: Function,
        },
        computed: {
            codeTextButton: {
                get: function () {
                    return this.codeText === '获取验证码' ? this.$t('login.getVerificationCode') : this.codeText
                },
                set: function (val) {
                    this.codeText = val
                },
            },
            rules: function () {
                // 验证密码
                let validatePass = (rule, value, callback) => {
                    // 校验没通过,按钮不可以用
                    this.registerDisabled=true
                    // 密码校验通过标志位
                    registerRulesFlag[1]=false
                    //  数字,字母 或特殊符号8-20位
                    const reg = /(?!\d+$)(?!^[a-zA-Z]+$)(?!^[_#@]+$).{8,20}/;
                      if (value === "") {
                    callback(new Error(this.$t("editModal.passwordNullError")));
                    }else if(!reg.test(value)){
                        callback(new Error(this.$t("login.passwordTip")))
                          this.$refs.formRef.validateFields("seconPassword")
                    } else {
                    if (this.formData.seconPassword !== "") {
                        this.$refs.formRef.validateFields("seconPassword")
                    }
                    // 判断其他校验是否通过
                    if (registerRulesFlag[0]&&registerRulesFlag[2]) {
                        this.registerDisabled=false
                    }
                    registerRulesFlag[1]=true
                    callback();
                    }
                };
                // 验证第二次输入的密码
                let validateSeconPass = (rule, value, callback) => {
                    // 校验没通过,按钮不可以用
                    this.registerDisabled=true
                    registerRulesFlag[2]=false
                    let registerPassword = this.formData.registerPassword;
                    if (value === "") {
                    callback(new Error(this.$t("editModal.passwordNullError")));
                    } else if (value !== registerPassword) {
                        this.isDisabled = true;
                    callback(new Error(this.$t("editModal.passwordDifferentError")));
                    } else {
                        if (registerRulesFlag[0]&&registerRulesFlag[1]) {
                            this.registerDisabled=false
                        }
                        registerRulesFlag[2]=true
                        callback();
                    }
                };
                  // 验证用户名是否重复
                let validateUsername = (rule, value, callback) => {
                // 重新校验,清除定时器
             clearTimeout(registerUsernameTimer)
                //清除上次校验结果,防止后续接口报错导致校验结果留存
             this.tip = ""
                // 校验没通过,按钮不可以用
             this.registerDisabled=true
                 // 用户名校验通过标志位
             registerRulesFlag[0]=false
                 // 数字 字母 或下划线长度6-12
             const reg = /^[0-9a-zA-Z_]{6,12}$/;
             if(value === "") {
             callback(new Error(this.$t("login.UsernameNullRule")));
             this.tip = "";
            }else if(!reg.test(value)){
              this.tip = ""
              callback(new Error(this.$t("login.registerName")));
            } else {
            if (this.formData&&this.formData.registerUsername) {
            registerUsernameTimer= setTimeout(() => {
            if(this.formData&&this.formData.registerUsername) {
               Api.checkUsername(value).then((res) => {
             if(res.result.data) {
                this.tip = "";
               callback(new Error(this.$t("login.registerUsernameRule")));
            }else{
                this.tip = this.$t("login.okRule");
                registerRulesFlag[0]=true
                //判定密码校验是否通过
                if (registerRulesFlag[1] && registerRulesFlag[2]) {
                    this.registerDisabled=false
                }
                callback();
            }}).catch((error) => {
              this.tip = "";
              callback(new Error(this.$t("login.registerUsernameRule")));
              this.$message.error(this.$t('login.fail'));
            });
            }
          }, 500)}
            }
       };

                return {
                    registerUsername: [{ validator: validateUsername,required:true, trigger: "change" }],
                    seconPassword: [{ validator: validateSeconPass,required:true, trigger: "change" }],
                    registerPassword: [{ validator: validatePass, trigger: "change" }],
                    userid: [{required: true, message: this.$t('login.phoneAndEmailRule')}],
                    code: [{required: true, message: this.$t('login.codeRule')}],
                    password: [{required: true, message: this.$t('login.passwordRule')}],
                    agreement: [{required: true, message: '确保阅读并同意 服务条款 和 隐私政策'}],
                }
            }
        },

        mounted () {
            initFbsdk()
        },
        data(){
            return {
                googleSignInParams: {
                    client_id: '481909600784-35t6sc0mbrik8vg6g0nqrog0tk6le53v.apps.googleusercontent.com',
                },
                activeKey:'code',
                tip:"",
                formData: {
                    phone: "",
                    userid: "",
                    code: "",
                    agreement:true,
                    password:"",
                    registerUsername:"",
                    registerPassword:"",
                    seconPassword:"",
                },
                codeText:"获取验证码",
                Timer:null,

                isDisabled:true,
                // 控制注册按钮显示隐藏
                registerDisabled:true,
                submitting:false,
                canSendSms: true,
            }
        },
        methods:{
            onload(){
                const elemapple = document.querySelector('.other-login-logo-apple')
                const elemG = document.querySelector('.other-login-logo-G')
                const elemF = document.querySelector('.other-login-logo-F')
                const elemwechat = document.querySelector('.other-login-logo-wechat')
                $(elemapple).hide();
                $(elemG).hide();
                $(elemF).hide();
                $(elemwechat).hide();
                $(elemapple).fadeIn('50000');
                $(elemG).fadeIn('50000');
                $(elemF).fadeIn('50000');
                $(elemwechat).fadeIn('50000');
            },
            handleCancel(){
                this.closeModal();
            },
            callback(key){
                this.activeKey = key;
                this.$refs.formRef.resetFields();
            },
            //获取验证码
            getCode(){
                this.$refs.formRef.validateFields(['phone']).then(values => {
                    let phone = values.phone;

                    this.canSendSms = false;
                    this.HandlerTime(60)
                    Api.sendSms(phone).then((res) => {
                        console.log("send sms", res);
                    });
                })
            },

            //改变userId
            onChangeForm(e){
                let values = this.$refs.formRef.getFieldsValue() || {};
                if(Object.keys(values).every(key => values[key])){
                    this.isDisabled = false;
                }else{
                    this.isDisabled = true;
                }
            },
            login(){
                this.$refs.formRef.validateFields().then(values => {
                    let params = {
                        userid: values.userid,
                        password: values.password,
                        device:"google",  //设备名称
                        systemName:"google",  //设备名称（浏览器名称）
                        systemVer:"web",  //系统名称（android，iOS，web）
                        appVer:"1.0.0",  //app 版本（web 为1.0.0）
                    }
                    this.submitting = true;
                    Api.login(params).then(res => {
                      if(res.reason !== 'success') {
                        this.$message.error(this.$t('login.loginFailed'));
                      } else {
                        localStorage.setItem("userInfo", JSON.stringify(res.result && res.result.data || null));
                        this.$message.success(this.$t('login.success'));
                        if (this.$route.name === 'Index') {
                          location.reload();
                        } else {
                          this.$router.push({name: 'Index'})
                        }
                      }
                    }).catch(error => {
                        this.submitting = false;
                        let msg = error.response && error.response.data && error.response.data.reason || this.$t('login.fail');
                        this.$message.error(msg);
                    })
                })
            },
            loginPhone(){
                this.$refs.formRef.validateFields().then(values => {
                    let params = {
                        phone: values.phone,
                        verifyCode: values.code,
                        zone: "+81",
                        device:"google",  //设备名称
                        systemName:"google",  //设备名称（浏览器名称）
                        systemVer:"web",  //系统名称（android，iOS，web）
                        appVer:"1.0.0",  //app 版本（web 为1.0.0）
                    }
                    this.submitting = true;
                    Api.loginByPhone(params).then(res => {
                        localStorage.setItem("userInfo",JSON.stringify(res.result && res.result.data || null));
                        this.$message.success(res.reason || this.$t('login.success'));
                        this.handleCancel();
                    }).catch(error => {
                        this.submitting = false;
                        let msg = error.response && error.response.data && error.response.data.reason || this.$t('login.fail');
                        this.$message.error(msg);
                    })
                })
            },

            //倒计时
            HandlerTime(allTime){
                let that = this;
                if(that.Timer){
                    clearTimeout(that.Timer);
                }
                if(allTime > 0){
                    that.Timer = setTimeout(function (){
                        that.codeText = allTime - 1 + 's';
                        that.HandlerTime(allTime - 1);
                    },1000)
                }else{
                    that.canSendSms = true;
                    that.codeText = '获取验证码'
                }
            },
            // 注册
            register(){
            this.$refs.formRef.validateFields().then((values) => {
                let params = {
                userid: values.registerUsername,
                password: values.seconPassword,
                username:values.registerUsername,
                zone: "+81",
                device: "google", //设备名称
                systemName: "google", //设备名称（浏览器名称）
                systemVer: "web", //系统名称（android，iOS，web）
                appVer: "1.0.0", //app 版本（web 为1.0.0）
                };
                this.submitting = true;
                Api.registerUser(params).then((res) => {
                    if(res.error_code === 0 && res.reason === "success"){
                    localStorage.setItem("userInfo",JSON.stringify((res.result && res.result.data) || null));
                    this.$message.success(res.reason || this.$t("login.success"));
                    }else if(res.error_code == 0 || res.result == "user already exists"){
                        this.$message.error(this.$t('login.registerUsernameRule'));
                    }
                     this.formData.registerUsername = "";
                     this.formData.registerPassword = "";
                     this.formData.seconPassword = "";
                }).catch((error) => {
                    let msg =error.response && error.response.data && error.response.data.reason || this.$t("login.fail");
                    this.$message.error(msg || this.$t("login.fail"));
                    this.formData.registerUsername = "";
                    this.formData.registerPassword = "";
                    this.formData.seconPassword = "";
                    this.submitting = false;
                }
                );
            });
            },

            loginWithOther(data) {
                let params = {
                    ...data,
                    zone: "+81",
                    device:"google",  //设备名称
                    systemName:"google",  //设备名称（浏览器名称）
                    systemVer:"web",  //系统名称（android，iOS，web）
                    appVer:"1.0.0",  //app 版本（web 为1.0.0）
                }
                this.submitting = true;
                Api.registerUserByOther(params).then(res => {
                  if(res.reason !== 'success') {
                    this.$message.error(this.$t('login.loginFailed'));
                  } else {
                    localStorage.setItem("userInfo",JSON.stringify(res.result && res.result.data || null));
                    this.$message.success(this.$t('login.success'));
                    this.handleCancel();
                  }
                }).catch(error => {
                    this.submitting = false;
                    let msg = error.response && error.response.data && error.response.data.reason || this.$t('login.fail');
                    this.$message.error(msg);
                })
            },
            loginWithWechat() {
                let url = Constants.wechat_auth_url;
                url = url.replace("{appid}", Constants.env[Constants.environment].appid);
                url = url.replace("{redirect_uri}", Constants.env[Constants.environment].root_uri);

                window.location.href=url;
            },
            loginWithGoogle () {
                this.$gAuth
                    .signIn()
                    .then(GoogleUser => {
                        // on success do something
                        console.log('GoogleUser', GoogleUser)
                        console.log('getId', GoogleUser.getId())
                        console.log('basicprofile', GoogleUser.getBasicProfile().getName())
                        console.log('getBasicProfile', GoogleUser.getBasicProfile())
                        console.log('getAuthResponse', GoogleUser.getAuthResponse())
                        var user = {
                            userid: GoogleUser.getId(),
                            username: GoogleUser.getBasicProfile().getName(),
                            email: GoogleUser.getBasicProfile().getEmail(),
                            profileImage: GoogleUser.getBasicProfile().getImageUrl(),
                            userType: "C"
                        }
                        this.loginWithOther(user);
                    })
                    .catch(error => {
                        console.log('error', error)
                    })
            },
            loginWithApple () {
                window.AppleID.auth.init({
                    clientId : 'com.imamemo.look.sign',
                    scope: 'name email',
                    redirectURI: Constants.env[Constants.environment].appleURL + '/auth/apple',
                    state: JSON.stringify({ foo: 'bar' }),
                    response_type: 'code',
                })

                window.AppleID.auth.signIn()
            },
            loginWithFacebook () {
                window.FB.login(response => {
                    if (response && response.authResponse) {
                        console.log('response', response)
                        window.FB.api(`/me?fields=id,name,email,picture&access_token=${response.authResponse.accessToken}`, userResponse => {
                            if (userResponse) {
                                console.log(userResponse);

                                var user = {
                                    userid: userResponse.id,
                                    username: userResponse.name,
                                    email: userResponse.email,
                                    phone: userResponse.phone,
                                    userType: "A",
                                    profileImage: userResponse.picture?.data?.url,
                                }
                                this.loginWithOther(user);
                            }
                        }, this.params);
                    }
                }, this.params)
            },
        }
    }
</script>

<style lang="scss">
    .login-modal-wrap{
        .ant-modal {
            width: 510px !important;

            .ant-modal-body{
                padding: 6px 60px 39px;
            }
            .ant-modal-close-x{
                display: block;
            }
            .ant-tabs-bar{
                border-bottom: none;
                margin-bottom: 1px;
            }
            .ant-tabs-nav{
                display: flex;
                justify-content: space-between;
            }
            .ant-tabs-tab{
                color: #999999;
                margin-right: 24px;
                font-size: 18px;
            }
            .ant-tabs-tab-active{
                position: relative;
                color: #333333;
                &::after{
                    content: "";
                    display: inline-block;
                    position: absolute;
                    width: 42px;
                    height: 2px;
                    background: #0870D1;
                    left: 0;
                    right: 0;
                    bottom: 2px;
                    margin: auto;
                }
            }
            .form-item-code{
                position: relative;
                .get-code-btn{
                    position: absolute;
                    right: 0;
                    line-height: 32px;
                    color:#0870D1;
                    cursor: pointer;
                }
                .get-code-btn-disable{
                    position: absolute;
                    right: 0;
                    line-height: 32px;
                    color:#CCCCCC;
                    cursor: pointer;
                }
            }
            .form-item-agreement{
                .ant-form-item-control-input-content{
                    border-bottom: 0;
                }
            }
        }
        .login-code-login{
            margin-top: 30px;
            .ant-btn{
                height: 44px;

                &.login-btn{
                    font-size: 18px;
                    margin-top: 6px;
                }
            }
            .ant-form-item-control-input-content{
                border-bottom:1px solid #EEEEEE;
                padding-bottom: 8px;
                .ant-input{
                    border: none;
                }
                .ant-input:focus{
                    box-shadow:none;
                }
            }
            .login-text{
                line-height: 22px;
                font-size: 18px;
                margin-bottom: 0;
            }
            .login-text-tips{
                color: #AFB8DE;
                font-size: 12px;
                line-height: 14px;
                margin-top: 3px;
            }
        }
        .ant-tabs-ink-bar{
            display: none !important;
        }

        .other-login-type{
            display: flex;
            margin-top: 70px;
            margin-bottom: 20px;
            justify-content: center;
            .label-text{
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                color: #CCCCCC;
            }
            .other-login-logo{
                img{
                    margin-right: 20px;
                    cursor: pointer;
                }
            }
        }
    }
</style>
