<template>
  <a-modal @cancel="deleteClose" :visible="deleteDialog"  width="20%">
      <div class="allContent">
          <div class="title">{{this.$t('deletePicture.deletePicture')}}</div>
          <br>
          <p class="text">{{this.$t('deletePicture.deleteConfirm')}}
            <span style="font-weight:700">"{{list.entryTitle}}"</span>?
          </p>
          <div class="btn_container">
            <a-Button class="cancel" @click="deleteClose">{{this.$t('deletePicture.cancel')}}</a-Button>
            <a-Button class="copy" @click="deleteSubmit">{{$t('done')}}</a-Button>
          </div>
      </div>
  </a-modal>
</template>

<script>

export default{
  data() {
    return {
     value: ''
    }
  },
  props:{
    deleteDialog: Boolean,
    list:{}
  },
  emits: ['reportsubmit', 'deleteClose','list'],

  methods: {
    checked(v) {
      this.value = v
    },
    deleteClose: function(){
       this.$emit('deleteClose');
    },
    deleteSubmit: function(){
      this.$emit('deleteSubmit');
    },
  },

}
</script>

<style lang="scss" scoped>
.allContent {
  margin-bottom: 40px;
}
.title {
   font-size: 20px;
   font-weight: 400;
   color: #202124;
   text-align: center;
}
.text{
  font-size: 16px;
  line-height: 20px;

}
.btn_container{
  display: flex;
  justify-content:space-evenly;
  padding-top: 10px;
}
.copy {
  background: #0870D1;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF; 
}
.cancel{
  background-color: #999;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF; 
}
.radio {
  display: block;
  height: 30px;
  line-height: 30px;
}
</style>
